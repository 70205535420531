import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserProvider } from '../../../providers/useUserProvider';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import MaterialIcon from '../../../components/MaterialIcon';
import { getUsers } from '../../../api/users';
import dayjs from 'dayjs';
import { getWallets } from '../../../api/wallets';
import DialogBuyCredits from '../../../components/dialogs/DialogBuyCredits';
import { getMovements } from '../../../api/credits';
import { getPayments } from '../../../api/payments';

const Wallet: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();

    const [userData, setUserData] = useState<any>({});
    const [movements, setMovements] = useState<any[]>([]);
    const [wallets, setWallets] = useState<any[]>([]);
    const [payments, setPayments] = useState<any[]>([]);

    const [openDialogBuyCredits, setOpenDialogBuyCredits] = useState(false);

    useEffect(() => {
        if (!user.userType || !user.userId) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        if (![2, 4, 5, 6].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 403 } });
            return;
        }

        openLoadingDialog();
        Promise.all([loadUser(), loadMovements(), loadWallets(), loadPayments()])
            .then(([userData, payments, wallets]) => {
                setUserData(userData);
                setMovements(payments);
                setWallets(wallets);
                setPayments(payments);
            })
            .catch((err) => {
                enqueueSnackbar("Errore durante il caricamento della pagina", { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const loadUser = async () => {
        const response = await getUsers({ id_utenti: user.userId! });
        return response.data[0];
    }

    const loadMovements = async () => {
        const response = await getMovements({ id_utente: user.userId! });
        return response.data;
    }

    const loadWallets = async () => {
        const response = await getWallets({ id_utente: user.userId! });
        return response.wallets;
    }

    const loadPayments = async () => {
        const response = await getPayments({ id_utente: user.userId! });
        return response.pagamenti;
    }

    const handleOpenDialogBuyCredits = () => {
        setOpenDialogBuyCredits(true);
    }

    const handleCloseDialogBuyCredits = () => {
        setOpenDialogBuyCredits(false);
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Portafoglio</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci i crediti e i movimenti del tuo portafoglio.
            </Typography>
            <Card
                sx={{
                    borderRadius: "1rem",
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <MaterialIcon icon='toll' size='4rem' color={theme.palette.warning.main} />
                <Typography variant="h5" color={theme.palette.text.primary} marginTop="1rem" fontWeight="bold" gutterBottom>{userData?.crediti_residui}</Typography>
                <Typography variant="h6" color={theme.palette.warning.main} fontWeight="bold">Crediti generici</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        marginTop: "1rem",
                    }}
                    onClick={handleOpenDialogBuyCredits}>
                    Ricarica crediti
                </Button>
            </Card>
            {(wallets && wallets.length > 0) && (
                <>
                    <Typography variant="h5" color={theme.palette.textDark.primary} marginTop="2rem" fontWeight="bold" gutterBottom>I tuoi wallet</Typography>
                    <Card
                        sx={{
                            borderRadius: "1rem",
                            padding: "0rem",
                            marginTop: "1rem",
                        }}>
                        {wallets.length > 0 && (
                            <List>
                                {wallets.map((wallet) => (
                                    <ListItem key={wallet.id_wallet}>
                                        <Box
                                            sx={{
                                                width: "3rem",
                                                height: "3rem",
                                                bgcolor: theme.palette.divider,
                                                borderRadius: "50%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                            <MaterialIcon
                                                icon={"account_balance_wallet"}
                                                color={theme.palette.text.primary}
                                            />
                                        </Box>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={theme.palette.text.primary}
                                                    fontWeight="bold">
                                                    Servizio: {wallet.nome_servizio}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={theme.palette.text.secondary}>
                                                    {wallet.saldo} crediti
                                                </Typography>
                                            }
                                            sx={{
                                                marginLeft: "1rem",
                                            }} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Card>
                </>
            )}
            {(movements && movements.length > 0) && (
                <>
                    <Typography variant="h5" color={theme.palette.textDark.primary} marginTop="2rem" fontWeight="bold" gutterBottom>I tuoi movimenti</Typography>
                    <Card
                        sx={{
                            borderRadius: "1rem",
                            padding: "0rem",
                            marginTop: "1rem",
                        }}>
                        {movements.length > 0 && (
                            <List>
                                {movements.map((movement, index) => (
                                    <>
                                        <ListItem key={movement.id_movimento}>
                                            <Box
                                                sx={{
                                                    width: "3rem",
                                                    height: "3rem",
                                                    bgcolor: movement.quantita < 0 ? theme.palette.error.light : theme.palette.success.light,
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}>
                                                <MaterialIcon
                                                    icon={movement.quantita < 0 ? "trending_down" : "trending_up"}
                                                    color={theme.palette.textDark.primary}
                                                />
                                            </Box>
                                            <ListItemText
                                                primary={movement.motivo}
                                                secondary={movement.timestamp_creazione}
                                                sx={{
                                                    marginLeft: "1rem",
                                                }} />
                                            <Typography
                                                variant="subtitle1"
                                                color={movement.quantita < 0 ? theme.palette.error.main : theme.palette.success.main}>
                                                {movement.quantita} crediti
                                            </Typography>
                                        </ListItem>
                                        {index < movements.length - 1 && <Divider />}
                                    </>
                                ))}
                            </List>
                        )}
                    </Card>
                </>
            )}
            <DialogBuyCredits
                open={openDialogBuyCredits}
                onClose={handleCloseDialogBuyCredits}
                onUpdate={() => { }} />
        </Box>
    );
};

export default Wallet;

import apiClient from "./base";

export const getCosts = async (params?: { id_listino?: number, id_servizio?: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/costi/fetch.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}

export const createCost = async (params: { id_listino: number, id_servizio: number, costo: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/costi/register.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}

export const deleteCost = async (params: { id_listino: number, id_servizio: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/costi/delete.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}
import { Box, Button, Card, CardActionArea, Grid, Typography, useTheme } from "@mui/material";
import LogoFitMedical from "../../../components/LogoFitMedical";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MaterialIcon from "../../../components/MaterialIcon";

const RoleSelection: React.FC = () => {
    const theme = useTheme();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState<number | null>(state && state.selectedRole || null);

    const handleRoleChange = (role: number) => {
        setSelectedRole(role);
    }

    const RoleCard = ({ role, name, icon }: { role: number, name: string, icon: string }) => {
        return (
            <Card
                sx={{
                    padding: "0rem",
                    borderRadius: "1rem",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    border: "1px solid",
                    borderColor: selectedRole === role ? theme.palette.primary.main : theme.palette.divider,
                }}>
                <CardActionArea
                    sx={{
                        padding: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: ".5rem",
                    }}
                    onClick={() => handleRoleChange(role)}>
                    <MaterialIcon icon={icon} size="2rem" color={selectedRole === role ? theme.palette.primary.main : theme.palette.text.secondary} />
                    <Typography variant="h6" color={selectedRole === role ? theme.palette.primary.main : theme.palette.text.secondary}>{name}</Typography>
                </CardActionArea>
            </Card>
        );
    }

    const handleLogin = () => {
        navigate("/login");
    }

    const onSubmit = () => {
        const path =
            selectedRole === 6 ? "/register/pharmacy" :
                selectedRole === 5 ? "/register/patient" :
                    selectedRole === 2 ? "/register/nurse" :
                        selectedRole === 3 ? "/register/sports-doctor" :
                            selectedRole === 4 ? "/register/gym" :
                                "/register";

        navigate(path, { state: { selectedRole } });
    }

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            <Card
                sx={{
                    maxWidth: "42rem",
                    margin: "1rem",
                    boxSizing: "border-box",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                <Grid container spacing="1rem" marginTop="2rem">
                    <Grid item xs={12} sm={6}>
                        <RoleCard role={5} name="Paziente" icon="person" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RoleCard role={2} name="Infermiere" icon="vaccines" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RoleCard role={3} name="Medico dello Sport" icon="stethoscope" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RoleCard role={4} name="Palestra" icon="exercise" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RoleCard role={6} name="Farmacia" icon="local_pharmacy" />
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginTop="2rem">
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleLogin}>
                        Hai già un account? Accedi
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!selectedRole}
                        onClick={onSubmit}>
                        Avanti
                    </Button>
                </Box>
            </Card >
        </Box >
    );
}

export default RoleSelection;
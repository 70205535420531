import { Box, Button, Card, Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../../../components/MaterialIcon";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../../providers/useSwallowLoading";
import { getCatalogs } from "../../../../../api/catalogs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteCost, getCosts } from "../../../../../api/costs";
import { useUserProvider } from "../../../../../providers/useUserProvider";
import DialogCreatePrice from "../../../../../components/dialogs/DialogCreatePrice";

const Prices: React.FC = () => {
    const theme = useTheme();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { user } = useUserProvider();

    const id_listino = state.id_listino;

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [catalog, setCatalog] = useState<any>();
    const [prices, setPrices] = useState<any[]>([]);

    const dialogRef = useRef<any>(null);

    useEffect(() => {
        if (!user.userType || ![1].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        openLoadingDialog();

        Promise.all([loadCatalog(), loadPrices()])
            .then(([catalog, prices]) => {
                setCatalog(catalog);
                setPrices(prices);
            })
            .catch((error: any) => {
                enqueueSnackbar("Errore durante il caricamento dei cataloghi", { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const loadCatalog = async () => {
        const response = await getCatalogs({ id_listino: id_listino! });
        return response.listini[0];
    }

    const loadPrices = async () => {
        const response = await getCosts({ id_listino: id_listino! });
        return response.costi;
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleDialogOpen = (mode: 'create' | 'edit' = 'create') => {
        if (mode === 'create') {
            if (!catalog) return;

            dialogRef.current?.open(mode, { id_listino }, catalog?.nome_listino);
        } else {
            if (!selectedItem) return;

            if (!catalog) return;

            dialogRef.current?.open(mode, selectedItem, catalog?.nome_listino);
        }
    }

    const handleDialogCloseUpdate = async () => {
        // Reload prices
        const response = await getCosts({ id_listino: id_listino! });
        setPrices(response.costi);
    }

    const handleDelete = async () => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            await deleteCost({ id_listino: selectedItem.id_listino, id_servizio: selectedItem.id_servizio });
            enqueueSnackbar('Costo eliminato con successo', { variant: 'success' });

            // Reload prices
            const response = await getCosts({ id_listino: id_listino! });
            setPrices(response.costi);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei costi", { variant: 'error' });
        } finally {
            closeLoadingDialog();
            handleMenuClose();
        }
    }

    const columns: HeadCell<any>[] = [
        { id: 'id_servizio', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome_servizio', numeric: false, disablePadding: false, label: 'Servizio' },
        { id: 'costo', numeric: true, disablePadding: false, label: 'Costo (€)' },
        { id: 'timestamp_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const renderRow = (item: any) => (
        <TableRow key={item.id_servizio}>
            <TableCell align="right">{item.id_servizio}</TableCell>
            <TableCell>{item.nome_servizio}</TableCell>
            <TableCell align="right">{item.costo}</TableCell>
            <TableCell>{item.timestamp_creazione}</TableCell>
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    const filteredList = prices.filter((item) => {
        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.nome_servizio.toLowerCase().includes(term) ||
            item.costo.toString().includes(term)
        );
        return matchesSearch;
    });

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Listini<MaterialIcon icon="arrow_right" />{catalog?.nome_listino}
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci i prezzi dei servizi del listino selezionato.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    onClick={() => handleDialogOpen('create')}>
                    <MaterialIcon icon="add" />
                </Fab>
                <TextField
                    label="Cerca prezzi"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleDialogOpen('edit')}>
                    <ListItemIcon>
                        <MaterialIcon icon="edit" />
                    </ListItemIcon>
                    <ListItemText>Modifica</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <MaterialIcon icon="delete" />
                    </ListItemIcon>
                    <ListItemText>Elimina</ListItemText>
                </MenuItem>
            </Menu>
            <DialogCreatePrice
                ref={dialogRef}
                onUpdate={handleDialogCloseUpdate} />
        </Box>
    );
};

export default Prices;
import { Box, Card, Chip, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../components/SortableTable";
import { useUserProvider } from "../../../../providers/useUserProvider";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { fetchAcceptedPolicies } from "../../../../api/policies";

const QRTermsManager: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [users, setUsers] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    const sortableTableRef = useRef<any>(null);

    const columns: HeadCell<any>[] = [
        { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
        { id: 'fl_registrato', numeric: false, disablePadding: false, label: 'Registrato' },
        { id: 'timestamp_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
    ];

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!sortableTableRef.current) return;

        sortableTableRef.current.setFilter((item: any) => {
            const searchTerms = searchText.toLowerCase().split(' ');
            const matchesSearch = searchTerms.every(term =>
                (item.nome?.toLowerCase() || '').includes(term) ||
                (item.cognome?.toLowerCase() || '').includes(term) ||
                (item.codice_fiscale?.toLowerCase() || '').includes(term)
            );
            return matchesSearch;
        });
    }, [searchText]);

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await fetchAcceptedPolicies();

            setUsers(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoadingDialog();
        }
    }

    const renderRow = (item: any) => {
        return (
            <TableRow key={item.id_medico}>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.cognome}</TableCell>
                <TableCell>{item.codice_fiscale}</TableCell>
                <TableCell>
                    <Chip
                        label={item.fl_registrato ? "Registrato" : "Non Registrato"}
                        color={item.fl_registrato ? "success" : "error"} />
                </TableCell>
                <TableCell>{item.timestamp_creazione}</TableCell>
            </TableRow>
        );
    };

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Gestione Consensi QR
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza tutti i consensi accettati dai pazienti attraverso il QR Code.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <TextField
                    label="Cerca utente"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    ref={sortableTableRef}
                    rows={users}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
        </Box>
    );
};

export default QRTermsManager;

import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField, Autocomplete } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { associationByAdmin, getUsers } from "../../api/users";

const DialogCreateAssociation = forwardRef((props: { users: any[], onUpdate: () => void }, ref) => {
    const { register, handleSubmit, setValue, formState, reset } = useForm<{ id_utente?: number | null, id_utente_infermiere?: number | null, id_utente_medico?: number | null }>({
        defaultValues: { id_utente: null, id_utente_infermiere: null, id_utente_medico: null }
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState<number | null>(null);
    const [nurse, setNurse] = useState<any | null>(null);
    const [doctor, setDoctor] = useState<any | null>(null);

    useEffect(() => {
        if (open && userId !== null) {
            loadUserData(userId);
        }
    }, [open, userId]);

    useImperativeHandle(ref, () => ({
        open: (id_utente: number | null) => {
            setOpen(true);
            setUserId(id_utente);
            resetForm();
        },
        close: () => {
            setOpen(false);
            resetForm();
        }
    }));

    const loadUserData = async (id_utente: number) => {
        openLoadingDialog();
        try {
            const response = await getUsers({ id_utenti: id_utente });
            const userData = response.data[0];
            if (userData) {
                setNurse(props.users.find(user => user.id_utenti === userData.id_utente_infermiere) || null);
                setDoctor(props.users.find(user => user.id_utenti === userData.id_utente_medico) || null);
                setValue("id_utente_infermiere", userData.id_utente_infermiere);
                setValue("id_utente_medico", userData.id_utente_medico);
            }
        } catch (error: any) {
            enqueueSnackbar("Errore durante il caricamento della pagina", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const onSubmit = async (data: { id_utente?: number | null, id_utente_infermiere?: number | null, id_utente_medico?: number | null }) => {
        openLoadingDialog();
        try {
            const response = await associationByAdmin({ id_paziente: userId!, id_medico: data.id_utente_medico, id_infermiere: data.id_utente_infermiere });

            enqueueSnackbar('Assegnazioni aggiornate con successo', { variant: 'success' });
            props.onUpdate();
            handleClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'aggiornamento delle assegnazioni", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleClose = () => {
        setOpen(false);
        resetForm();
    };

    const resetForm = () => {
        setNurse(null);
        setDoctor(null);
        reset({});
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Assegna infermiere e medico</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="1rem">Assegna un infermiere e/o un medico al paziente</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="nurse"
                                options={props.users?.filter(user => user.tipi_utenti_id_tipi_utenti === 2)}
                                value={nurse}
                                getOptionLabel={(option) => `${option.nome} ${option.cognome}`}
                                onChange={(e, value) => {
                                    setNurse(value);
                                    setValue("id_utente_infermiere", value ? value.id_utenti : null);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Seleziona un infermiere" variant="outlined" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="doctor"
                                options={props.users?.filter(user => user.tipi_utenti_id_tipi_utenti === 3)}
                                value={doctor}
                                getOptionLabel={(option) => `${option.nome} ${option.cognome}`}
                                onChange={(e, value) => {
                                    setDoctor(value);
                                    setValue("id_utente_medico", value ? value.id_utenti : null);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Seleziona un medico" variant="outlined" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogCreateAssociation;

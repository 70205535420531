import { Box, Button, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../components/MaterialIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserProvider } from "../../providers/useUserProvider";

const Error: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { user } = useUserProvider();

    const handleBack = () => {
        if (!user.userId || !user.userType) {
            navigate("/login");
            return;
        }

        if (state?.from) {
            navigate(state.from, { replace: true });
            return;
        }

        if (state?.errorStatus === 401) {
            navigate("/login", { replace: true });
            return;
        }

        if (state?.errorStatus === 403) {
            navigate("/dashboard", { replace: true });
            return;
        }
    }

    let statusCode = state.errorStatus ?? 404;
    let message = "";
    let description = "";

    switch (statusCode) {
        case 401:
            message = "Non autorizzato";
            description = "Non hai i permessi necessari per visualizzare questa pagina";
            break;
        case 403:
            message = "Vietato";
            description = "Non hai i permessi necessari per visualizzare questa pagina";
            break;
        case 404:
            message = "Pagina non trovata";
            description = "La pagina che stai cercando non esiste";
            break;
        default:
            message = "Errore";
            description = "Si è verificato un errore";
            break;
    }

    return (
        <Box
            width="100svw"
            height="100svh"
            bgcolor={theme.palette.background.default}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <MaterialIcon icon="error" color="white" size="8rem" />
            <Typography variant="h2" color="#ffffff" textAlign="center" gutterBottom sx={{ marginTop: "2rem" }}>{statusCode}</Typography>
            <Typography variant="h4" color="#ffffff" textAlign="center" gutterBottom>{message}</Typography>
            <Typography variant="body1" color="#ffffff" textAlign="center" sx={{ marginBottom: "2rem" }}>{description}</Typography>
            <Button
                variant="contained"
                sx={{ borderRadius: "2rem" }}
                style={{
                    backgroundColor: theme.palette.primaryDark.main,
                    color: theme.palette.primaryDark.contrastText,
                }}
                onClick={handleBack}>
                Torna indietro
            </Button>
        </Box >
    );
}

export default Error;
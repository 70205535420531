import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { createCatalog, updateCatalog } from "../../api/catalogs";
import { useSwallowLoading } from "../../providers/useSwallowLoading";

type FormValues = {
    id_listino?: number;
    tipo_profilo?: number;
    nome_listino: string;
};

const DialogCreateCatalog = forwardRef((props: { onUpdate: () => void }, ref) => {
    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm<FormValues>({
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'create' | 'edit'>('create');

    useEffect(() => {
        if (open) {
            reset(getValues());
        }
    }, [open, reset, getValues]);

    useImperativeHandle(ref, () => ({
        open: (mode: 'create' | 'edit', initialData?: FormValues) => {
            setMode(mode);
            if (initialData) {
                reset(initialData);
            }
            setOpen(true);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
        setInitialData: (initialData: FormValues) => {
            reset(initialData);
        }
    }));

    const onSubmit = async () => {
        if (formState.isSubmitting) {
            return;
        }

        const data = getValues();

        if (mode === 'edit' && !data.id_listino) {
            return;
        }

        if (mode === 'create') {
            delete data.id_listino;
        }

        openLoadingDialog();
        try {
            if (mode === 'create') {
                await createCatalog(data as any);
                enqueueSnackbar('Listino creato con successo', { variant: 'success' });
            } else {
                await updateCatalog({
                    id_listino: data.id_listino!,
                    nome_listino: data.nome_listino,
                    tipo_profilo: data.tipo_profilo
                });
                enqueueSnackbar('Listino aggiornato con successo', { variant: 'success' });
            }

            props.onUpdate();
            handleClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'aggiornamento del listino", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleClose = () => {
        setOpen(false);
        resetForm();
    }

    const resetForm = () => {
        reset({});
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{mode === 'create' ? 'Nuovo Listino' : 'Modifica Listino'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per {mode === 'create' ? 'creare' : 'modificare'} il listino.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.tipo_profilo}>
                            <InputLabel htmlFor="ruolo">Ruolo</InputLabel>
                            <Select
                                id="ruolo"
                                label="Ruolo"
                                value={getValues().tipo_profilo}
                                {...register("tipo_profilo", { required: "Il ruolo è obbligatorio" })}>
                                <MenuItem value={1}>Amministratore</MenuItem>
                                <MenuItem value={2}>Infermiere</MenuItem>
                                <MenuItem value={3}>Medico</MenuItem>
                                <MenuItem value={4}>Palestra</MenuItem>
                                <MenuItem value={5}>Paziente</MenuItem>
                            </Select>
                            {!!errors.tipo_profilo && (
                                <FormHelperText>{errors.tipo_profilo.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.nome_listino}>
                            <InputLabel htmlFor="listino">Nome listino</InputLabel>
                            <OutlinedInput
                                id="listino"
                                type="text"
                                label="Nome listino"
                                {...register("nome_listino", { required: "Il nome del listino è obbligatorio" })}
                            />
                            {!!errors.nome_listino && (
                                <FormHelperText>{errors.nome_listino.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogCreateCatalog;

import axios, { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = axios.create({
    // baseURL: process.env.REACT_APP_API_BASE_URL ?? 'http://51.38.126.111:8081/api', // Get the API base URL from environment variables
    baseURL: 'https://api.telemedicina.care/api',
    headers: {
        'Content-Type': 'application/json', // Set the default content type
    },
});

export default apiClient;
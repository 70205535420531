import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import MaterialIcon from "../../components/MaterialIcon";
import { getDocumentCategories, uploadDocument } from "../../api/documents";

type FormValues = {
    documents: { file: File | null, category: number | null }[];
};

const DialogUploadDocuments: React.FC<DialogProps & { id_richiesta?: number, id_utente?: number, onUpdate: () => void }> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [categories, setCategories] = useState<any[]>([]);
    const [documents, setDocuments] = useState<{ file: File, category: number | null }[]>([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getDocumentCategories({ hidden: 0 });
                setCategories(response.categorie);
            } catch (error) {
                enqueueSnackbar('Errore nel caricamento delle categorie', { variant: 'error' });
            }
        };
        fetchCategories();
    }, [props.open, enqueueSnackbar]);

    const onSubmit = async () => {
        if (documents.length === 0) {
            enqueueSnackbar('Nessun documento caricato', { variant: 'warning' });
            return;
        }

        openLoadingDialog();
        try {
            for (const document of documents) {
                if (document.file && document.category !== null) {
                    await uploadDocument({ file: document.file, id_categoria: document.category, id_richiesta: props.id_richiesta, id_utente: props.id_utente });
                }
            }

            enqueueSnackbar('Documenti caricati con successo', { variant: 'success' });
            props.onUpdate();
            onClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante il caricamento dei documenti", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        setDocuments([]);
    }

    const handleFileDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        const newDocs = Array.from(files).map(file => ({ file, category: null }));
        setDocuments([...documents, ...newDocs]);
    };

    const handleRemove = (index: number) => {
        const newDocuments = documents.filter((_, i) => i !== index);
        setDocuments(newDocuments);
    }

    const handleCategoryChange = (index: number, category: number) => {
        const newDocuments = documents.map((doc, i) => i === index ? { ...doc, category } : doc);
        setDocuments(newDocuments);
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const canSubmit = documents.every(doc => doc.file && doc.category !== null) && documents.length > 0;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "48rem",
                    },
                },
            }}>
            <DialogTitle>Carica Documenti</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Trascina i tuoi file qui o clicca per caricarli. Assegna una categoria a ogni documento.</DialogContentText>
                <Box
                    border="2px dashed #cccccc"
                    padding="1rem"
                    textAlign="center"
                    onDrop={handleFileDrop}
                    onDragOver={handleDragOver}>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        Trascina qui i tuoi file oppure
                    </Typography>
                    <Button variant="contained" color="primary" component="label">
                        Carica documento
                        <input type="file" hidden multiple onChange={(e) => {
                            const files = e.target.files;
                            if (files) {
                                const newDocs = Array.from(files).map(file => ({ file, category: null }));
                                setDocuments([...documents, ...newDocs]);
                            }
                        }} />
                    </Button>
                </Box>
                {documents.map((document, index) => (
                    <Grid container spacing="1rem" key={`file-${index}`} sx={{ marginTop: '1rem', alignItems: 'center' }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    type="text"
                                    value={document.file ? document.file.name : ""}
                                    readOnly
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor={`category-${index}`}>Categoria</InputLabel>
                                <Select
                                    label="Categoria"
                                    id={`category-${index}`}
                                    onChange={(e) => handleCategoryChange(index, e.target.value as number)}>
                                    {categories.map((category) => (
                                        <MenuItem key={category.id_categoria} value={category.id_categoria}>
                                            {category.nome_categoria}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                color="secondary"
                                aria-label="delete file"
                                onClick={() => handleRemove(index)}
                            >
                                <MaterialIcon icon="delete" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" onClick={onSubmit} disabled={!canSubmit}>Carica</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogUploadDocuments;

import apiClient from "./base";

export const getPayments = async (params?: { id_utente?: number, id_pagamento?: number, id_product?: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/pagamenti/getPayments.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}

export const decodePaymentToken = async (params: { token: string }) => {
    const response = await apiClient.post('/pagamenti/decodePaymentToken.php', params);

    return response.data;
}
import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField, Autocomplete } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { associationByAdmin, getUsers } from "../../api/users";
import { fetchDoctorsForAssignment, reassignRequest } from "../../api/requests";

const DialogReassignRequest = forwardRef((props: { onUpdate: () => void }, ref) => {
    const { register, handleSubmit, setValue, getValues, formState, reset } = useForm<{ id_utente_destinatario: number | null }>({
        defaultValues: { id_utente_destinatario: null },
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [requestId, setRequestId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const [doctors, setDoctors] = useState<any[]>([]);

    useEffect(() => {
        loadDoctors();
    }, [open, requestId]);

    const loadDoctors = async () => {
        if (!requestId) return;

        try {
            const response = await fetchDoctorsForAssignment({ id_richiesta: requestId });
            setDoctors(response.data.medici);

            if (response.data.id_utente_destinatario) {
                setValue('id_utente_destinatario', response.data.id_utente_destinatario);
            } else {
                setValue('id_utente_destinatario', null);
            }
        } catch (error: any) {
            enqueueSnackbar("Errore durante il caricamento dei medici", { variant: 'error' });
        }
    }

    useImperativeHandle(ref, () => ({
        open: (id_richiesta: number | null) => {
            setOpen(true);
            resetForm();
            setRequestId(id_richiesta);
        },
        close: () => {
            setOpen(false);
            resetForm();
        }
    }));

    const onSubmit = async (data: { id_utente_destinatario: number | null }) => {
        if (!requestId) return;

        if (!data.id_utente_destinatario) {
            enqueueSnackbar('Seleziona un medico', { variant: 'warning' });
            return;
        }

        openLoadingDialog();
        try {
            const response = await reassignRequest({ id_richiesta: requestId as number, id_utente_destinatario: data.id_utente_destinatario });

            enqueueSnackbar('Assegnazioni aggiornate con successo', { variant: 'success' });
            props.onUpdate();
            handleClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'aggiornamento delle assegnazioni", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleClose = () => {
        setOpen(false);
        resetForm();
    };

    const resetForm = () => {
        reset({});
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Assegna richiesta</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="1rem">
                    Seleziona un medico a cui assegnare la richiesta
                </DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="doctor"
                                options={doctors}
                                value={doctors.find((doctor) => doctor.id_utenti === getValues('id_utente_destinatario')) || null}
                                getOptionLabel={(option) => `${option.nome} ${option.cognome} - ${option.codice_fiscale}`}
                                onChange={(e, value) => {
                                    setValue('id_utente_destinatario', value?.id_utenti || null);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Seleziona un medico" variant="outlined" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogReassignRequest;

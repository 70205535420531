import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useUserProvider } from '../../providers/useUserProvider';
import { createAvailability, deleteAvailability, updateAvailability } from '../../api/availabilities';
import { useSwallowLoading } from '../../providers/useSwallowLoading';
import { getUsers } from '../../api/users';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

type DialogAvailabilityCreateProps = {
    onUpdate: () => void;
};

const generateTimeOptions = (start: number, end: number) => {
    const times = ["Nessun orario"];
    for (let i = start; i <= end; i++) {
        times.push(`${i.toString().padStart(2, '0')}:00:00`);
        times.push(`${i.toString().padStart(2, '0')}:30:00`);
    }
    return times;
};

const morningTimes = generateTimeOptions(9, 13);
const afternoonTimes = generateTimeOptions(14, 20);

const DialogAvailabilityCreate = forwardRef<unknown, DialogAvailabilityCreateProps>((props, ref) => {
    const { register, handleSubmit, getValues, setValue, formState, reset, watch } = useForm<{
        id_medico_sport?: number;
        id_disponibilita?: number;
        data_disponibilita?: dayjs.Dayjs | undefined | null;
        orario_mattina_inizio?: string;
        orario_mattina_fine?: string;
        orario_pomeriggio_inizio?: string;
        orario_pomeriggio_fine?: string;
        max_bookings_per_interval?: number;
    }>();
    const { errors } = formState;
    const { user } = useUserProvider();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [users, setUsers] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({
        open: (mode: 'create' | 'edit', initialData?: any) => {
            setMode(mode);
            reset({
                ...initialData
            });
            setOpen(true);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
    }));

    useEffect(() => {
        const init = async () => {
            openLoadingDialog();
            try {
                const response = await getUsers({ tipi_utenti_id_tipi_utenti: 3 });
                setUsers(response.data);
            } catch (error) {
                enqueueSnackbar('Errore durante il caricamento degli utenti', { variant: 'error' });
            } finally {
                closeLoadingDialog();
            }
        };

        if (user.userType === 1) {
            init();
        }
    }, []);

    const onSubmit = async () => {
        const data = getValues();
        const dateLocaleString = dayjs(data.data_disponibilita).format('DD/MM/YYYY');

        console.log(data);

        if (!data.id_medico_sport) {
            enqueueSnackbar('Seleziona un medico', { variant: 'error' });
            return;
        }

        if (!data.data_disponibilita) {
            enqueueSnackbar('Seleziona una data', { variant: 'error' });
            return;
        }

        try {
            if (mode === 'create') {
                await createAvailability({
                    id_medico_sport: data.id_medico_sport!,
                    data_disponibilita: dateLocaleString.split('/').reverse().join('-'),
                    orario_mattina_inizio: data.orario_mattina_inizio === "Nessun orario" || !data.orario_mattina_inizio ? null : data.orario_mattina_inizio,
                    orario_mattina_fine: data.orario_mattina_fine === "Nessun orario" || !data.orario_mattina_fine ? null : data.orario_mattina_fine,
                    orario_pomeriggio_inizio: data.orario_pomeriggio_inizio === "Nessun orario" || !data.orario_pomeriggio_inizio ? null : data.orario_pomeriggio_inizio,
                    orario_pomeriggio_fine: data.orario_pomeriggio_fine === "Nessun orario" || !data.orario_pomeriggio_fine ? null : data.orario_pomeriggio_fine,
                    max_bookings_per_interval: data.max_bookings_per_interval,
                });
            } else if (mode === 'edit') {
                if (!data.id_disponibilita) return;

                await updateAvailability({
                    id_disponibilita: data.id_disponibilita,
                    id_medico_sport: data.id_medico_sport!,
                    data_disponibilita: dateLocaleString.split('/').reverse().join('-'),
                    orario_mattina_inizio: data.orario_mattina_inizio === "Nessun orario" || !data.orario_mattina_inizio ? null : data.orario_mattina_inizio,
                    orario_mattina_fine: data.orario_mattina_fine === "Nessun orario" || !data.orario_mattina_fine ? null : data.orario_mattina_fine,
                    orario_pomeriggio_inizio: data.orario_pomeriggio_inizio === "Nessun orario" || !data.orario_pomeriggio_inizio ? null : data.orario_pomeriggio_inizio,
                    orario_pomeriggio_fine: data.orario_pomeriggio_fine === "Nessun orario" || !data.orario_pomeriggio_fine ? null : data.orario_pomeriggio_fine,
                    max_bookings_per_interval: data.max_bookings_per_interval,
                });
            }

            enqueueSnackbar('Disponibilità salvata con successo', { variant: 'success' });
            props.onUpdate();
            handleClose();
        } catch (error) {
            enqueueSnackbar('Errore durante il salvataggio della disponibilità', { variant: 'error' });
        }
    };

    const handleClose = () => {
        setOpen(false);
        resetForm();
    };

    const resetForm = () => {
        reset({});
    };

    const filterTimes = (times: string[], startTime?: string, endTime?: string) => {
        if (startTime && startTime !== "Nessun orario") {
            return times.filter(time => time === "Nessun orario" || time > startTime);
        }
        if (endTime && endTime !== "Nessun orario") {
            return times.filter(time => time === "Nessun orario" || time < endTime);
        }
        return times;
    };

    const watchFields = watch(['orario_mattina_inizio', 'orario_mattina_fine', 'orario_pomeriggio_inizio', 'orario_pomeriggio_fine']);
    const morningEndTimeOptions = filterTimes(morningTimes, watchFields[0]);
    const morningStartTimeOptions = filterTimes(morningTimes, undefined, watchFields[1]);
    const afternoonEndTimeOptions = filterTimes(afternoonTimes, watchFields[2]);
    const afternoonStartTimeOptions = filterTimes(afternoonTimes, undefined, watchFields[3]);

    const currentDate = dayjs();
    const minDate = currentDate;
    const maxDate = currentDate.add(2, 'month');

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",
                    },
                },
            }}>
            <DialogTitle>{mode === 'create' ? 'Nuova Disponibilità' : mode === 'edit' ? 'Modifica Disponibilità' : 'Visualizza Disponibilità'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per {mode === 'create' ? 'creare' : 'modificare'} una disponibilità.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    {user.userType === 1 && (
                        <Grid item xs={12}>
                            <FormControl fullWidth error={!!errors.id_medico_sport}>
                                <Autocomplete
                                    options={users}
                                    getOptionLabel={(option) => `Dott. ${option.nome} ${option.cognome} - ${option.codice_fiscale}`}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Seleziona un medico" variant="outlined" />
                                    )}
                                    onChange={(event, newValue) => {
                                        setValue('id_medico_sport', newValue?.id_utenti);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id_utenti === value.id_utenti}
                                    noOptionsText="Nessun medico trovato"
                                    value={users.find(user => user.id_utenti === getValues().id_medico_sport)}
                                    disabled={mode === 'edit'}
                                />
                                {!!errors.id_medico_sport && (
                                    <FormHelperText>{errors.id_medico_sport.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DatePicker
                            label="Data disponibilità"
                            value={getValues('data_disponibilita') ? dayjs(getValues('data_disponibilita')) : null}
                            onChange={(date) => {
                                if (date) {
                                    setValue('data_disponibilita', date, { shouldValidate: true });
                                } else {
                                    setValue('data_disponibilita', null); // Resetta il valore
                                }
                            }}
                            format="DD-MM-YYYY"
                            disabled={user.userType === 3}
                            minDate={minDate}
                            maxDate={maxDate}
                            sx={{ width: '100%' }}
                            shouldDisableDate={(date) => {
                                const day = date.day();
                                return day === 0 || day === 6;
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_mattina_inizio}>
                            <InputLabel htmlFor="orario_mattina_inizio">Orario Inizio Mattina</InputLabel>
                            <Select
                                id="orario_mattina_inizio"
                                label="Orario Inizio Mattina"
                                {...register("orario_mattina_inizio")}
                                value={watchFields[0] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_mattina_inizio', e.target.value)}
                            >
                                {morningStartTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_mattina_inizio && (
                                <FormHelperText>{errors.orario_mattina_inizio.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_mattina_fine}>
                            <InputLabel htmlFor="orario_mattina_fine">Orario Fine Mattina</InputLabel>
                            <Select
                                id="orario_mattina_fine"
                                label="Orario Fine Mattina"
                                {...register("orario_mattina_fine")}
                                value={watchFields[1] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_mattina_fine', e.target.value)}
                            >
                                {morningEndTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_mattina_fine && (
                                <FormHelperText>{errors.orario_mattina_fine.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_pomeriggio_inizio}>
                            <InputLabel htmlFor="orario_pomeriggio_inizio">Orario Inizio Pomeriggio</InputLabel>
                            <Select
                                id="orario_pomeriggio_inizio"
                                label="Orario Inizio Pomeriggio"
                                {...register("orario_pomeriggio_inizio")}
                                value={watchFields[2] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_pomeriggio_inizio', e.target.value)}
                            >
                                {afternoonStartTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_pomeriggio_inizio && (
                                <FormHelperText>{errors.orario_pomeriggio_inizio.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth error={!!errors.orario_pomeriggio_fine}>
                            <InputLabel htmlFor="orario_pomeriggio_fine">Orario Fine Pomeriggio</InputLabel>
                            <Select
                                id="orario_pomeriggio_fine"
                                label="Orario Fine Pomeriggio"
                                {...register("orario_pomeriggio_fine")}
                                value={watchFields[3] || 'Nessun orario'}
                                onChange={(e: SelectChangeEvent<string>) => setValue('orario_pomeriggio_fine', e.target.value)}
                            >
                                {afternoonEndTimeOptions.map(time => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.orario_pomeriggio_fine && (
                                <FormHelperText>{errors.orario_pomeriggio_fine.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {mode === 'edit' && (
                    <Button onClick={async () => {
                        const data = getValues();
                        if (!data.id_disponibilita) return;
                        try {
                            await deleteAvailability({ id_disponibilita: data.id_disponibilita });
                            enqueueSnackbar('Disponibilità eliminata con successo', { variant: 'success' });
                            props.onUpdate();
                            handleClose();
                        } catch (error) {
                            enqueueSnackbar('Errore durante l\'eliminazione della disponibilità', { variant: 'error' });
                        }
                    }}>Elimina disponibilità</Button>
                )}
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogAvailabilityCreate;

import React, { } from 'react';
import { Box, Button, Card, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { FISCAL_CODE_OR_VAT_REGEX } from '../../../config/regex';
import LogoFitMedical from '../../../components/LogoFitMedical';
import { requestPasswordRecovery } from '../../../api/auth';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';

type FormValues = {
    username: string;
}

const Recover: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState, getValues } = useForm<FormValues>({
        defaultValues: {
            username: ""
        },
    });
    const { errors } = formState;
    const theme = useTheme();

    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const onSubmit = async () => {
        const username = getValues("username");

        try {
            openLoadingDialog();
            const response = await requestPasswordRecovery({
                username: username
            });

            enqueueSnackbar("E-mail di recupero inviata con successo", { variant: "success" });
        } catch (error: any) {
            enqueueSnackbar("Errore nell'invio dell'e-mail di recupero", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            <Card sx={{
                maxWidth: "28rem",
                margin: "1rem",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
                    <Typography variant="h5" gutterBottom textAlign="center">Recupero password</Typography>
                    <Typography variant="body1" style={{ marginBottom: "2rem" }} textAlign="center">Inserisci il tuo codice fiscale o partita IVA per ricevere una e-mail con le istruzioni per il recupero della password.</Typography>
                    <FormControl fullWidth variant='outlined' error={!!errors.username}>
                        <InputLabel htmlFor="username">Codice Fiscale o Partita IVA</InputLabel>
                        <OutlinedInput
                            id="username"
                            type="text"
                            fullWidth
                            label="Codice Fiscale o Partita IVA"
                            {...register("username", {
                                required: "Il campo è obbligatorio",
                                pattern: FISCAL_CODE_OR_VAT_REGEX
                            })}
                        />
                        {errors.username && (
                            <FormHelperText>{errors.username.message}</FormHelperText>
                        )}
                    </FormControl>
                    <Box height="2rem" />
                    <FormControl fullWidth>
                        <Button variant="contained" type="submit">Invia e-mail di recupero</Button>
                    </FormControl>
                </form>
            </Card>
        </Box >
    );
}

export default Recover;

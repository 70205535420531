import { Box, Button, Typography, OutlinedInput, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { sendOtp } from "../../api/otp";

const DialogOtpContent = ({ onCancel, onSubmit, phoneNumber, userId }: { onCancel: () => void, onSubmit: (otp: number) => void, phoneNumber?: string, userId?: number }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    useEffect(() => {
        send();
    }, []);

    const send = async () => {
        try {
            const response = await sendOtp({ phone_number: phoneNumber, id_utente: userId });
            enqueueSnackbar("Codice OTP inviato con successo", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Errore nell'invio del codice OTP", { variant: "error" });
        }
    };

    const handleChange = (index: number, value: string) => {
        if (/^[0-9]?$/.test(value)) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);

            // Sposta il focus al campo successivo se c'è un numero inserito
            if (value && index < otpValues.length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
        if (e.key === 'Backspace' && otpValues[index] === '') {
            // Sposta il focus al campo precedente se c'è una cancellazione
            if (index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
        }
    };

    const handleSubmit = () => {
        const otp = Number(otpValues.join(""));
        onSubmit(otp);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom textAlign="center">
                Conferma i tuoi dati
            </Typography>
            <Typography variant="body1" gutterBottom textAlign="center">
                Inserisci il codice OTP inviato al tuo numero di telefono per confermare la tua identità.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "0.5rem",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                }}
            >
                {otpValues.map((value, index) => (
                    <OutlinedInput
                        key={index}
                        value={value}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        sx={{
                            width: "3rem",
                            textAlign: "center",
                            fontSize: "1.5rem",
                        }}
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center" },
                        }}
                        inputRef={(ref) => inputRefs.current[index] = ref}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: "-1rem"
                }}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}>
                    Conferma
                </Button>
                <Button
                    fullWidth
                    variant="text"
                    color="primary"
                    onClick={() => send()}>
                    Invia di nuovo
                </Button>
                <Button
                    fullWidth
                    variant="text"
                    color="secondary"
                    onClick={() => onCancel()}>
                    Annulla
                </Button>
            </Box>
        </Box>
    );
};

export default DialogOtpContent;

import apiClient from "./base";

export const getCatalogs = async (params?: { id_listino?: number, nome_listino?: string, tipo_profilo?: number, fl_default?: 0 | 1, fl_attivo?: 0 | 1 }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/listini/getListini.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const updateCatalog = async (params: { id_listino: number, nome_listino?: string, tipo_profilo?: number, fl_default?: 0 | 1, fl_attivo?: 0 | 1 }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/listini/update.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const createCatalog = async (params: { nome_listino: string, tipo_profilo: number, fl_default?: 0 | 1, fl_attivo?: 0 | 1 }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/listini/register.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const deleteCatalog = async (params: { id_listino: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/listini/delete.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
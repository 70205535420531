import apiClient from "./base";

export const getUsers = async (params?: { id_utenti?: number, tipi_utenti_id_tipi_utenti?: number, codice_fiscale?: string, piva?: string, id_utente_creatore?: number, id_utente_infermiere?: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/users/fetch.php", params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })

    return response.data;
}

interface MedicalHistory {
    id_anamnesi?: number;
    id_utente?: number;
}

export const getMedicalHistory = async (params: MedicalHistory) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.get("/users/getMedicalHistory", {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    })

    return response.data;
}

export const checkExistingFiscalCode = async (fiscalCode: string) => {
    const response = await apiClient.post(`/users/checkExistingFiscalCode.php`, {
        codice_fiscale: fiscalCode
    });

    return response.data;
}

export const getTokenQRCode = async (params: { id_utente: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/users/requestTokenQrCode.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const association = async (params: { token: string }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/users/association.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const associationByAdmin = async (params: { id_paziente: number, id_medico?: number | null | undefined, id_infermiere?: number | null | undefined }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/users/associationByAdmin.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const createUser = async (params: any) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/users/createUser.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const updateUser = async (params: { id_utente: number } & any) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/users/update.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}
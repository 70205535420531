import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { buyCredits } from "../../api/credits";
import { useModalDialog } from "../../providers/useModalDialog";
import MaterialIcon from "../MaterialIcon";

type FormValues = {
    quantita: number;
};

const DialogBuyCredits: React.FC<DialogProps & { onUpdate: () => void }> = (props) => {
    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm<FormValues>({
        defaultValues: {
            quantita: 0,
        }
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { createModalDialog } = useModalDialog();
    const theme = useTheme();

    useEffect(() => {
        reset();
    }, [props.open, reset]);

    const onSubmit = async () => {
        const data = getValues();

        openLoadingDialog();
        try {
            const data = getValues();

            const response = await buyCredits(data);

            props.onUpdate();
            onClose();

            if (response.credit && response.credit.url) {
                createModalDialog(
                    null, {
                    content: (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '2rem',
                                }}>
                                <Box
                                    sx={{
                                        width: '8rem',
                                        height: '8rem',
                                        borderRadius: '50%',
                                        backgroundColor: theme.palette.success.main,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <MaterialIcon icon="check" size="4rem" color={theme.palette.textDark.primary} />
                                </Box>
                            </Box>
                            <Typography variant="h5" textAlign="center" color={theme.palette.text.primary} gutterBottom>
                                Richiesta inviata.
                            </Typography>
                            <Typography variant="body1" textAlign="center" color={theme.palette.text.secondary}>
                                La tua richiesta di acquisto crediti è stata inviata con successo.
                                <br />
                                Sarai reindirizzato al sito di pagamento.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '2rem',
                                }}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => {
                                        window.location.href = response.credit.url;
                                    }}>
                                    Continua
                                </Button>
                            </Box>
                        </>
                    ),
                });
            }
        } catch (error: any) {
            enqueueSnackbar("Errore nella richiesta di acquisto crediti", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        reset();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Acquista Crediti</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Inserisci il numero di crediti che vuoi acquistare.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.quantita}>
                            <InputLabel htmlFor="quantita">Quantità</InputLabel>
                            <OutlinedInput
                                id="quantita"
                                type="number"
                                label="Quantità"
                                inputProps={{ step: 1 }}
                                {...register('quantita', {
                                    required: 'Questo campo è obbligatorio',
                                    min: { value: 1, message: 'Il valore minimo è di 1 credito' },
                                    max: { value: 1000, message: 'Il valore massimo è di 1000 crediti' },
                                    validate: value => !isNaN(value) || 'Il campo deve essere numerico'
                                })}
                            />
                            {!!errors.quantita && (
                                <FormHelperText>{errors.quantita.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Acquista</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogBuyCredits;

import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, useTheme } from "@mui/material";
import Logo from '../../../components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserProvider } from '../../../providers/useUserProvider';
import { login } from '../../../api/auth';
import { useSnackbar } from 'notistack';
import MaterialIcon from '../../../components/MaterialIcon';
import { useForm } from 'react-hook-form';
import { FISCAL_CODE_OR_VAT_REGEX, PASSWORD_REGEX } from '../../../config/regex';
import LogoFitMedical from '../../../components/LogoFitMedical';

type FormValues = {
    username: string;
    password: string;
}

const Login: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState, getValues } = useForm<FormValues>({
        defaultValues: {
            username: "",
            password: ""
        },
    });
    const { errors } = formState;
    const navigate = useNavigate();
    const { setUserId, setUserType } = useUserProvider();
    const theme = useTheme();
    const { state } = useLocation();

    const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const onSubmit = async () => {
        const username = getValues("username");
        const password = getValues("password");

        if (isRequestInProgress) {
            return;
        }

        try {
            setIsRequestInProgress(true);

            const response = await login({
                codice_fiscale: username,
                password: password,
            });

            enqueueSnackbar("Login effettuato con successo", { variant: "success" });

            setUserId(response.user.data.id_utenti);
            setUserType(response.user.data.tipi_utenti_id_tipi_utenti);

            const status = response.status;

            if (!status) {
                navigate("/pending");
                return;
            }

            sessionStorage.setItem("token", response.token);

            if (state && state.redirect) {
                navigate(state.redirect);
                return;
            }

            navigate("/dashboard");
        } catch (error: any) {
            enqueueSnackbar("Credenziali non valide", { variant: "error" });
        } finally {
            setIsRequestInProgress(false);
        }
    }

    const handleTogglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const handleRegister = () => {
        navigate("/register");
    }

    const handleForgotPassword = () => {
        navigate("/recover");
    }

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            <Card sx={{
                maxWidth: "28rem",
                margin: "1rem",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
                    <FormControl fullWidth variant='outlined' error={!!errors.username}>
                        <InputLabel htmlFor="username">Codice Fiscale o Partita IVA</InputLabel>
                        <OutlinedInput
                            id="username"
                            type="text"
                            fullWidth
                            label="Codice Fiscale o Partita IVA"
                            {...register("username", {
                                required: "Il campo è obbligatorio",
                                pattern: FISCAL_CODE_OR_VAT_REGEX
                            })}
                        />
                        {errors.username && (
                            <FormHelperText>{errors.username.message}</FormHelperText>
                        )}
                    </FormControl>
                    <Box height="1rem" />
                    <FormControl fullWidth variant='outlined' sx={{ marginBottom: "2rem" }} error={!!errors.password}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={isPasswordVisible ? "text" : "password"}
                            fullWidth
                            endAdornment={
                                <IconButton
                                    edge="end"
                                    onClick={handleTogglePasswordVisibility}
                                >
                                    {!isPasswordVisible ? <MaterialIcon icon="visibility" /> : <MaterialIcon icon="visibility_off" />}
                                </IconButton>
                            }
                            label="Password"
                            {...register("password", {
                                required: "Il campo è obbligatorio",
                                pattern: PASSWORD_REGEX
                            })}
                        />
                        {errors.password && (
                            <FormHelperText>{errors.password.message}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth>
                        <Button variant="contained" type="submit">Login</Button>
                    </FormControl>
                </form>
                <Box height="1rem" />
                <FormControl fullWidth>
                    <Button variant="text" onClick={handleForgotPassword}>Hai dimenticato la password?</Button>
                </FormControl>
                <FormControl fullWidth>
                    <Button variant="text" onClick={handleRegister}>Crea un nuovo account</Button>
                </FormControl>
            </Card>
        </Box >
    );
}

export default Login;

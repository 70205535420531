import { Box, Card, Chip, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { cancelBooking, getBookings, sendMessage } from "../../../api/bookings";
import MaterialIcon from "../../../components/MaterialIcon";
import { useNavigate } from "react-router-dom";
import { useModalDialog } from "../../../providers/useModalDialog";
import DialogCreateBooking from "../../../components/dialogs/DialogCreateBooking";

const Bookings: React.FC = () => {
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');
    const [singleDateFilter, setSingleDateFilter] = useState<Date | null>(null);
    const [statusFilter, setStatusFilter] = useState<number | null>(-1);

    const [bookings, setBookings] = useState<any[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const { createModalDialog } = useModalDialog();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState<any>({});
    const [dialogMode, setDialogMode] = useState<'create' | 'edit'>('create');

    const sortableTableRef = useRef<any>(null);

    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await getBookings({ order: 'richieste' });
            setBookings(response.data);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento delle prenotazioni", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, request: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(request);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const columns: HeadCell<any>[] = [
        { id: 'id_richiesta', numeric: false, disablePadding: false, label: 'ID' },
        { id: 'paziente', numeric: false, disablePadding: false, label: 'Paziente' },
        { id: 'medico', numeric: false, disablePadding: false, label: 'Medico' },
        { id: 'stato_richiesta', numeric: false, disablePadding: false, label: 'Stato richiesta' },
        { id: 'data_disponibilita', numeric: false, disablePadding: false, label: 'Data Prenotazione' },
        { id: 'intervallo', numeric: false, disablePadding: false, label: 'Intervallo' },
        { id: 'actions', numeric: false, disablePadding: false, label: 'Azioni' },
    ];

    const handleOpenRequest = () => {
        navigate(`/requests/details/${selectedItem.id_richiesta}`);
    }

    const handleSendLink = async (send: boolean) => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            const response = await sendMessage({ id_richiesta: selectedItem.id_richiesta, send });

            if (send) {
                enqueueSnackbar("Link prenotazione inviato con successo", { variant: "success" });
            } else {
                const data = response.data;
                navigator.clipboard.writeText(data.shortUrl);

                enqueueSnackbar("Link prenotazione copiato negli appunti", { variant: "success" });
            }
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento del link", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }

        handleMenuClose();
    }

    const handleDismissBooking = async () => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            await cancelBooking({ id_richiesta: selectedItem.id_richiesta });
            enqueueSnackbar("Prenotazione annullata con successo", { variant: "success" });

            loadData();
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'annullamento della prenotazione", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }

        handleMenuClose();
    }

    const renderRow = (item: any) => {
        return (
            <TableRow>
                <TableCell>{item.id_richiesta}</TableCell>
                <TableCell>{item.nome_paziente} {item.cognome_paziente}<br />{item.codice_fiscale_paziente}</TableCell>
                <TableCell>{item.nome_medico} {item.cognome_medico}</TableCell>
                <TableCell>
                    <Chip
                        label={item.stato_richiesta === 0 ? 'In attesa referto cardiologico' : item.stato_richiesta === 1 ? 'In attesa prenotazione' : item.stato_richiesta === 2 ? 'Prenotazione completata' : 'Richiesta completata'}
                        color={item.stato_richiesta === 0 ? 'default' : item.stato_richiesta === 1 ? 'default' : item.stato_richiesta === 2 ? 'primary' : 'success'} />
                </TableCell>
                <TableCell>{item.stato_richiesta > 1 ? item.data_disponibilita : 'N/A'}</TableCell>
                <TableCell>
                    {item.stato_richiesta > 1 ? item.fascia_oraria_inizio + "-" + item.fascia_oraria_fine : 'N/A'}
                </TableCell>
                <TableCell>
                    <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                        <MaterialIcon icon="more_vert" />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };

    const showInfoDialog = () => {
        createModalDialog(null, {
            title: 'Informazioni',
            content: (
                <Typography variant="body1">
                    La pagina mostra lo stato delle prenotazioni per le richieste di <strong>Rinnovo del Certificato Medico Sportivo Non Agonistico</strong> che non sono ancora state completate.
                    <br />
                    Le richieste verranno visualizzate non appena verranno assegnate ad un medico dello sport, ovvero alla produzione di un <strong>referto cardiologico</strong>.
                    <br /><br />
                    Per visualizzare i dettagli di una prenotazione, seleziona la riga corrispondente e clicca sull'icona a tre puntini.
                </Typography>
            ),
            canIgnore: true,
            actions: [
                {
                    label: 'Chiudi',
                    onClick: () => null
                }
            ]
        });
    }

    const handleDialogOpen = () => {
        handleMenuClose();

        if (!selectedItem) return;

        setDialogData({
            id_richiesta: selectedItem.id_richiesta,
        });
        setDialogMode('create');
        setDialogOpen(true);
    }

    const handleEditBooking = () => {
        handleMenuClose();

        if (!selectedItem) return;

        setDialogData({
            id_richiesta: selectedItem.id_richiesta,
            id_prenotazione: selectedItem.id_prenotazione,
            id_disponibilita: selectedItem.id_disponibilita,
            fascia_oraria: `${selectedItem.fascia_oraria_inizio}-${selectedItem.fascia_oraria_fine}`
        });
        setDialogMode('edit');
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        setDialogData({});
        setDialogMode('create');
    }

    useEffect(() => {
        if (!sortableTableRef.current) {
            return;
        }

        sortableTableRef.current.setFilter((item: any) => {
            if (statusFilter !== -1 && item.status !== statusFilter) return
            if (singleDateFilter && item.data_disponibilita !== singleDateFilter.toISOString().split('T')[0]) return false;

            const searchTerms = searchText.toLowerCase().split(' ');
            const matchesSearch = searchTerms.every(term =>
                item.id_richiesta?.toString().includes(term) ||
                item.nome_paziente?.toLowerCase().includes(term) ||
                item.cognome_paziente?.toLowerCase().includes(term) ||
                item.codice_fiscale_paziente?.toLowerCase().includes(term) ||
                item.nome_medico?.toLowerCase().includes(term) ||
                item.cognome_medico?.toLowerCase().includes(term) ||
                item.stato_richiesta?.toString().includes(term) ||
                item.data_disponibilita?.includes(term) ||
                item.fascia_oraria_inizio?.includes(term) ||
                item.fascia_oraria_fine?.includes(term)
            );
            return matchesSearch;
        });
    }, [searchText, singleDateFilter, statusFilter]);

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Prenotazioni</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza lo stato delle prenotazioni per le richieste di Rinnovo del Certificato Medico Sportivo Non Agonistico.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <TextField
                    label="Cerca richieste"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                <TextField
                    label="Filtra per data"
                    type="date"
                    sx={{ width: "12rem" }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setSingleDateFilter(e.target.value ? new Date(e.target.value) : null)}
                />
                <FormControl variant="outlined" sx={{ width: "12rem" }}>
                    <InputLabel htmlFor="status">Filtra per stato</InputLabel>
                    <Select
                        id="status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value as number)}
                        label="Filtra per stato"
                    >
                        <MenuItem value={-1}>Tutti</MenuItem>
                        <MenuItem value={0}>In attesa referto cardiologico</MenuItem>
                        <MenuItem value={1}>In attesa prenotazione</MenuItem>
                        <MenuItem value={2}>Prenotazione completata</MenuItem>
                        <MenuItem value={3}>Richiesta completata</MenuItem>
                    </Select>
                </FormControl>
                <IconButton onClick={showInfoDialog} sx={{ marginLeft: "auto" }}>
                    <MaterialIcon icon="info" />
                </IconButton>
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    ref={sortableTableRef}
                    rows={bookings}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleOpenRequest}>
                    <ListItemIcon>
                        <MaterialIcon icon="open_in_new" />
                    </ListItemIcon>
                    <ListItemText>Apri richiesta</ListItemText>
                </MenuItem>
                {(selectedItem?.stato_richiesta == 1 || selectedItem?.stato_richiesta == 2) && (
                    <MenuItem onClick={selectedItem?.stato_richiesta == 2 ? handleEditBooking : handleDialogOpen}>
                        <ListItemIcon>
                            <MaterialIcon icon={selectedItem?.stato_richiesta == 2 ? 'edit' : 'add'} />
                        </ListItemIcon>
                        <ListItemText>{selectedItem?.stato_richiesta == 2 ? 'Modifica prenotazione' : 'Nuova prenotazione'}</ListItemText>
                    </MenuItem>
                )}
                {selectedItem?.stato_richiesta == 2 ? (
                    <MenuItem onClick={handleDismissBooking}>
                        <ListItemIcon>
                            <MaterialIcon icon="delete" />
                        </ListItemIcon>
                        <ListItemText>Elimina prenotazione</ListItemText>
                    </MenuItem>
                ) : <></>}
                {selectedItem?.stato_richiesta == 1 && (
                    <>
                        <MenuItem onClick={() => handleSendLink(true)}>
                            <ListItemIcon>
                                <MaterialIcon icon="send" />
                            </ListItemIcon>
                            <ListItemText>Invia link prenotazione</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => handleSendLink(false)}>
                            <ListItemIcon>
                                <MaterialIcon icon="content_copy" />
                            </ListItemIcon>
                            <ListItemText>Copia link prenotazione</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>
            <DialogCreateBooking
                open={dialogOpen}
                onClose={handleDialogClose}
                initialData={dialogData}
                mode={dialogMode}
                onUpdate={loadData} />
        </Box>
    );
};

export default Bookings;
import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Box, Typography, useTheme, Card, CardActionArea, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { checkHasSignature, getSignature, uploadSignature } from "../../api/signatures";
import { useUserProvider } from "../../providers/useUserProvider";
import { useNavigate } from "react-router-dom";

const DialogUploadSignature: React.FC<DialogProps> = (props) => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const [signature, setSignature] = useState<File | null>(null);
    const [signatureURL, setSignatureURL] = useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSignature = async () => {
            if (!user.userId || user.userType !== 3) {
                navigate('/error', { state: { errorStatus: 401 } });
                return;
            }

            setIsLoading(true);
            try {
                const response = await checkHasSignature({ id_utente: user.userId! });

                if (response.exists) {
                    const fetchedSignature = await getSignature({ id_utente: user.userId! });

                    const _file = new File([fetchedSignature.data], 'signature.png', { type: fetchedSignature.headers['content-type'] });
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const result = reader.result as string;
                        console.log('FileReader result:', result);
                        if (result) {
                            setSignatureURL(result);
                            setSignature(_file);
                        } else {
                            enqueueSnackbar('Errore nella lettura del file', { variant: 'error' });
                        }
                        setIsLoading(false);
                    };

                    reader.onerror = () => {
                        console.error('FileReader error:', reader.error);
                        enqueueSnackbar('Errore nella lettura del file', { variant: 'error' });
                        setIsLoading(false);
                    };

                    reader.readAsDataURL(_file);
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching signature:', error);
                enqueueSnackbar('Errore nel caricamento della firma', { variant: 'error' });
                setIsLoading(false);
            }
        };


        if (props.open) {
            fetchSignature();
        }
    }, [props.open, user, navigate, enqueueSnackbar]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setSignature(e.target.files[0]);
            setSignatureURL(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleFileDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0 && (files[0].type === 'image/png' || files[0].type === 'image/jpeg')) {
            setSignature(files[0]);
            setSignatureURL(URL.createObjectURL(files[0]));
        } else {
            enqueueSnackbar('Formato file non supportato. Accettati solo PNG e JPG.', { variant: 'error' });
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const onSubmit = async () => {
        if (!signature) {
            enqueueSnackbar('Nessuna firma caricata', { variant: 'info' });
            onClose();
            return;
        }

        openLoadingDialog();
        try {
            await uploadSignature({ signature: signature, id_utente: user.userId! });
            enqueueSnackbar('Firma caricata con successo', { variant: 'success' });
            onClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante il caricamento della firma", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        setSignature(null);
        setSignatureURL(null);
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",
                    },
                },
            }}>
            <DialogTitle>La tua firma</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="12rem">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {!signatureURL && (
                            <DialogContentText marginBottom="2rem">Attualmente non è stata caricata nessuna firma. Carica una nuova firma.</DialogContentText>
                        )}
                        {signatureURL ? (
                            <Card
                                sx={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderRadius: ".5rem",
                                    padding: '0',
                                    height: "12rem",
                                    boxShadow: "none",
                                    bgcolor: "transparent",
                                }}>
                                <CardActionArea
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        padding: "1rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => !isLoading && document.getElementById('file-input')?.click()}>
                                    <img
                                        src={signatureURL}
                                        alt="Firma"
                                        style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain' }}
                                    />
                                    <input type="file" hidden id="file-input" onChange={handleFileChange} accept="image/png, image/jpeg" />
                                </CardActionArea>
                            </Card>
                        ) : (
                            <Box
                                border="2px dashed #cccccc"
                                padding="1rem"
                                textAlign="center"
                                onDrop={handleFileDrop}
                                onDragOver={handleDragOver}
                            >
                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                    Trascina qui la tua firma oppure
                                </Typography>
                                <Button variant="contained" color="primary" component="label">
                                    Carica firma
                                    <input type="file" hidden id="file-input" onChange={handleFileChange} accept="image/png, image/jpeg" />
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" disabled={!signature && !signatureURL} onClick={onSubmit}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogUploadSignature;

import apiClient from "./base";

export const uploadContract = async (params: { id_utente: number, file: File }) => {
    const token = sessionStorage.getItem('token');

    const formData = new FormData();
    formData.append('id_utente', params.id_utente.toString());
    formData.append('file', params.file);

    const response = await apiClient.post('/contratti/upload.php', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const getContracts = async (params?: { id_contratto?: number, id_utente?: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/contratti/getContratti.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const approveContract = async (params: { id_contratto: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/contratti/approva.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const rejectContract = async (params: { id_contratto: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/contratti/rifiuta.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const deleteContract = async (params: { id_contratto: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/contratti/delete.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
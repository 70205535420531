import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useSnackbar } from "notistack";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { changePassword } from "../../api/auth";
import { useUserProvider } from "../../providers/useUserProvider";
import MaterialIcon from "../MaterialIcon";

type FormValues = {
    password: string;
    confirm_password: string;
};

type DialogChangePasswordProps = {
    onUpdate?: () => void;
};

const DialogChangePassword = forwardRef(({ onUpdate, ...props }: DialogChangePasswordProps, ref) => {
    const { register, handleSubmit, getValues, formState, reset } = useForm<FormValues>({
        defaultValues: {
            password: "",
            confirm_password: "",
        }
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { user } = useUserProvider(); // Assume we use this to get the current user
    const [userId, setUserId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open: (userId: number) => {
            setUserId(userId); // Default to the current user's ID if not provided
            reset();
            setOpen(true);
        },
        close: () => {
            onClose();
        }
    }));

    useEffect(() => {
        reset();
    }, [open, reset]);

    const onSubmit = async () => {
        if (!userId) {
            enqueueSnackbar("Token o ID utente non valido", { variant: 'error' });
            return;
        }

        const data = getValues();
        openLoadingDialog();
        try {
            await changePassword({ password: data.password, id_utenti: userId });
            enqueueSnackbar("Password cambiata con successo", { variant: 'success' });
            onClose();
            onUpdate?.();
        } catch (error: any) {
            enqueueSnackbar("Errore durante il reset della password", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const onClose = () => {
        reset();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{user.userId != userId ? 'Modifica la password' : 'Modifica la tua password'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="1rem">
                    {user.userId != userId ? 'Inserisci la nuova password per l\'utente selezionato' : 'Compila il form per modificare la tua password'}
                </DialogContentText>
                <FormControl fullWidth variant='outlined' error={!!errors.password}>
                    <InputLabel htmlFor="password">Nuova password</InputLabel>
                    <OutlinedInput
                        id='password'
                        type='password'
                        label='Nuova password'
                        fullWidth
                        {...register("password", {
                            required: "La password è obbligatoria",
                            minLength: {
                                value: 8,
                                message: "La password deve essere di almeno 8 caratteri"
                            }
                        })}
                    />
                    {errors.password && (
                        <FormHelperText>{errors.password.message}</FormHelperText>
                    )}
                </FormControl>
                <Box height="1rem" />
                <FormControl fullWidth variant='outlined' error={!!errors.confirm_password}>
                    <InputLabel htmlFor="confirm_password">Conferma nuova password</InputLabel>
                    <OutlinedInput
                        id='confirm_password'
                        type='password'
                        label='Conferma nuova password'
                        fullWidth
                        {...register("confirm_password", {
                            required: "La conferma della password è obbligatoria",
                            minLength: {
                                value: 8,
                                message: "La password deve essere di almeno 8 caratteri",
                            },
                            validate: (value) => value === getValues("password") || "Le password non corrispondono"
                        })}
                    />
                    {errors.confirm_password && (
                        <FormHelperText>{errors.confirm_password.message}</FormHelperText>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogChangePassword;

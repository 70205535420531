import { Box, Card, Chip, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../components/MaterialIcon";
import { useEffect, useState } from "react";
import SortableTable, { HeadCell } from "../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { getReports, regenerateReport } from "../../../api/reports";
import { report } from "process";
import { useNavigate } from "react-router-dom";
import { downloadDocument } from "../../../api/documents";
import { useModalDialog } from "../../../providers/useModalDialog";
import { useUserProvider } from "../../../providers/useUserProvider";

const Reports: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { createModalDialog } = useModalDialog();
    const { user } = useUserProvider();  // Prendi il tipo di utente loggato

    const [searchText, setSearchText] = useState<string>('');
    const [serviceFilter, setServiceFilter] = useState<number | null>(-1);
    const [categoryFilter, setCategoryFilter] = useState<number | null>(-1);
    const [reports, setReports] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        loadReports();
    }, []);

    const loadReports = async () => {
        openLoadingDialog();
        try {
            let response;

            if (user.userType == 3) {
                response = await getReports({ id_utente_creatore: user.userId });
            } else {
                response = await getReports();
            }

            let _reports = response.data;

            setReports(_reports);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei referti", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleNavigateToRequest = () => {
        handleMenuClose();

        if (!selectedItem) {
            return;
        }

        navigate(`/requests/details/${selectedItem.id_richiesta}`);
    }

    const handleDownloadReport = async () => {
        handleMenuClose();

        if (!selectedItem) {
            return;
        }

        openLoadingDialog();
        try {
            const response = await downloadDocument({ id_documento: selectedItem.id_documento });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `referto-${selectedItem.id_referto}.pdf`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            enqueueSnackbar("Errore durante il download del documento", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleShowReportText = () => {
        handleMenuClose();

        createModalDialog(null, {
            title: `Referto #${selectedItem?.id_referto}`,
            content: (
                <>
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        <strong>Medico</strong>: {selectedItem?.id_utente_creatore ? `${selectedItem?.nome_utente} ${selectedItem?.cognome_utente}` : 'N/A'}
                    </Typography>
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        <strong>Data creazione</strong>: {selectedItem?.timestamp_creazione}
                    </Typography>
                    <Box height="1rem" />
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        {selectedItem?.testo_refertazione}
                    </Typography>
                </>
            ),
            actions: [
                {
                    label: 'Chiudi',
                    onClick: () => null
                }
            ]
        });
    }

    const handleRegenerateReportDialog = () => {
        handleMenuClose();

        createModalDialog(null, {
            title: `Rigenera referto #${selectedItem?.id_referto}`,
            content: (
                <>
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        Sei sicuro di voler rigenerare il referto?
                    </Typography>
                </>
            ),
            actions: [
                {
                    label: 'Annulla',
                    onClick: () => null
                },
                {
                    label: 'Conferma',
                    onClick: async () => {
                        handleMenuClose();
                        openLoadingDialog();
                        try {
                            const response = await regenerateReport({ id_referto: selectedItem?.id_referto });
                            enqueueSnackbar('Referto rigenerato con successo', { variant: 'success' });
                        }
                        catch (error: any) {
                            enqueueSnackbar("Errore durante la rigenerazione del referto", { variant: 'error' });
                        } finally {
                            closeLoadingDialog();
                        }
                    }
                }
            ]
        });
    };

    const columns: HeadCell<any>[] = [
        { id: 'id_referto', numeric: false, disablePadding: false, label: 'ID' },
        { id: 'id_richiesta', numeric: false, disablePadding: false, label: 'ID Richiesta' },
        { id: 'nome_servizio', numeric: false, disablePadding: false, label: 'Servizio' },
        { id: 'nome_categoria', numeric: false, disablePadding: false, label: 'Tipo Referto' },
        { id: 'medico', numeric: false, disablePadding: false, label: 'Medico' },
        { id: 'timestamp_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
        { id: 'actions', numeric: false, disablePadding: false, label: 'Azioni' }
    ];

    const filteredList = reports.filter((item) => {
        if (serviceFilter !== -1 && item.id_servizio !== serviceFilter) {
            return false;
        }

        if (categoryFilter !== -1 && item.id_categoria !== categoryFilter) {
            return false;
        }

        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.id_referto.toString().toLowerCase().includes(term) ||
            item.id_richiesta.toString().toLowerCase().includes(term) ||
            item.nome_servizio.toLowerCase().includes(term) ||
            item.nome_categoria.toLowerCase().includes(term) ||
            (item.nome_utente && item.nome_utente.toLowerCase().includes(term)) ||
            (item.cognome_utente && item.cognome_utente.toLowerCase().includes(term)) ||
            item.timestamp_creazione.toLowerCase().includes(term)
        );
        return matchesSearch;
    });

    let _services = new Set();
    let _types = new Set();

    reports.forEach((item) => {
        _services.add(item.id_servizio);
        _types.add(item.id_categoria);
    });

    let services = Array.from(_services);
    let types = Array.from(_types);

    services = services.map((service) => (
        {
            id_servizio: service,
            nome_servizio: reports.find((item) => item.id_servizio === service)?.nome_servizio
        }
    ));

    types = types.map((type) => (
        {
            id_categoria: type,
            nome_categoria: reports.find((item) => item.id_categoria === type)?.nome_categoria
        }
    ));

    const renderRow = (item: any) => (
        <TableRow key={item.id_referto}>
            <TableCell>{item.id_referto}</TableCell>
            <TableCell>{item.id_richiesta}</TableCell>
            <TableCell>
                <Chip
                    label={item.nome_servizio}
                    color="primary" />
            </TableCell>
            <TableCell>
                <Chip
                    label={item.nome_categoria}
                    color="primary"
                    variant="outlined" />
            </TableCell>
            <TableCell>
                {item.id_utente_creatore && (
                    `${item.nome_utente} ${item.cognome_utente}`
                )}
                {item.id_utente_creatore === null && (
                    'N/A'
                )}
            </TableCell>
            <TableCell>{item.timestamp_creazione}</TableCell>
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Referti</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza i referti generati dai medici sulla piattaforma.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <TextField
                    label="Cerca referti"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                {user.userType !== 3 && (
                    <>
                        <FormControl sx={{ width: "12rem" }}>
                            <InputLabel htmlFor="service-filter">Servizio</InputLabel>
                            <Select
                                id="service-filter"
                                value={serviceFilter}
                                label="Servizio"
                                onChange={(e) => setServiceFilter(e.target.value as number)}
                            >
                                <MenuItem value={-1}>Tutti</MenuItem>
                                {services.map((service: any) => (
                                    <MenuItem key={service.id_servizio} value={service.id_servizio}>{service.nome_servizio}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: "12rem" }}>
                            <InputLabel htmlFor="category-filter">Tipo di Referto</InputLabel>
                            <Select
                                id="category-filter"
                                value={categoryFilter}
                                label="Tipo di Referto"
                                onChange={(e) => setCategoryFilter(e.target.value as number)}>
                                <MenuItem value={-1}>Tutti</MenuItem>
                                {types.map((type: any) => (
                                    <MenuItem key={type.id_categoria} value={type.id_categoria}>{type.nome_categoria}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )}

            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleNavigateToRequest}>
                    <ListItemIcon>
                        <MaterialIcon icon="open_in_new" />
                    </ListItemIcon>
                    <ListItemText>Apri richiesta</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleShowReportText}>
                    <ListItemIcon>
                        <MaterialIcon icon="troubleshoot" />
                    </ListItemIcon>
                    <ListItemText>Visualizza testo</ListItemText>
                </MenuItem>
                {selectedItem?.id_documento && (
                    <MenuItem onClick={handleDownloadReport}>
                        <ListItemIcon>
                            <MaterialIcon icon="download" />
                        </ListItemIcon>
                        <ListItemText>Scarica referto</ListItemText>
                    </MenuItem>
                )}
                {user.userType === 1 && (
                    <MenuItem onClick={handleRegenerateReportDialog}>
                        <ListItemIcon>
                            <MaterialIcon icon="refresh" />
                        </ListItemIcon>
                        <ListItemText>Rigenera documento</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default Reports;
import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserProvider } from '../../../providers/useUserProvider';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import MaterialIcon from '../../../components/MaterialIcon';
import { getTokenQRCode, getUsers } from '../../../api/users';
import LogoFitMedical from '../../../components/LogoFitMedical';
import { getMedicalHistories } from '../../../api/medicalHistory';
import dayjs from 'dayjs';
import QRCode from 'react-qr-code';
import DialogUploadSignature from '../../../components/dialogs/DialogUploadSignature';
import DialogChangePassword from '../../../components/dialogs/DialogChangePassword';
import DialogUserEdit from '../../../components/dialogs/DialogUserEdit';

const Profile: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const [profile, setProfile] = useState<any>(null);
    const [medicalHistory, setMedicalHistory] = useState<any>(null);

    useEffect(() => {
        if (!user.userType || !user.userId) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        loadData();
    }, []);

    const loadData = async () => {
        openLoadingDialog();

        try {
            const [profile, medicalHistory] = await Promise.all([getUsers({ id_utenti: user.userId! }), getMedicalHistories({ id_utente: user.userId! })]);
            setProfile(profile.data[0]);
            setMedicalHistory(medicalHistory.anamnesi[0]);
        } catch (error) {
            enqueueSnackbar("Errore nel caricamento del profilo", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Il tuo Profilo</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e modifica le informazioni del tuo profilo
            </Typography>
            {profile && (
                <>
                    <Card sx={{
                        padding: "3rem",
                        borderRadius: "1rem",
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <LogoFitMedical style={{ height: "4.5rem", marginBottom: "2rem" }} />
                        <Typography variant="h5" gutterBottom>{profile.nome} {profile.cognome}</Typography>
                        <Chip label={
                            profile.tipi_utenti_id_tipi_utenti === 1 ? "Amministratore" :
                                profile.tipi_utenti_id_tipi_utenti === 2 ? "Infermiere" :
                                    profile.tipi_utenti_id_tipi_utenti === 3 ? "Medico" :
                                        profile.tipi_utenti_id_tipi_utenti === 4 ? "Palestra" : "Paziente"
                        } />
                        <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom marginTop="1rem">{profile.email}</Typography>
                        <Typography variant="body1" color={theme.palette.text.secondary}>Data di iscrizione: {dayjs(profile.timestamp_creazione).format("DD/MM/YYYY")}</Typography>
                    </Card>
                    <Grid container spacing="1rem">
                        <Grid item xs={12}>
                            <ProfileCard profile={profile} reloadFunc={loadData} />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
};

const ProfileCard: React.FC<{ profile: any, reloadFunc: () => void }> = ({ profile, reloadFunc }) => {
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState(false);
    const [qrCodeToken, setQrCodeToken] = useState<string | null>(null);

    const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);

    const dialogEditProfileRef = useRef<any>(null);
    const dialogChangePasswordRef = useRef<any>(null);

    useEffect(() => {
        if ([2, 5].includes(user.userType!)) {
            openLoadingDialog();
            loadQrCodeToken()
                .then(token => setQrCodeToken(token))
                .catch(error => {
                    console.error(error);
                    enqueueSnackbar("Errore nel caricamento del QR Code", { variant: "error" });
                })
                .finally(() => closeLoadingDialog());
        }
    }, []);

    const loadQrCodeToken = async () => {
        const response = await getTokenQRCode({ id_utente: user.userId! });
        return response.token;
    }

    const QRCodeDialog: React.FC = () => {
        const url = `${window.location.origin}/association?token=${qrCodeToken}`;
        const qrcodeRef = useRef<any>(null);
        const { enqueueSnackbar } = useSnackbar();

        const handleShareQRCode = () => {
            if (qrcodeRef.current) {
                const svg = qrcodeRef.current.outerHTML;
                const blob = new Blob([svg], { type: 'image/svg+xml' });
                const url = URL.createObjectURL(blob);

                const img = new Image();
                img.src = url;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const scaleFactor = 4;
                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;

                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        ctx.scale(scaleFactor, scaleFactor);
                        ctx.drawImage(img, 0, 0);
                        URL.revokeObjectURL(url);

                        const pngUrl = canvas.toDataURL('image/png');

                        if (navigator.share) {
                            navigator.share({
                                title: 'Il mio QR Code',
                                text: 'Condividi il QR code per effettuare l\'associazione',
                                files: [
                                    new File([pngUrl], 'qr_code.png', { type: 'image/png' })
                                ]
                            }).catch((error) => {
                                console.error('Errore nella condivisione:', error);
                            });
                        } else {
                            const a = document.createElement('a');
                            a.href = pngUrl;
                            a.download = 'qr_code.png';
                            a.click();
                        }
                    } else {
                        enqueueSnackbar("Errore nella conversione del QR Code", { variant: "error" });
                    }
                };
            } else {
                enqueueSnackbar("Errore nel caricamento del QR Code", { variant: "error" });
            }
        };

        return (
            <Dialog
                open={qrCodeDialogOpen}
                onClose={() => setQrCodeDialogOpen(false)}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    <Typography variant="h6" fontWeight="bold">Il tuo QR Code</Typography>
                    <Typography variant="body2" color="textSecondary">Condividi il tuo QR code per effettuare l'associazione</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "1rem 0"
                    }}>
                        <QRCode
                            value={url}
                            size={400}
                            ref={qrcodeRef}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setQrCodeDialogOpen(false)}
                        color="primary"
                        variant="text">
                        Chiudi
                    </Button>
                    <Button
                        onClick={handleShareQRCode}
                        color="primary"
                        variant="contained">
                        Condividi
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleOpenChangePasswordDialog = () => {
        console.log('Opening change password dialog');

        if (!dialogChangePasswordRef.current) {
            return;
        }

        dialogChangePasswordRef.current.open(user.userId!);
    }

    const handleOpenProfileDialog = () => {
        if (!dialogEditProfileRef.current) {
            return;
        }

        dialogEditProfileRef.current.open(profile);
    }

    return (
        <>
            <Card sx={{
                padding: "0", borderRadius: "1rem",
            }}>
                <List>
                    <ListSubheader>Informazioni Personali</ListSubheader>
                    <ListItem>
                        <ListItemText primary="Nome" secondary={profile.nome} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Cognome" secondary={profile.cognome} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Codice Fiscale" secondary={profile.codice_fiscale} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Email" secondary={profile.email} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Telefono" secondary={profile.telefono} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Residenza" secondary={`${profile.indirizzo_residenza}, ${profile.cap_residenza} ${profile.denominazione_comune} (${profile.sigla_provincia})`} />
                    </ListItem>
                    <Divider />
                    <ListSubheader>Impostazioni</ListSubheader>
                    <ListItem button onClick={handleOpenProfileDialog}>
                        <ListItemIcon>
                            <MaterialIcon icon="edit" />
                        </ListItemIcon>
                        <ListItemText primary="Modifica Profilo" secondary="Modifica le informazioni del tuo profilo" />
                    </ListItem>
                    <ListItem button onClick={handleOpenChangePasswordDialog}>
                        <ListItemIcon>
                            <MaterialIcon icon="lock" />
                        </ListItemIcon>
                        <ListItemText primary="Modifica Password" secondary="Cambia la tua password" />
                    </ListItem>
                    {[2, 4, 5].includes(user.userType!) && (
                        <ListItem button onClick={() => setQrCodeDialogOpen(true)}>
                            <ListItemIcon>
                                <MaterialIcon icon="qr_code" />
                            </ListItemIcon>
                            <ListItemText primary="Visualizza QR Code" secondary="Visualizza il tuo QR Code" />
                        </ListItem>
                    )}
                    {user.userType === 3 && (
                        // Show the signature
                        <ListItem button onClick={() => setSignatureDialogOpen(true)}>
                            <ListItemIcon>
                                <MaterialIcon icon="signature" />
                            </ListItemIcon>
                            <ListItemText primary="Firma" secondary="Visualizza la tua firma" />
                        </ListItem>
                    )}
                    <ListItem button onClick={() => navigate('/logout')}>
                        <ListItemIcon>
                            <MaterialIcon icon="logout" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" secondary="Esci dalla piattaforma" />
                    </ListItem>
                </List>
            </Card>
            {[2, 5].includes(user.userType!) && <QRCodeDialog />}
            {user.userType == 3 && <DialogUploadSignature open={signatureDialogOpen} onClose={() => setSignatureDialogOpen(false)} />}
            <DialogChangePassword
                ref={dialogChangePasswordRef} />
            <DialogUserEdit
                ref={dialogEditProfileRef}
                onUpdate={reloadFunc} />
        </>
    );
}

export default Profile;

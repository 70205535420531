import apiClient from "./base";

export const getServices = async (params?: { id_servizio?: number, id_tipo_servizio?: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/servizi/getServizi.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const getServicesAvailable = async (params: { id_utente: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/servizi/getServiziDisponibili.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
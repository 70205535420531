import apiClient from "./base";

export const checkHasSignature = async (params: { id_utente: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/signatures/checkHasSignature.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const getSignature = async (params: { id_utente: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/signatures/retrieve.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    return { headers: response.headers, data: response.data };
}

export const uploadSignature = async (params: { id_utente: number, signature: File }) => {
    const token = sessionStorage.getItem('token');

    const formData = new FormData();
    formData.append('id_utente', params.id_utente.toString());
    formData.append('file', params.signature);

    const response = await apiClient.post('/signatures/upload.php', formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}
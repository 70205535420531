import apiClient from "./base";

export const getExaminationQuestions = async (params?: { id_domanda?: number, id_servizio?: number, id_utente_creatore?: number, fl_attivo?: 0 | 1 }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/domande_esame_obiettivo/fetch.php", params, {
        headers: {
            "Authorization": `Bearer ${token}`
        },
    });
    return response.data;
}
import apiClient from "./base";

export const uploadDocument = async (params: { file: File, id_categoria: number, id_utente?: number, id_richiesta?: number }) => {
    const token = sessionStorage.getItem("token");

    const formData = new FormData();
    formData.append("file", params.file);
    formData.append("id_categoria", params.id_categoria.toString());

    if (params.id_utente) {
        formData.append("id_utente", params.id_utente.toString());
    }

    if (params.id_richiesta) {
        formData.append("id_richiesta", params.id_richiesta.toString());
    }

    const response = await apiClient.post("/documenti/upload.php", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
        }
    });

    return response.data;
};

export const downloadDocument = async (params: { id_documento: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/documenti/download.php`, params, {
        headers: {
            "Authorization": `Bearer ${token}`
        },
        responseType: "blob"
    });

    return { headers: response.headers, data: response.data };
};

export const getDocumentCategories = async (params?: { id_categoria?: number, hidden?: 0 | 1 }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/documenti/categorie.php`, params, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    return response.data;
}

export const getMyDocuments = async () => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/documenti/getMyDocuments.php`, {}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    return response.data;
}

export const getDocuments = async (params?: { id_documento?: number, id_utente?: number, id_categoria?: number, id_utente_caricamento?: number, id_richiesta?: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/documenti/fetch.php`, params, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    return response.data;
}

export const unassignDocument = async (params: { id_documento: number, id_richiesta?: number, id_utente?: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post(`/documenti/unassign.php`, params, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    return response.data;
}
import apiClient from "./base";

export const getMedicalHistories = async (params?: { id_anamnesi?: number, id_utente?: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/anamnesi/fetch.php", params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}

export const createMedicalHistory = async (params: any) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/anamnesi/register.php", params, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    return response.data;
}
import { Box, Button, Autocomplete, TextField, Card, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Step, StepLabel, Stepper, Typography, useTheme, useMediaQuery } from "@mui/material";
import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import MaterialIcon from "../../../../components/MaterialIcon";
import { useUserProvider } from "../../../../providers/useUserProvider";
import { useNavigate } from "react-router-dom";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { MEDICAL_HISTORY_KEYS } from "../../../../common/utils";
import { getMedicalHistories } from "../../../../api/medicalHistory";
import { getExaminationQuestions } from "../../../../api/examination";
import { createRequest, getSummaryOnBuy } from "../../../../api/requests";
import { getServicesAvailable } from "../../../../api/services";
import { getUsers } from "../../../../api/users";
import { getDocumentCategories, uploadDocument } from "../../../../api/documents";
import { useModalDialog } from "../../../../providers/useModalDialog";
import DialogOtpContent from "../../../../components/dialogs/DialogOtpContent";
import DialogUserCreate from "../../../../components/dialogs/DialogUserCreate";

const RequestCreate: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();
    const { createModalDialog, closeModalDialog } = useModalDialog();

    const [activeStep, setActiveStep] = useState(0);
    const serviceSelectionRef = useRef<any>(null);
    const patientSelectionRef = useRef<any>(null);
    const medicalHistoryFormRef = useRef<any>(null);
    const examinationFormRef = useRef<any>(null);
    const filesFormRef = useRef<any>(null);

    const [serviceId, setServiceId] = useState<number | null>(null);
    const [patientId, setPatientId] = useState<number | null>(null);
    const [medicalHistory, setMedicalHistory] = useState<any>(null);
    const [examination, setExamination] = useState<any>(null);
    const [documents, setDocuments] = useState<any[]>([]);

    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        if (!user.userType || ![2, 4, 5, 6].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }
    }, []);

    const handleNextStep = async () => {
        switch (activeStep) {
            case 0:
                setServiceId(await serviceSelectionRef.current.getServiceId());
                break;
            case 1:
                if (user.userType !== 5) setPatientId(await patientSelectionRef.current.getPatientId());
                break;
            case 2:
                setMedicalHistory(medicalHistoryFormRef.current.getMedicalHistoryData());
                break;
            case 3:
                if (user.userType !== 5) setExamination(examinationFormRef.current.getExaminationData());
                break;
            case 4:
                setDocuments(filesFormRef.current.getDocumentsList());
                break;
        }

        if (activeStep === steps.length - 1) {
            try {
                createModalDialog('otp', {
                    content: (
                        <DialogOtpContent
                            onSubmit={async (otp) => {
                                try {
                                    openLoadingDialog();

                                    const response = await createRequest({
                                        id_paziente: patientId!,
                                        id_servizio: serviceId!,
                                        anamnesi: medicalHistory,
                                        esame_obiettivo: examination,
                                        otp: otp
                                    });
                                    closeModalDialog('otp');

                                    createModalDialog(
                                        'custom-request-dialog',
                                        {
                                            content: (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            marginBottom: '2rem',
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                width: '8rem',
                                                                height: '8rem',
                                                                borderRadius: '50%',
                                                                backgroundColor: theme.palette.success.main,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                            <MaterialIcon icon="check" size="4rem" color={theme.palette.textDark.primary} />
                                                        </Box>
                                                    </Box>
                                                    <Typography variant="h5" textAlign="center" color={theme.palette.text.primary} gutterBottom>
                                                        Acquisto completato.
                                                    </Typography>
                                                    {response.metodo_pagamento === "stripe" ? (
                                                        <Typography variant="body1" textAlign="center" color={theme.palette.text.secondary}>
                                                            Verrai reindirizzato al pagamento.
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="body1" textAlign="center" color={theme.palette.text.secondary}>
                                                            La richiesta è stata acquistata con successo e potrà essere visualizzata nella sezione "Richieste".
                                                        </Typography>
                                                    )}
                                                    <Box
                                                        sx={{
                                                            marginTop: '2rem',
                                                        }}>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                if (response.metodo_pagamento === "stripe" && response.stripe_product.url) {
                                                                    window.location.href = response.stripe_product.url;
                                                                } else {
                                                                    navigate('/requests', { replace: true });
                                                                    closeModalDialog('custom-request-dialog');
                                                                }
                                                            }}>
                                                            {response.metodo_pagamento === "stripe" ? 'Vai al pagamento' : 'Vai alle richieste'}
                                                        </Button>
                                                    </Box>
                                                </>
                                            ),
                                        });
                                    for (const document of documents) {
                                        try {
                                            await uploadDocument({
                                                id_richiesta: response.id_richiesta,
                                                id_categoria: document.category!,
                                                file: document.file,
                                                id_utente: patientId!
                                            });
                                        } catch (error: any) {
                                            enqueueSnackbar("Errore durante il caricamento del Documento", { variant: "error" });
                                        }
                                    }
                                } catch (error: any) {
                                    enqueueSnackbar("Errore durante il caricamento della pagina", { variant: "error" });
                                } finally {
                                    closeLoadingDialog();
                                }
                            }}
                            onCancel={() => closeModalDialog('otp')}
                            userId={user.userId!}
                        />
                    )
                });
            } catch (error: any) {
                enqueueSnackbar("Errore durante il caricamento della pagina", { variant: "error" });
            } finally {
                closeLoadingDialog();
            }
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    const handlePreviousStep = () => {
        if (activeStep === 0) return;
        setActiveStep(activeStep - 1);
    }

    const steps = [
        { title: 'Seleziona il servizio', alternativeTitle: 'Servizio', child: <ServiceSelection ref={serviceSelectionRef} serviceId={serviceId} onSubmit={handleNextStep} /> },
        { title: 'Seleziona il paziente', alternativeTitle: 'Paziente', child: user.userType !== 5 ? <PatientSelection ref={patientSelectionRef} patientId={patientId} onBack={handlePreviousStep} onSubmit={handleNextStep} /> : null },
        { title: user.userType === 5 ? 'Inserisci l\'anamnesi' : 'Inserisci l\'anamnesi del paziente', alternativeTitle: 'Anamnesi', child: <MedicalHistoryForm ref={medicalHistoryFormRef} patientId={patientId} initialData={medicalHistory} onBack={handlePreviousStep} onSubmit={handleNextStep} /> },
        { title: 'Inserisci l\'esame obiettivo', alternativeTitle: 'Esame Obiettivo', child: user.userType !== 5 ? <ExaminationForm ref={examinationFormRef} initialData={examination} serviceId={serviceId} onBack={handlePreviousStep} onSubmit={handleNextStep} /> : null },
        { title: 'Carica i documenti', alternativeTitle: 'Documenti', child: <FilesForm ref={filesFormRef} initialData={documents} onBack={handlePreviousStep} onSubmit={handleNextStep} /> },
        { title: 'Conferma e invia la richiesta', alternativeTitle: 'Conferma', child: <ConfirmCreation patientId={patientId} serviceId={serviceId} documents={documents} onBack={handlePreviousStep} onSubmit={handleNextStep} /> },
    ];

    const filteredSteps = steps.filter(step => step.child !== null);

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Richieste<MaterialIcon icon="arrow_right" />Nuova richiesta
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Crea una nuova richiesta di telerefertazione.
            </Typography>
            <Grid container spacing="1rem">
                <Grid item xs={12} lg={3}>
                    <Card
                        sx={{
                            padding: "2rem",
                            borderRadius: "1rem",
                        }}>
                        {matchesLG ? (

                            <Stepper
                                orientation="vertical"
                                activeStep={activeStep}>
                                {filteredSteps.map((step, index) => (
                                    <Step key={index}>
                                        <StepLabel
                                            optional={
                                                <Typography variant="caption" color={theme.palette.text.secondary}>
                                                    {step.title}
                                                </Typography>
                                            }>
                                            Step {index + 1} di {filteredSteps.length}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        ) : (
                            <Stepper
                                alternativeLabel
                                orientation="horizontal"
                                activeStep={activeStep}>
                                {filteredSteps.map((step, index) => (
                                    <Step key={index}>
                                        <StepLabel>
                                            {step.alternativeTitle}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Card
                        sx={{
                            padding: "2rem",
                            borderRadius: "1rem",
                        }}>
                        {filteredSteps[activeStep].child}
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

const ServiceSelection = forwardRef<any, { serviceId?: number | null, onSubmit: () => void }>(({ serviceId, onSubmit }, ref) => {
    const theme = useTheme();
    const [services, setServices] = useState<any[]>([]);
    const [selectedService, setSelectedService] = useState<number | null>(serviceId || null);
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUserProvider();

    useEffect(() => {
        openLoadingDialog();
        loadServices()
            .then(services => setServices(services))
            .catch(() => enqueueSnackbar('Errore nel caricamento dei servizi', { variant: 'error' }))
            .finally(() => closeLoadingDialog());
    }, []);

    useImperativeHandle(ref, () => ({
        getServiceId: () => selectedService,
    }));

    const loadServices = async () => {
        const response = await getServicesAvailable({ id_utente: user.userId! });
        return response.servizi;
    }

    return (
        <>
            <Typography variant="h6" fontWeight="bold" color={theme.palette.text.primary} gutterBottom>
                Seleziona il servizio
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} marginBottom="1rem">
                Seleziona il servizio di telerefertazione che desideri richiedere.
            </Typography>
            {services.length > 0 ? (
                <List sx={{ marginBottom: "1rem" }}>
                    {services.map((service) => (
                        <ListItem key={service.id_servizio} button onClick={() => setSelectedService(service.id_servizio)}>
                            <Radio
                                edge="start"
                                checked={selectedService === service.id_servizio}
                                tabIndex={-1}
                                disableRipple
                            />
                            <ListItemText
                                primary={service.nome_servizio}
                                secondary={
                                    <Typography variant="body2" color={theme.palette.text.secondary}>
                                        <strong>Prezzo:</strong> {service.costo} crediti
                                        <br />
                                        {service.descrizione || 'Nessuna descrizione disponibile'}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body1" color={theme.palette.text.secondary}>
                    Nessun servizio disponibile
                </Typography>
            )}
            <Box display="flex" flexDirection="row" justifyContent="end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={!selectedService}>
                    Successivo
                </Button>
            </Box>
        </>
    );
});

const PatientSelection = forwardRef<any, { patientId?: number | null, onBack: () => void, onSubmit: () => void }>(({ patientId, onBack, onSubmit }, ref) => {
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [patients, setPatients] = useState<any[]>([]);
    const [selectedPatient, setSelectedPatient] = useState<any | null>(null);

    const createPatientDialogRef = useRef<any>(null);

    useEffect(() => {
        loadData();
    }, []);

    useImperativeHandle(ref, () => ({
        getPatientId: () => {
            const id = selectedPatient ? selectedPatient.id_utenti : null;
            console.log("getPatientId: ", id); // Aggiungi il console.log per verificare
            return id;
        },
    }));

    const handleDialogOpen = () => {
        if (!createPatientDialogRef.current) return;

        createPatientDialogRef.current.open({ tipi_utenti_id_tipi_utenti: 5 });
    }

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await getUsers({ id_utente_infermiere: user.userId! });
            setPatients(response.data);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <>
            <Typography variant="h6" fontWeight="bold" color="primary" gutterBottom>
                Seleziona il paziente
            </Typography>
            <Autocomplete
                options={patients}
                getOptionLabel={(option) => `${option.nome} ${option.cognome} - ${option.codice_fiscale}`}
                renderInput={(params) => (
                    <TextField {...params} label="Seleziona un paziente" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                    setSelectedPatient(newValue);
                }}
                value={selectedPatient}
                isOptionEqualToValue={(option, value) => option.id_utenti === value.id_utenti}
                noOptionsText="Nessun paziente trovato"
            />
            <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                onClick={handleDialogOpen}
            >
                Aggiungi paziente
            </Button>
            <Box display="flex" flexDirection="row" justifyContent="end" gap="1rem" marginTop="1rem">
                <Button
                    variant="text"
                    color="primary"
                    onClick={onBack}>
                    Indietro
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const id = selectedPatient ? selectedPatient.id_utenti : null;
                        console.log("Selected Patient ID:", id); // Aggiungi console.log al passaggio allo step successivo
                        onSubmit();
                    }}
                    disabled={!selectedPatient}>
                    Successivo
                </Button>
            </Box>
            <DialogUserCreate
                ref={createPatientDialogRef}
                onUpdate={() => {
                    loadData();
                }} />
        </>
    );
});


const MedicalHistoryForm = forwardRef<any, { patientId?: number | null, initialData?: any, onBack: () => void, onSubmit: () => void }>(({ patientId, initialData, onBack, onSubmit }, ref) => {
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [defaultAnswers, setDefaultAnswers] = useState<any>(null);
    const [answers, setAnswers] = useState<any>(null);

    useEffect(() => {
        if (!patientId) return;

        openLoadingDialog();
        loadMedicalHistory()
            .then((medicalHistory) => {
                const { id_anamnesi, id_utente, tempo_creazione, nome, cognome, ...rest } = medicalHistory;
                setDefaultAnswers(rest);

                if (initialData) {
                    setAnswers(initialData);
                } else {
                    setAnswers(rest);
                }
            })
            .catch(() => enqueueSnackbar('Errore nel caricamento dell\'anamnesi', { variant: 'error' }))
            .finally(() => closeLoadingDialog());
    }, []);

    useImperativeHandle(ref, () => ({
        getMedicalHistoryData: () => answers,
    }));

    const loadMedicalHistory = async () => {
        const response = await getMedicalHistories({ id_utente: patientId! });
        return response.anamnesi[0];
    }

    const handleInputChange = (key: string, value: any) => {
        setAnswers((prevAnswers: any) => ({ ...prevAnswers, [key]: value }));
    };

    const handleResetDefault = () => {
        setAnswers(defaultAnswers);
    }

    return (
        <>
            <Typography variant="h6" fontWeight="bold" color={theme.palette.text.primary} gutterBottom>
                Inserisci l'anamnesi del paziente
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} marginBottom="1rem">
                Inserisci l'anamnesi del paziente, per garantire un servizio di qualità.
            </Typography>
            <Box marginBottom="2rem">
                {answers && MEDICAL_HISTORY_KEYS.map((category) => (
                    <Box marginBottom="1rem" key={category.category}>
                        <Typography variant="subtitle1" fontWeight="bold" color={theme.palette.text.primary} gutterBottom>
                            {category.category}
                        </Typography>
                        <Grid container spacing="1rem">
                            {category.keys.map((key) => (
                                <Fragment key={key.key}>
                                    {key.type === 'number' && (
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor={key.key}>{key.name}</InputLabel>
                                                <OutlinedInput
                                                    id={key.key}
                                                    type="number"
                                                    label={key.name}
                                                    value={answers[key.key]}
                                                    onChange={(e) => {
                                                        handleInputChange(key.key, e.target.value);
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {key.type === 'text' && (
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor={key.key}>{key.name}</InputLabel>
                                                <OutlinedInput
                                                    id={key.key}
                                                    type="text"
                                                    label={key.name}
                                                    value={answers[key.key]}
                                                    onChange={(e) => {
                                                        handleInputChange(key.key, e.target.value);
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {key.type === 'radio' && (
                                        <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset" fullWidth>
                                                <FormLabel component="legend">{key.name}</FormLabel>
                                                <RadioGroup
                                                    row
                                                    value={answers[key.key]}
                                                    onChange={(e) => {
                                                        handleInputChange(key.key, e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel value="1" control={<Radio />} label="Si" />
                                                    <FormControlLabel value="0" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                            {key.ifYes && answers[key.key] === "1" && (
                                                <FormControl fullWidth sx={{ marginTop: "1rem" }}>
                                                    <InputLabel htmlFor={key.ifYes.key}>{key.ifYes.name}</InputLabel>
                                                    <OutlinedInput
                                                        id={key.ifYes.key}
                                                        type="text"
                                                        label={key.ifYes.name}
                                                        value={answers[key.ifYes.key]}
                                                        onChange={(e) => {
                                                            handleInputChange(key.ifYes.key, e.target.value);
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                    )}
                                </Fragment>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="end" gap="1rem">
                <Button
                    variant="text"
                    color="primary"
                    onClick={handleResetDefault}
                    sx={{
                        marginRight: "auto"
                    }}>
                    Ripristina valori di default
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    onClick={onBack}>
                    Indietro
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}>
                    Successivo
                </Button>
            </Box>
        </>
    );
});

const ExaminationForm = forwardRef<any, { serviceId?: number | null, initialData?: any, onBack: () => void, onSubmit: (data: any) => void }>(({ serviceId, initialData, onBack, onSubmit }, ref) => {
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [questions, setQuestions] = useState<any[]>([]);
    const [answers, setAnswers] = useState<any[]>([]);

    const { register } = useForm();

    useEffect(() => {
        if (!serviceId) return;

        openLoadingDialog();
        loadQuestions()
            .then((questions) => {
                setQuestions(questions);
                setAnswers(questions.map((q: any) => ({
                    id_domanda: q.id_domanda,
                    domanda: q.domanda,
                    risposta: initialData?.find((a: any) => a.id_domanda === q.id_domanda)?.risposta || "",
                    sotto_risposte: q.tipo_domanda === 'convo' ? JSON.parse(q.domande_aggiuntive).map(() => initialData?.find((a: any) => a.id_domanda === q.id_domanda)?.sotto_risposte || "") : []
                })));
            })
            .catch(() => enqueueSnackbar('Errore nel caricamento dell\'esame obiettivo', { variant: 'error' }))
            .finally(() => closeLoadingDialog());
    }, []);

    useImperativeHandle(ref, () => ({
        getExaminationData: () => answers,
    }));

    const loadQuestions = async () => {
        const response = await getExaminationQuestions({ id_servizio: serviceId!, fl_attivo: 1 });
        return response.domande;
    }

    const handleAnswerChange = (index: number, value: any) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].risposta = value;
        setAnswers(updatedAnswers);
    }

    const handleSubAnswerChange = (index: number, subIndex: number, value: any) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].sotto_risposte[subIndex] = value;
        setAnswers(updatedAnswers);
    }

    const allQuestionsAnswered = questions.every((q, index) =>
        q.fl_obbligatorio ? answers[index].risposta.trim() !== "" : true &&
            (q.tipo_domanda === 'convo' && answers[index].risposta === "1" ? answers[index].sotto_risposte.every((sub: any) => sub.trim() !== "") : true)
    );

    return (
        <>
            <Typography variant="h6" fontWeight="bold" color={theme.palette.text.primary} gutterBottom>
                Inserisci l'esame obiettivo
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} marginBottom="1rem">
                Inserisci l'esame obiettivo del paziente, per garantire un servizio di qualità.
            </Typography>
            {questions.length > 0 && (
                questions.map((question, index) => (
                    <Box key={question.id_domanda} marginBottom="1rem">
                        {question.tipo_domanda === 'text' && (
                            <FormControl fullWidth >
                                <InputLabel>{question.domanda}</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    label={question.domanda}
                                    multiline
                                    rows={4}
                                    value={answers[index].risposta}
                                    {...register(`risposta_${question.id_domanda}`, {
                                        required: question.fl_obbligatorio ? "Inserisci la risposta" : undefined,
                                        onChange: (e) => handleAnswerChange(index, e.target.value),
                                        pattern: /^[0-9]*$/,
                                    })}
                                />
                            </FormControl>
                        )}
                        {question.tipo_domanda === 'number' && (
                            <FormControl fullWidth >
                                <InputLabel>{question.domanda}</InputLabel>
                                <OutlinedInput
                                    type="number"
                                    label={question.domanda}
                                    value={answers[index].risposta}
                                    {...register(`risposta_${question.id_domanda}`, {
                                        required: question.fl_obbligatorio ? "Inserisci la risposta" : undefined,
                                        onChange: (e) => handleAnswerChange(index, e.target.value),
                                        pattern: /^[0-9]*$/,
                                    })}
                                />
                            </FormControl>
                        )}
                        {question.tipo_domanda === 'boolean' && (
                            <FormControl component="fieldset" fullWidth required={question.fl_obbligatorio}>
                                <FormLabel component="legend">{question.domanda}</FormLabel>
                                <RadioGroup row value={answers[index].risposta} onChange={(e) => handleAnswerChange(index, e.target.value)}>
                                    <FormControlLabel value="Sì" control={<Radio />} label="Si" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        )}
                        {question.tipo_domanda === 'convo' && (
                            <>
                                <FormControl component="fieldset" fullWidth required={question.fl_obbligatorio}>
                                    <FormLabel component="legend">{question.domanda}</FormLabel>
                                    <RadioGroup row value={answers[index].risposta} onChange={(e) => handleAnswerChange(index, e.target.value)}>
                                        <FormControlLabel value="Sì" control={<Radio />} label="Si" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                                {answers[index].risposta === "1" && JSON.parse(question.domande_aggiuntive).map((subQuestion: any, subIndex: number) => (
                                    <FormControl fullWidth sx={{ marginTop: "1rem" }} key={subIndex}>
                                        <InputLabel>{subQuestion.valore}</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            label={subQuestion.valore}
                                            value={answers[index].sotto_risposte[subIndex]}
                                            onChange={(e) => handleSubAnswerChange(index, subIndex, e.target.value)}
                                            required={question.fl_obbligatorio}
                                        />
                                    </FormControl>
                                ))}
                            </>
                        )}
                    </Box>
                ))
            )}
            <Box display="flex" flexDirection="row" justifyContent="end" gap="1rem">
                <Button
                    variant="text"
                    color="primary"
                    onClick={onBack}>
                    Indietro
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(answers)}
                    disabled={!allQuestionsAnswered}>
                    Successivo
                </Button>
            </Box>
        </>
    );
});

const FilesForm = forwardRef<any, { initialData?: any, onBack: () => void, onSubmit: () => void }>(({ initialData, onBack, onSubmit }, ref) => {
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [documentsList, setDocumentsList] = useState<{ file: File | null, category: number | null }[]>(initialData || []);
    const [categories, setCategories] = useState<any[]>([]);

    useEffect(() => {
        openLoadingDialog();
        loadCategories()
            .then(categories => setCategories(categories))
            .catch(() => enqueueSnackbar('Errore nel caricamento delle categorie', { variant: 'error' }))
            .finally(() => closeLoadingDialog());
    }, []);

    useImperativeHandle(ref, () => ({
        getDocumentsList: () => documentsList,
    }));

    const loadCategories = async () => {
        const response = await getDocumentCategories({ hidden: 0 });
        return response.categorie;
    };

    const handleFilesChange = (files: FileList) => {
        const newDocuments = Array.from(files).map(file => ({ file, category: null }));
        setDocumentsList(prev => [...prev, ...newDocuments]);
    };

    const handleCategoryChange = (index: number, category: number | null) => {
        const updatedDocuments = [...documentsList];
        updatedDocuments[index] = { ...updatedDocuments[index], category };
        setDocumentsList(updatedDocuments);
    };

    const removeDocumentField = (index: number) => {
        const updatedDocuments = documentsList.filter((_, i) => i !== index);
        setDocumentsList(updatedDocuments);
    };

    const handleFileDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFilesChange(files);
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const allDocumentsHaveCategory = documentsList.every(doc => doc.file && doc.category !== null);

    return (
        <>
            <Typography variant="h6" fontWeight="bold" color={theme.palette.text.primary} gutterBottom>
                Carica i documenti
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} marginBottom="1rem">
                Carica tutti i documenti necessari all'espletamento del servizio.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        border="2px dashed #cccccc"
                        padding="1rem"
                        textAlign="center"
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                    >
                        <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                            Trascina qui i tuoi file oppure
                        </Typography>
                        <Button variant="contained" color="primary" component="label">
                            Carica documenti
                            <input type="file" hidden multiple onChange={(e) => handleFilesChange(e.target.files!)} />
                        </Button>
                    </Box>
                </Grid>
                {documentsList.map((doc, index) => (
                    <Grid item xs={12} key={index} display="flex" flexDirection="row" alignItems="center" gap="1rem">
                        <FormControl variant="outlined" fullWidth sx={{ flex: 2 }}>
                            <InputLabel htmlFor={`file-${index}`}>File</InputLabel>
                            <OutlinedInput
                                id={`file-${index}`}
                                type="text"
                                value={doc.file ? doc.file.name : ""}
                                label="File"
                                readOnly
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth sx={{ flex: 1 }}>
                            <InputLabel htmlFor={`category-${index}`}>Categoria</InputLabel>
                            <Select
                                id={`category-${index}`}
                                value={doc.category || ""}
                                onChange={(e) => handleCategoryChange(index, e.target.value as number)}
                                label="Categoria"
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category.id_categoria} value={category.id_categoria}>
                                        {category.nome_categoria}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <IconButton
                            color="secondary"
                            aria-label="delete file"
                            onClick={() => removeDocumentField(index)}
                        >
                            <MaterialIcon icon="delete" />
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
            <Box display="flex" flexDirection="row" justifyContent="end" gap="1rem" marginTop="1rem">
                <Button
                    variant="text"
                    color="primary"
                    onClick={onBack}>
                    Indietro
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={!allDocumentsHaveCategory}>
                    Successivo
                </Button>
            </Box>
        </>
    );
});

const ConfirmCreation = forwardRef<any, { serviceId: number | null, patientId: number | null, documents: { file: File, category: string }[], onBack: () => void, onSubmit: () => void }>(({ serviceId, patientId, documents, onBack, onSubmit }, ref) => {
    const theme = useTheme();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [summaryData, setSummaryData] = useState<any>(null);

    useEffect(() => {
        openLoadingDialog();
        Promise.all([loadSummaryOnBuy()])
            .then(([summary]) => setSummaryData(summary))
            .catch(() => enqueueSnackbar('Errore nel caricamento del riepilogo', { variant: 'error' }))
            .finally(() => closeLoadingDialog());
    }, []);

    const loadSummaryOnBuy = async () => {
        const response = await getSummaryOnBuy({ id_servizio: serviceId!, id_paziente: patientId! });
        return response.data;
    }

    return (
        <>
            <Typography variant="h6" fontWeight="bold" color={theme.palette.text.primary} gutterBottom>
                Riepilogo Acquisto
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} marginBottom="1rem">
                Verifica i tuoi dati prima di confermare l'acquisto della richiesta.
            </Typography>
            {summaryData && (
                <Box marginBottom="1rem">
                    <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                        <span style={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Paziente:</span> {summaryData.paziente.nome} {summaryData.paziente.cognome} - {summaryData.paziente.codice_fiscale}
                    </Typography>
                    <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                        <span style={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Servizio:</span> {summaryData.servizio.nome_servizio}
                    </Typography>
                    <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                        <span style={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Costo:</span> {summaryData.servizio.costo} crediti
                    </Typography>
                    <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                        <span style={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Metodo di pagamento:</span> {summaryData.wallet.saldo && summaryData.wallet.saldo > 0 ? 'Wallet' : parseInt(summaryData.wallet.crediti_residui) > summaryData.servizio.costo ? 'Crediti' : 'Stripe'}
                    </Typography>
                    {documents.length > 0 && (
                        <>
                            <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                                <span style={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>Documenti:</span>
                            </Typography>
                            <ul style={{ margin: 0 }}>
                                {documents.map((doc, index) => (
                                    <li key={index}>
                                        <Typography variant="body1" color={theme.palette.text.secondary} gutterBottom>
                                            {doc.file.name}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </Box>
            )}
            <Box display="flex" flexDirection="row" justifyContent="end" gap="1rem" marginTop="1rem">
                <Button
                    variant="text"
                    color="primary"
                    onClick={onBack}>
                    Indietro
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}>
                    Conferma acquisto
                </Button>
            </Box>
        </>
    );
});

export default RequestCreate;

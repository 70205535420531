import { createBrowserRouter } from "react-router-dom";
import App from "../pages/app/App";
import Login from "../pages/auth/login/Login";
import Dashboard from "../pages/app/dashboard/Dashboard";
import Logout from "../pages/auth/logout/Logout";
import Error from "../pages/error/Error";
import Questionnaire from "../pages/app/questionnaire/Questionnaire";
import Availabilities from "../pages/app/availabilities/Availabilities";
import Main from "../pages/Main";
import Users from "../pages/app/users/Users";
import Profile from "../pages/app/profile/Profile";
import Catalogs from "../pages/app/settings/catalogs/Catalogs";
import Contracts from "../pages/app/settings/contracts/Contracts";
import Wallets from "../pages/app/settings/wallets/Wallets";
import Reports from "../pages/app/reports/Reports";
import Payments from "../pages/app/payments/Payments";
import Prices from "../pages/app/settings/catalogs/prices/Prices";
import Register from "../pages/auth/register/Register";
import RoleSelection from "../pages/auth/register/RoleSelection";
import Requests from "../pages/app/requests/Requests";
import Pending from "../pages/auth/pending/Pending";
import Wallet from "../pages/app/wallet/Wallet";
import RequestCreate from "../pages/app/requests/create/RequestCreate";
import Association from "../pages/association/Association";
import PaymentSuccess from "../pages/payments/success/PaymentSuccess";
import UserDetails from "../pages/app/users/details/UserDetails";
import Documents from "../pages/app/documents/Documents";
import RequestDetails from "../pages/app/requests/details/RequestDetails";
import Specializations from "../pages/app/settings/specializations/Specializations";
import Bookings from "../pages/app/bookings/Bookings";
import ContractDetails from "../pages/app/settings/contracts/details/ContractDetails";
import Book from "../pages/book/Book";
import Calls from "../pages/app/calls/Calls";
import Recover from "../pages/auth/recover/Recover";
import ResetPassword from "../pages/auth/reset-password/ResetPassword";
import Consent from "../pages/consent/Consent";
import AvailabilitiesManager from "../pages/app/settings/availabilities-manager/AvailabilitiesManager";
import QRTermsManager from "../pages/app/settings/qr-terms-manager/QRTermsManager";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: "/",
                element: <App />,
                children: [
                    {
                        index: true,
                        path: "dashboard",
                        element: <Dashboard />,
                    },
                    {
                        path: "calls",
                        element: <Calls />
                    },
                    {
                        path: "users",
                        children: [
                            {
                                index: true,
                                element: <Users />
                            },
                            {
                                path: "details/:userId",
                                element: <UserDetails />
                            }
                        ]
                    },
                    {
                        path: "patients",
                        children: [
                            {
                                index: true,
                                element: <Users />
                            },
                            {
                                path: "details/:userId",
                                element: <UserDetails />
                            }
                        ]
                    },
                    {
                        path: "requests",
                        children: [
                            {
                                index: true,
                                element: <Requests />
                            },
                            {
                                path: "create",
                                element: <RequestCreate />
                            },
                            {
                                path: "details/:requestId",
                                element: <RequestDetails />
                            }
                        ]
                    },
                    {
                        path: "reports",
                        element: <Reports />
                    },
                    {
                        path: "bookings",
                        element: <Bookings />
                    },
                    {
                        path: "documents",
                        element: <Documents />
                    },
                    {
                        path: "payments",
                        element: <Payments />
                    },
                    {
                        path: "wallet",
                        element: <Wallet />
                    },
                    {
                        path: "settings",
                        children: [
                            {
                                path: "contracts",
                                children: [
                                    {
                                        index: true,
                                        element: <Contracts />
                                    },
                                    {
                                        path: "details",
                                        element: <ContractDetails />
                                    }
                                ]
                            },
                            {
                                path: "qr-terms",
                                element: <QRTermsManager />
                            },
                            {
                                path: "availabilities",
                                element: <AvailabilitiesManager />
                            },
                            {
                                path: "specializations",
                                element: <Specializations />
                            },
                            {
                                path: "catalogs",
                                children: [
                                    {
                                        index: true,
                                        element: <Catalogs />
                                    },
                                    {
                                        path: "prices",
                                        element: <Prices />
                                    }
                                ]
                            },
                            {
                                path: "wallets",
                                element: <Wallets />
                            },
                            {
                                path: "questionnaire-management",
                                element: <Questionnaire />
                            }
                        ]
                    },
                    {
                        path: "profile",
                        element: <Profile />
                    },
                    {
                        path: "availabilities",
                        element: <Availabilities />
                    },
                    {
                        path: "questionnaire",
                        element: <Questionnaire />
                    },
                ]
            },
            {
                path: "book/:token",
                element: <Book />
            },
            {
                path: "sign-terms",
                element: <Consent />
            },
            {
                path: "association",
                element: <Association />
            },
            {
                path: "login",
                element: <Login />
            },
            {
                path: "register",
                children: [
                    {
                        index: true,
                        element: <RoleSelection />
                    },
                    {
                        path: "patient",
                        element: <Register />
                    },
                    {
                        path: "nurse",
                        element: <Register />
                    },
                    {
                        path: "sports-doctor",
                        element: <Register />
                    },
                    {
                        path: "gym",
                        element: <Register />
                    },
                    {
                        path: "pharmacy",
                        element: <Register />
                    }
                ],
            },
            {
                path: "logout",
                element: <Logout />
            },
            {
                path: "recover",
                element: <Recover />
            },
            {
                path: "reset-password",
                element: <ResetPassword />
            },
            {
                path: "error",
                element: <Error />
            },
            {
                path: "pending",
                element: <Pending />
            },
            {
                path: "*",
                element: null
            },
            {
                path: "payments",
                children: [
                    {
                        path: "success",
                        element: <PaymentSuccess />
                    },
                    {
                        path: "cancel",
                        element: <PaymentSuccess />
                    }
                ]
            }
        ]
    },
]);

export default router;

import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    Chip,
    Fab,
    FormControl,
    IconButton,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserProvider } from '../../../providers/useUserProvider';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import SortableTable, { HeadCell } from '../../../components/SortableTable';
import MaterialIcon from '../../../components/MaterialIcon';
import { getRequests } from '../../../api/requests';
import { getBookings, sendMessage } from '../../../api/bookings';
import { useModalDialog } from '../../../providers/useModalDialog';
import DialogReassignRequest from '../../../components/dialogs/DialogReassignRequest';

const Requests: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { createModalDialog } = useModalDialog();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<number | null>(-1);
    const [revisableFilter, setRevisableFilter] = useState<number | null>(-1);
    const [requests, setRequests] = useState<any[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRequest, setSelectedRequest] = useState<any>(null);

    const sortableTableRef = useRef<any>(null);

    const refDialogReassignRequest = useRef<any>(null);

    useEffect(() => {
        if (!user.userType || !user.userId) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        loadRequests();
    }, []);

    const loadRequests = async () => {
        openLoadingDialog();
        try {
            const params =
                user.userType === 2 || user.userType === 4 || user.userType === 6 ? { id_utente_richiedente: user.userId } :
                    user.userType === 3 ? { id_utente_destinatario: user.userId } :
                        user.userType === 5 ? { id_paziente: user.userId } : {};
            const response = await getRequests(params as any);
            setRequests(response.data);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento delle richieste", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, request: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedRequest(request);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRequest(null);
    };

    const columns: HeadCell<any>[] = [
        { id: 'id_richiesta', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome_richiedente', numeric: false, disablePadding: false, label: 'Richiedente' },
        { id: 'nome_paziente', numeric: false, disablePadding: false, label: 'Paziente' },
        { id: 'codice_fiscale_paziente', numeric: false, disablePadding: false, label: 'Codice Fiscale' },
        { id: 'id_servizio', numeric: false, disablePadding: false, label: 'Servizio' },
        { id: 'stato_richiesta', numeric: false, disablePadding: false, label: 'Stato Richiesta' },
        { id: 'tempo_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const renderRow = (request: any) => (
        <TableRow key={request.id_richiesta}>
            <TableCell align='right'>{request.id_richiesta}</TableCell>
            <TableCell>{`${request.nome_richiedente} ${request.cognome_richiedente}`}</TableCell>
            <TableCell>{`${request.nome_paziente} ${request.cognome_paziente}`}</TableCell>
            <TableCell>{request.codice_fiscale_paziente}</TableCell>
            <TableCell>
                <Chip label={request.nome_servizio || 'N/A'} />
            </TableCell>
            <TableCell>
                <Chip
                    label={request.stato_richiesta === 0 ? 'Creata' : request.stato_richiesta === 1 ? 'In lavorazione' : request.stato_richiesta === 2 ? 'Televisita effettuata' : 'Completata'}
                    color={request.stato_richiesta === 0 ? 'default' : request.stato_richiesta === 1 ? 'default' : request.stato_richiesta === 2 ? 'primary' : 'success'} />
                {((user.userType === 3 || user.userType === 1) && request.stato_richiesta === 2 && request.rivedibile === 1) && (
                    <Chip label="Rivedibile" color="warning" />
                )}
            </TableCell>
            <TableCell>{new Date(request.tempo_creazione).toLocaleString()}</TableCell>
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, request)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    useEffect(() => {
        if (!sortableTableRef.current) return;

        sortableTableRef.current.setFilter((request: any) => {
            if (statusFilter !== -1 && request.stato_richiesta !== statusFilter) {
                return false;
            }
            if (revisableFilter != -1 && request.rivedibile !== revisableFilter) {
                return false;
            }

            const searchTerms = searchText.toLowerCase().split(' ');
            const matchesSearch = searchTerms.every(term =>
                (request.nome_richiedente?.toLowerCase() || '').includes(term) ||
                (request.cognome_richiedente?.toLowerCase() || '').includes(term) ||
                (request.nome_paziente?.toLowerCase() || '').includes(term) ||
                (request.cognome_paziente?.toLowerCase() || '').includes(term) ||
                (request.nome_servizio?.toLowerCase() || '').includes(term) ||
                (request.codice_fiscale_paziente?.toLowerCase() || '').includes(term) ||
                (request.id_richiesta?.toString() || '').includes(term)
            );
            return matchesSearch;
        });
    }, [searchText, statusFilter, revisableFilter]);

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleOpenRequest = () => {
        navigate(`/requests/details/${selectedRequest.id_richiesta}`);
    };

    const handleSendLink = async (send: boolean) => {
        openLoadingDialog();
        try {
            const response = await sendMessage({ id_richiesta: selectedRequest.id_richiesta, send });

            if (send) {
                enqueueSnackbar("Link prenotazione inviato con successo", { variant: "success" });
            } else {
                const data = response.data;
                navigator.clipboard.writeText(data.shortUrl);

                enqueueSnackbar("Link prenotazione copiato negli appunti", { variant: "success" });
            }
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento del link", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleShowBookingDetails = async () => {
        if (!selectedRequest) return;

        try {
            const response = await getBookings({ id_richiesta: selectedRequest.id_richiesta });
            let data = response.data;

            if (data.length === 0) {
                createModalDialog(null, {
                    title: 'Richiesta in lavorazione',
                    content: (
                        <>
                            <ListItem>
                                <ListItemIcon>
                                    <MaterialIcon icon='info' />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant='body1' color={theme.palette.text.secondary}>
                                        La prenotazione non è stata ancora effettuata perché la richiesta è in attesa del referto cardiologico. Riprova più tardi.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </>
                    ),
                    actions: [
                        {
                            label: 'Chiudi',
                            onClick() {
                                return;
                            },
                        }
                    ]
                })
            } else {
                data = data.filter((booking: any) => {
                    return booking.id_prenotazione !== null && booking.timestamp_rifiuto === null && booking.fascia_oraria_inizio !== null && booking.fascia_oraria_fine !== null && booking.data_disponibilita !== null;
                });

                if (data.length === 0) {
                    createModalDialog(null, {
                        title: 'Prenotazione non effettuata',
                        content: (
                            <>
                                <ListItem>
                                    <ListItemIcon>
                                        <MaterialIcon icon='info' />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='body1' color={theme.palette.text.secondary}>
                                            Il paziente non ha ancora effettuato la prenotazione. Verifica che il paziente abbia ricevuto il link per effettuare la prenotazione, quindi riprova più tardi.
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </>
                        ),
                        actions: [
                            {
                                label: 'Chiudi',
                                onClick() {
                                    return;
                                },
                            }
                        ]
                    })
                } else {
                    const booking = data[0];

                    createModalDialog(null, {
                        title: 'Dettagli prenotazione',
                        content: (
                            <>
                                <Typography variant='body1' color={theme.palette.text.secondary} marginBottom="1rem">
                                    Il paziente ha effettuato la prenotazione per la data e l'ora indicate.
                                </Typography>
                                <ListItem>
                                    <ListItemIcon>
                                        <MaterialIcon icon='event' />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='body1' color={theme.palette.text.secondary}>
                                            <strong>Data disponibilità:</strong> {new Date(booking.data_disponibilita).toLocaleDateString()}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <MaterialIcon icon='schedule' />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='body1' color={theme.palette.text.secondary}>
                                            <strong>Ora inizio:</strong> {booking.fascia_oraria_inizio}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <MaterialIcon icon='schedule' />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='body1' color={theme.palette.text.secondary}>
                                            <strong>Ora fine:</strong> {booking.fascia_oraria_fine}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </>
                        ),
                        actions: [
                            {
                                label: 'Chiudi',
                                onClick() {
                                    return;
                                },
                            }
                        ]
                    });
                }
            }
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei dettagli della prenotazione", { variant: "error" });
        } finally {
            handleMenuClose();
        }
    }

    const handleReassignRequest = async () => {
        if (!selectedRequest) return;

        refDialogReassignRequest.current.open(selectedRequest.id_richiesta);
        handleMenuClose();
    }

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Richieste</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                {user.userType === 2 || user.userType === 4 ? 'Visualizza l\'elenco delle richieste effettuate.' :
                    user.userType === 3 ? 'Visualizza l\'elenco delle richieste ricevute.' :
                        user.userType === 5 ? 'Visualizza l\'elenco delle richieste effettuate.' :
                            'Visualizza l\'elenco delle richieste.'}
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                {user.userType === 2 || user.userType === 4 || user.userType === 5 ? (
                    <Fab
                        color="primary"
                        size="medium"
                        aria-label="add"
                        onClick={() => navigate('/requests/create')}>
                        <MaterialIcon icon="add" />
                    </Fab>
                ) : null}
                <TextField
                    label="Cerca richieste"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                <FormControl variant="outlined" sx={{ width: "12rem" }}>
                    <InputLabel htmlFor="status">Filtra per stato</InputLabel>
                    <Select
                        id="status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value as number)}
                        label="Filtra per stato"
                    >
                        <MenuItem value={-1}>Tutte</MenuItem>
                        <MenuItem value={0}>Creata</MenuItem>
                        <MenuItem value={1}>In lavorazione</MenuItem>
                        <MenuItem value={2}>Televisita effettuata</MenuItem>
                        <MenuItem value={3}>Completata</MenuItem>
                    </Select>
                </FormControl>
                {(user.userType && [1, 3].includes(user.userType)) && (
                    <FormControl variant="outlined" sx={{ width: "12rem" }}>
                        <InputLabel htmlFor="revisable">Filtra per rivedibilità</InputLabel>
                        <Select
                            id="revisable"
                            value={revisableFilter}
                            onChange={(e) => setRevisableFilter(e.target.value as number)}
                            label="Filtra per rivedibilità"
                        >
                            <MenuItem value={-1}>Tutte</MenuItem>
                            <MenuItem value={1}>Rivedibili</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    ref={sortableTableRef}
                    rows={requests}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleOpenRequest}>
                    <ListItemIcon>
                        <MaterialIcon icon="open_in_new" />
                    </ListItemIcon>
                    <ListItemText>Apri richiesta</ListItemText>
                </MenuItem>
                {(selectedRequest?.fl_completo_richiesta == 0 && selectedRequest?.id_servizio == 5 && user.userType == 1 && selectedRequest?.stato_richiesta < 2) && (
                    <MenuItem onClick={handleReassignRequest}>
                        <ListItemIcon>
                            <MaterialIcon icon='stethoscope' />
                        </ListItemIcon>
                        <ListItemText>Assegna richiesta</ListItemText>
                    </MenuItem>
                )}
                {(selectedRequest?.fl_completo_richiesta == 0 && selectedRequest?.id_servizio == 5 && selectedRequest?.stato_richiesta == 1) && (
                    <>
                        {user.userType === 1 && (
                            <MenuItem onClick={() => handleSendLink(true)}>
                                <ListItemIcon>
                                    <MaterialIcon icon="send" />
                                </ListItemIcon>
                                <ListItemText>Invia link prenotazione</ListItemText>
                            </MenuItem>
                        )}
                        {[1, 2, 4, 6].includes(user.userType ?? 0) && (
                            <MenuItem onClick={() => handleSendLink(false)}>
                                <ListItemIcon>
                                    <MaterialIcon icon="content_copy" />
                                </ListItemIcon>
                                <ListItemText>Copia link prenotazione</ListItemText>
                            </MenuItem>
                        )}
                    </>
                )}
                {(selectedRequest?.fl_completo_richiesta == 0 && selectedRequest?.id_servizio == 5) && (
                    <MenuItem onClick={handleShowBookingDetails}>
                        <ListItemIcon>
                            <MaterialIcon icon='event' />
                        </ListItemIcon>
                        <ListItemText>Dettagli prenotazione</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <DialogReassignRequest
                ref={refDialogReassignRequest}
                onUpdate={() => null} />
        </Box>
    );
};

export default Requests;

import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { getAvailableDates, createBooking, updateBooking } from '../../api/bookings';
import dayjs from 'dayjs';
import { getRequests } from '../../api/requests';

type FormValues = {
    id_richiesta?: number;
    id_prenotazione?: number;
    id_disponibilita?: number;
    fascia_oraria?: string;
};

const DialogCreateBooking: React.FC<DialogProps & { initialData?: FormValues; mode: 'create' | 'edit'; onUpdate: () => void }> = (props) => {
    const { register, handleSubmit, setValue, formState, reset, watch } = useForm<FormValues>({
        defaultValues: props.initialData
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const [dates, setDates] = useState<any[]>([]);
    const [selectedAvailability, setSelectedAvailability] = useState<number | null>(null);
    const [selectedTime, setSelectedTime] = useState<string | null>(null);

    useEffect(() => {
        reset(props.initialData);
        if (props.initialData?.id_richiesta) {
            loadAvailableDates(props.initialData.id_richiesta);
        }
    }, [props.open, props.initialData?.id_richiesta]);

    const loadAvailableDates = async (id_richiesta: number) => {
        try {
            let response = await getRequests({ id_richiesta });
            const id_medico = response.data[0].id_utente_destinatario;

            response = await getAvailableDates({ id_medico });
            setDates(response.data);
        } catch (error) {
            enqueueSnackbar('Errore durante il caricamento delle date disponibili', { variant: 'error' });
        }
    };

    const handleDateSelect = (event: SelectChangeEvent) => {
        const date = event.target.value as any;
        setSelectedAvailability(date);
        setSelectedTime(null);
    };

    const handleTimeSelect = (event: SelectChangeEvent) => {
        setSelectedTime(event.target.value as string);
    };

    const onSubmit = async (data: FormValues) => {
        if (!selectedTime) return;

        if (!selectedAvailability) return;

        const [fascia_oraria_inizio, fascia_oraria_fine] = selectedTime.split('-');

        try {
            if (props.mode === 'create') {
                await createBooking({
                    id_richiesta: data.id_richiesta!,
                    id_disponibilita: selectedAvailability,
                    fascia_oraria_inizio,
                    fascia_oraria_fine
                });
            } else {
                await updateBooking({
                    id_prenotazione: data.id_prenotazione!,
                    id_disponibilita: selectedAvailability,
                    fascia_oraria_inizio,
                    fascia_oraria_fine
                });
            }

            enqueueSnackbar('Prenotazione salvata con successo', { variant: 'success' });
            props.onUpdate();
            onClose();
        } catch (error) {
            enqueueSnackbar('Errore durante il salvataggio della prenotazione', { variant: 'error' });
        }
    };

    const onClose = () => {
        props.onClose?.({}, 'escapeKeyDown');
        reset();
    };

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{props.mode === 'create' ? 'Nuova Prenotazione' : 'Modifica Prenotazione'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">
                    Compila il form per {props.mode === 'create' ? 'creare' : 'modificare'} una prenotazione.
                </DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.id_disponibilita}>
                            <InputLabel htmlFor="date">Data</InputLabel>
                            <Select
                                id="date"
                                label="Data"
                                value={dates.find(d => d.id_disponibilita === selectedAvailability)?.id_disponibilita ?? ''}
                                onChange={handleDateSelect}
                            >
                                {dates.length === 0 && <MenuItem value="" disabled>Nessuna data disponibile</MenuItem>}
                                {dates.map((date) => (
                                    <MenuItem key={date.id_disponibilita} value={date.id_disponibilita}>
                                        {dayjs(date.data_disponibilita).format('DD MMMM YYYY')}
                                    </MenuItem>
                                ))}
                            </Select>
                            {!!errors.id_disponibilita && (
                                <FormHelperText>{errors.id_disponibilita.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.fascia_oraria}>
                            <InputLabel htmlFor="time">Orario</InputLabel>
                            <Select
                                id='time'
                                label="Orario"
                                value={selectedTime ?? ''}
                                onChange={handleTimeSelect}
                            >
                                {selectedAvailability === null && <MenuItem value="" disabled>Seleziona una data</MenuItem>}
                                {dates.find((d) => d.id_disponibilita === selectedAvailability)?.availableIntervals.map((time: string) => {
                                    const ct = time.substring(0, 5);
                                    const nt = dayjs(time, 'HH:mm').add(15, 'minute').format('HH:mm');
                                    return (
                                        <MenuItem key={time} value={`${ct}-${nt}`}>
                                            {`${ct}-${nt}`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {!!errors.fascia_oraria && (
                                <FormHelperText>{errors.fascia_oraria.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCreateBooking;

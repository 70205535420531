import React, { useEffect, useState } from 'react';
import { Box, Divider, Drawer, IconButton, Toolbar, styled, useTheme, useMediaQuery, ListItemButton, ListItemText, ListItem, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import './App.css';
import { Outlet, useNavigate } from 'react-router-dom';
import MaterialIcon from '../../components/MaterialIcon';
import DrawerList from '../../components/DrawerList';
import { useUserProvider } from '../../providers/useUserProvider';
import { getAvailabilitiesCount } from '../../api/availabilities';
import LogoFitMedical from '../../components/LogoFitMedical';
import { useModalDialog } from '../../providers/useModalDialog';

const drawerWidth = "18rem";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  isSmallScreen?: boolean;
}>(({ theme, open, isSmallScreen }) => ({
  flexGrow: 1,
  width: "100%",
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isSmallScreen ? 0 : `-${drawerWidth}`,
  ...(open && {
    width: isSmallScreen ? "100%" : `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  overflow: "scroll"
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isSmallScreen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, isSmallScreen }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: isSmallScreen ? "100%" : `calc(100% - ${drawerWidth})`,
    marginLeft: isSmallScreen ? 0 : `${drawerWidth}`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function App() {
  const theme = useTheme();
  const { user } = useUserProvider();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const { createModalDialog } = useModalDialog();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    navigate("/logout");
  }

  useEffect(() => {
    const loadAvailabilitiesCount = async () => {
      try {
        const response = await getAvailabilitiesCount({
          id_utente: user.userId!
        });

        if (response.count < 5) {
          createModalDialog(null, {
            title: "Attenzione",
            content: (
              <Typography variant="body1" color={theme.palette.text.primary}>
                Hai meno di 5 disponibilità impostate per i prossimi giorni.<br />Cliccando sul pulsante qui sotto verrai reindirizzato alla pagina delle disponibilità per impostarne di nuove, garantendo un servizio migliore.
              </Typography>
            ),
            actions: [
              {
                label: "Vai alle disponibilità",
                onClick: () => {
                  navigate("/availabilities");
                }
              }
            ]
          });
        }
      } catch (error: any) {
        console.log(error.message);
      }
    }

    if (user.userType === 3) {
      loadAvailabilitiesCount();
    }

    if (!user.userId || !user.userType || !sessionStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  return (
    <Box
      sx={{
        height: "100svh",
        width: "100svw",
        backgroundColor: theme.palette.background.default
      }}>
      <Box display="flex" width="100%" height="100%">
        <AppBar position="fixed" sx={{ boxShadow: "none", backgroundColor: theme.palette.background.default }} open={open} isSmallScreen={isSmallScreen}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MaterialIcon icon="menu" color={theme.palette.textDark.primary} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant={isSmallScreen ? "temporary" : "persistent"}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          <DrawerHeader>
            <IconButton onClick={toggleDrawer}>
              <MaterialIcon icon={isSmallScreen ? "close" : "chevron_left"} />
            </IconButton>
          </DrawerHeader>
          <Box marginLeft="auto" marginRight="auto" marginBottom="4rem">
            <LogoFitMedical style={{ width: "100%", height: "2.5rem", padding: "1rem 0" }} />
          </Box>
          <Divider />
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto"
            }}>
            <DrawerList toggleDrawer={toggleDrawer} />
          </Box>
          <Divider />
          <ListItem
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                width: "100%",
                borderRadius: "0.5rem",
                backgroundColor: "transparent",
              }}>
              <MaterialIcon icon="logout" color={theme.palette.text.secondary} />
              <ListItemText
                primary="Logout"
                sx={{
                  marginLeft: "1rem",
                  marginRight: "auto",
                  color: theme.palette.text.secondary,
                }} />
            </ListItemButton>
          </ListItem>
        </Drawer>
        <Main open={open} isSmallScreen={isSmallScreen}>
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </Box>
  );
}

export default App;

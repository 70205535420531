import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

// Definizione del tipo di stato
interface UserState {
    userId: number | null;
    userType: number | null;
}

// Inizializza il context con un valore di default undefined
const UserContext = createContext<{
    user: UserState;
    setUserId: (userId: number) => void;
    setUserType: (userType: number) => void;
    clearUser: () => void;
} | undefined>(undefined);

// Crea un provider
interface UserProviderProps {
    children: ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
    const [user, setUser] = useState<UserState>({
        userId: null,
        userType: null,
    });

    const navigate = useNavigate(); // Aggiunto per la navigazione

    const setUserId = (userId: number) => {
        setUser((prevUser) => ({ ...prevUser, userId }));
    }

    const setUserType = (userType: number) => {
        setUser((prevUser) => ({ ...prevUser, userType }));
    }

    const clearUser = () => {
        setUser({ userId: null, userType: null });
    }

    return (
        <UserContext.Provider value={{ user, setUserId, setUserType, clearUser }}>
            {children}
        </UserContext.Provider>
    );
}

// Hook personalizzato per utilizzare il context
export function useUserProvider() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserProvider must be used within a UserProvider');
    }
    return context;
}
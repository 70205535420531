import apiClient from "./base";

export const grantCredits = async (params: { id_utente: number; quantita: number }) => {
    const token = sessionStorage.getItem('token');

    const response = apiClient.post('/crediti/grant.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response;
}

export const buyCredits = async (params: { quantita: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/crediti/buy.php', {
        ...params,
        cancel_url: `${window.location.origin}/payments/cancel`,
        success_url: `${window.location.origin}/payments/success`,
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const getMovements = async (params?: { id_utente?: number, id_movimento?: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/crediti/getMovements.php', params, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}
import apiClient from "./base";

export const getAdminDashboardData = async () => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/admin/dashboard.php', {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const getNurseDashboardData = async () => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/infermieri/dashboard.php', {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}

export const getDoctorDashboardData = async () => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/medici/dashboard.php', {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response.data;
}
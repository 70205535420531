import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { createCost } from "../../api/costs";
import { getServices } from "../../api/services";
import { useSwallowLoading } from "../../providers/useSwallowLoading";

type FormValues = {
    id_listino: number;
    id_servizio: number;
    costo: number;
};

const DialogCreatePrice = forwardRef((props: { onUpdate: () => void }, ref) => {
    const { register, handleSubmit, getValues, setValue, formState, reset } = useForm<FormValues>();
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [services, setServices] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [catalogName, setCatalogName] = useState<string>('');

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (open) {
            reset(getValues());
        }
    }, [open, reset, getValues]);

    useImperativeHandle(ref, () => ({
        open: (mode: 'create' | 'edit', initialData: FormValues, catalogName: string) => {
            setMode(mode);
            setCatalogName(catalogName);
            if (initialData) {
                reset(initialData);
            }
            setOpen(true);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
    }));

    const loadData = async () => {
        openLoadingDialog();
        try {
            const servicesResponse = await getServices();
            setServices(servicesResponse.servizi);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei servizi", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const onSubmit = async () => {
        const data = getValues();

        openLoadingDialog();
        try {
            let costo: any = data.costo;

            // Check if costo is of type string and replace comma with dot
            if (typeof costo === 'string') {
                costo = parseFloat(costo.replace(',', '.'));
            }

            await createCost({
                id_listino: data.id_listino,
                id_servizio: data.id_servizio,
                costo: costo
            });

            enqueueSnackbar('Prezzo creato con successo', { variant: 'success' });

            props.onUpdate();
            handleClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante la creazione del prezzo", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    };

    const handleClose = () => {
        setOpen(false);
        resetForm();
    };

    const resetForm = () => {
        reset({});
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{mode === 'create' ? 'Nuovo Prezzo' : 'Modifica Prezzo'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per {mode === 'create' ? 'creare' : 'modificare'} il prezzo.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="id_listino"
                                label="Listino"
                                value={catalogName}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.id_servizio}>
                            <InputLabel htmlFor="servizio">Servizio</InputLabel>
                            <Select
                                id="servizio"
                                label="Servizio"
                                value={getValues('id_servizio')}
                                disabled={mode === 'edit'}
                                {...register("id_servizio", {
                                    required: "Il servizio è obbligatorio",
                                    onChange: (e) => setValue('id_servizio', e.target.value)
                                })}>
                                {services?.map((item) => (
                                    <MenuItem key={item.id_servizio} value={item.id_servizio}>{item.nome_servizio}</MenuItem>
                                ))}
                            </Select>
                            {!!errors.id_servizio && (
                                <FormHelperText>{errors.id_servizio.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.costo}>
                            <InputLabel htmlFor="costo">Costo</InputLabel>
                            <OutlinedInput
                                id="costo"
                                type="decimal"
                                label="Costo"
                                {...register("costo", {
                                    required: "Il costo è obbligatorio",
                                    min: {
                                        value: 0,
                                        message: 'Il costo deve essere maggiore di 0'
                                    },
                                })}
                            />
                            {!!errors.costo && (
                                <FormHelperText>{errors.costo.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogCreatePrice;

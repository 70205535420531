import apiClient from "./base";

export const getRegions = async (params?: { codice_regione?: string, denominazione_regione?: string }) => {
    const response = await apiClient.post(`/garda_informatica/regioni.php`, params);
    return response.data;
}

export const getProvinces = async (params?: { codice_regione?: string, sigla_provincia?: string, denominazione_provincia?: string, codice_sovracomunale?: string }) => {
    const response = await apiClient.post(`/garda_informatica/province.php`, params);
    return response.data;
}

export const getCities = async (params?: { sigla_provincia?: string, codice_istat?: string, denominazione_ita?: string, denominazione_ita_altra?: string, flag_capoluogo?: string, codice_belfiore?: string, codice_sovracomunale?: string }) => {
    const response = await apiClient.post(`/garda_informatica/comuni.php`, params);
    return response.data;
}

export const getCaps = async (params?: { codice_istat?: string, cap?: string }) => {
    const response = await apiClient.post(`/garda_informatica/cap.php`, params);
    return response.data;
}

export const getPrefixes = async (params?: { iso?: string, name?: string, nicename?: string, iso3?: string, phonecode?: string }) => {
    const response = await apiClient.post(`/garda_informatica/prefissi.php`, params);
    return response.data;
}
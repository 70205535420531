import apiClient from "./base";

export const login = async (params: { codice_fiscale: string, password: string }) => {
    const response = await apiClient.post('/users/login.php', params);
    return response.data;
}

export const logout = async () => {
    const token = sessionStorage.getItem('token');

    if (!token) {
        throw new Error('Token not found');
    }

    const response = await apiClient.post('/users/logout.php', {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const register = async (params: any) => {
    const response = await apiClient.post('/users/register.php', params);
    return response.data;
}

export const requestPasswordRecovery = async (params: { username: string }) => {
    const response = await apiClient.post('/users/requestRecovery.php', params);
    return response.data;
}

export const resetPassword = async (params: { token: string, password: string }) => {
    const response = await apiClient.post('/users/resetPassword.php', params);
    return response.data;
}

export const changePassword = async (params: { password: string, id_utenti: number }) => {
    const token = sessionStorage.getItem('token');

    const response = await apiClient.post('/users/changePassword.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const refreshToken = async () => {
    const token = sessionStorage.getItem('token');

    if (!token) {
        throw new Error('Token not found');
    }

    const response = await apiClient.post('/users/refreshToken.php', {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}
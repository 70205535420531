import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, OutlinedInput, Box, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import MaterialIcon from "../MaterialIcon";
import { uploadContract } from "../../api/contracts";

type DialogUploadContractProps = {
    onUpdate: () => void;
};

const DialogUploadContract = forwardRef((props: DialogUploadContractProps, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [document, setDocument] = useState<File | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open: () => {
            setOpen(true);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
        setUserId: (id: number | null) => {
            setUserId(id);
        }
    }));

    const onSubmit = async () => {
        if (!document) {
            enqueueSnackbar('Nessun documento caricato', { variant: 'warning' });
            return;
        }

        if (!userId) {
            enqueueSnackbar('Utente non selezionato', { variant: 'warning' });
            return;
        }

        openLoadingDialog();
        try {
            await uploadContract({ id_utente: userId, file: document });
            enqueueSnackbar('Contratto caricato con successo', { variant: 'success' });
            props.onUpdate();
            onClose();
        } catch (error: any) {
            enqueueSnackbar("Errore durante il caricamento del contratto", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const onClose = () => {
        setOpen(false);
        resetForm();
    }

    const resetForm = () => {
        setDocument(null);
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        setDocument(file);
    }

    const handleRemove = () => {
        setDocument(null);
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    }

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const file = e.dataTransfer.files?.[0] || null;
        setDocument(file);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",
                    },
                },
            }}>
            <DialogTitle>Carica Contratto</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Carica il contratto per l'utente selezionato.</DialogContentText>
                {document ? (
                    <>
                        <Box sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                            <FormControl fullWidth>
                                <OutlinedInput
                                    type="text"
                                    value={document.name}
                                    readOnly
                                />
                            </FormControl>
                            <IconButton
                                color="secondary"
                                aria-label="delete file"
                                onClick={handleRemove}
                                sx={{ marginLeft: '1rem' }}
                            >
                                <MaterialIcon icon="delete" />
                            </IconButton>
                        </Box>
                    </>
                ) : (
                    <Box
                        border="2px dashed #cccccc"
                        padding="1rem"
                        textAlign="center"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <Typography variant="body1" marginBottom="1rem">Trascina il file qui per caricarlo</Typography>
                        <Typography variant="body2" color="textSecondary" marginBottom="1rem">oppure</Typography>
                        <Button variant="contained" color="primary" component="label">
                            Carica contratto
                            <input type="file" hidden onChange={handleFileChange} />
                        </Button>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annulla</Button>
                <Button variant="contained" onClick={onSubmit} disabled={!document}>Carica</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogUploadContract;

import { Box, Card, Chip, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../components/MaterialIcon";
import { useEffect, useState } from "react";
import SortableTable, { HeadCell } from "../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { approveContract, getContracts, rejectContract } from "../../../api/contracts";
import { useUserProvider } from "../../../providers/useUserProvider";
import { useNavigate } from "react-router-dom";
import { getPayments } from "../../../api/payments";

const Payments: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');
    const [dateFilter, setDateFilter] = useState<Date | null>(null);
    const [statusFilter, setStatusFilter] = useState<string>('Tutti');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [payments, setPayments] = useState<any[]>([]);

    useEffect(() => {
        if (!user.userType || ![1].includes(user.userType)) {
            navigate('/error', { state: { errorStatus: 401 } });
        }

        openLoadingDialog();
        Promise.all([loadPayments()])
            .then(([payments]) => {
                setPayments(payments);
            })
            .catch((error: any) => {
                enqueueSnackbar("Errore durante l'ottenimento dei pagamenti", { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const loadPayments = async () => {
        const response = await getPayments();
        return response.pagamenti;
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const statuses = Array.from(new Set(payments.map((item) => item.payment_status)));

    const columns: HeadCell<any>[] = [
        { id: 'id_pagamento', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice fiscale' },
        { id: 'paid_amount', numeric: false, disablePadding: false, label: 'Quantità' },
        { id: 'payment_status', numeric: false, disablePadding: false, label: 'Stato' },
        { id: 'created', numeric: false, disablePadding: false, label: 'Data Creazione' },
    ];

    const filteredList = payments.filter((item) => {
        if (statusFilter && statusFilter !== 'Tutti' && item.payment_status !== statusFilter) {
            return false;
        }
        if (dateFilter && item.created !== dateFilter.toISOString().split('T')[0]) return false;

        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.id_utente.toString().includes(term) ||
            item.nome.toLowerCase().includes(term) ||
            item.cognome.toLowerCase().includes(term) ||
            item.codice_fiscale.toLowerCase().includes(term) ||
            item.paid_amount.toString().includes(term)
        );
        return matchesSearch;
    });

    const renderRow = (item: any) => (
        <TableRow key={item.id_pagamento}>
            <TableCell align="right">{item.id_utente}</TableCell>
            <TableCell>{item.nome}</TableCell>
            <TableCell>{item.cognome}</TableCell>
            <TableCell>{item.codice_fiscale}</TableCell>
            <TableCell>{item.paid_amount}</TableCell>
            <TableCell>
                <Chip
                    label={item.payment_status.toUpperCase()}
                    color={
                        item.payment_status === "completed"
                            ? "success"
                            : item.payment_status === "unpaid"
                                ? "default"
                                : "error"
                    } />
            </TableCell>
            <TableCell>{item.created}</TableCell>
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Pagamenti</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza i pagamenti effettuati dagli utenti sulla piattaforma.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <TextField
                    label="Cerca pagamenti"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                <TextField
                    label="Filtra per data"
                    type="date"
                    sx={{ width: "12rem" }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setDateFilter(e.target.value ? new Date(e.target.value) : null)}
                />
                <FormControl
                    sx={{ width: "12rem" }}>
                    <InputLabel htmlFor="status">Filtra per stato</InputLabel>
                    <Select
                        id="status"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        label="Filtra per stato"
                    >
                        <MenuItem value={'Tutti'}>Tutti</MenuItem>
                        {statuses.map((status) => (
                            <MenuItem key={status} value={status}>{status}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem>
                    <ListItemIcon>
                        <MaterialIcon icon="troubleshoot" />
                    </ListItemIcon>
                    <ListItemText>Visualizza dettagli</ListItemText>
                </MenuItem>
            </Menu>
            {/* Aggiungere Dialog per  */}
        </Box>
    );
};

export default Payments;
import { Box, Card, Chip, Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../components/SortableTable";
import { useUserProvider } from "../../../../providers/useUserProvider";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { deleteAvailability, getAvailabilities } from "../../../../api/availabilities";
import MaterialIcon from "../../../../components/MaterialIcon";
import DialogAvailabilityCreate from "../../../../components/dialogs/DialogAvailabilityCreate";
import { useSnackbar } from "notistack";

const AvailabilitiesManager: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { enqueueSnackbar } = useSnackbar();

    const [availabilities, setAvailabilities] = useState<any[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);

    const dialogCreateAvailabilityRef = useRef<any>(null);

    const columns: HeadCell<any>[] = [
        { id: 'id_disponibilita', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
        { id: 'data', numeric: false, disablePadding: false, label: 'Data Disponibilità' },
        { id: 'mattina', numeric: false, disablePadding: false, label: 'Mattina' },
        { id: 'pomeriggio', numeric: false, disablePadding: false, label: 'Pomeriggio' },
        { id: 'actions', numeric: false, disablePadding: false, label: 'Azioni' }
    ];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await getAvailabilities();
            let data = response.data;

            setAvailabilities(data);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoadingDialog();
        }
    }

    const handleCreateCatalog = () => {
        dialogCreateAvailabilityRef.current.open('create', {});
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditAvailability = () => {
        console.log(selectedItem);
        dialogCreateAvailabilityRef.current.open('edit', selectedItem);

        handleMenuClose();
    }

    const handleDeleteAvailability = async () => {
        openLoadingDialog();
        try {
            await deleteAvailability({ id_disponibilita: selectedItem.id_disponibilita });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Errore durante l'eliminazione della disponibilità", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }

        handleMenuClose();

        loadData();
    }

    const renderRow = (item: any) => {
        return (
            <TableRow key={item.id_medico}>
                <TableCell align="right">{item.id_disponibilita}</TableCell>
                <TableCell>{item.nome}</TableCell>
                <TableCell>{item.cognome}</TableCell>
                <TableCell>{item.data_disponibilita}</TableCell>
                <TableCell>
                    {
                        item.orario_mattina_inizio && item.orario_mattina_fine ?
                            <>
                                <Chip label={item.orario_mattina_inizio.substring(0, 5)} color="default" size="small" />
                                <br />
                                <Chip label={item.orario_mattina_fine.substring(0, 5)} color="default" size="small" sx={{ marginTop: "0.25rem" }} />
                            </> : <Chip label="N/D" color="default" size="small" />
                    }
                </TableCell>
                <TableCell>
                    {
                        item.orario_pomeriggio_inizio && item.orario_pomeriggio_fine ?
                            <>
                                <Chip label={item.orario_pomeriggio_inizio.substring(0, 5)} color="default" size="small" />
                                <br />
                                <Chip label={item.orario_pomeriggio_fine.substring(0, 5)} color="default" size="small" sx={{ marginTop: "0.25rem" }} />
                            </> : <Chip label="N/D" color="default" size="small" />
                    }
                </TableCell>
                <TableCell>
                    <IconButton
                        onClick={(event) => handleMenuOpen(event, item)}>
                        <MaterialIcon icon="more_vert" />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">
                Gestione Disponibilità
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci le disponibilità dei medici.
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    onClick={handleCreateCatalog}>
                    <MaterialIcon icon="add" />
                </Fab>
                <TextField
                    label="Cerca disponibilità"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={() => null} />
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={availabilities}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditAvailability}>
                    <ListItemIcon>
                        <MaterialIcon icon="edit" />
                    </ListItemIcon>
                    <ListItemText>Modifica</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteAvailability}>
                    <ListItemIcon >
                        <MaterialIcon icon="delete" />
                    </ListItemIcon>
                    <ListItemText>Elimina</ListItemText>
                </MenuItem>
            </Menu>
            <DialogAvailabilityCreate
                ref={dialogCreateAvailabilityRef}
                onUpdate={loadData} />
        </Box>
    );
};

export default AvailabilitiesManager;

import apiClient from "./base";

interface Question {
    id_domanda?: number;
    testo_domanda?: string;
    fl_visibile?: boolean;
}

export const getQuestions = async (params?: { id_domanda?: number, testo_domanda?: string, fl_visibile?: boolean }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/questionario_sport/fetchQuestions.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const addQuestion = async (params: { testo_domanda: string }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/questionario_sport/createQuestion.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const deleteQuestion = async (params: { id_domanda: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/questionario_sport/deleteQuestion.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const updateQuestion = async (params: { id_domanda: number, testo_domanda: string }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post(`/questionario_sport/updateQuestion.php`, params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const getQuestionnaire = async (params: { id_richiesta: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/questionario_sport/fetchQuestionnaire.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const getCompiledQuestionnaire = async (params: { id_richiesta: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/questionario_sport/fetchQuestionnaire.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const compileQuestionnaire = async (params: { id_richiesta: number, questionario: { id_domanda: number, risposta: string }[] }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/questionario_sport/compileQuestionnaire.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
import apiClient from "./base";

export const getWallets = async (params?: { id_servizio?: number, id_utente?: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/wallet/getWallet.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const updateWallet = async (params: { id_utente: number, id_servizio: number, saldo: number }) => {
    const token = sessionStorage.getItem('token');
    const response = await apiClient.post('/wallet/updateWallet.php', params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
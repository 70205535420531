import { Box, Button, Card, FormControl, FormHelperText, Grid, IconButton, InputLabel, OutlinedInput, Step, StepLabel, Stepper, Typography, useTheme, Select, MenuItem, Checkbox, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import LogoFitMedical from "../../../components/LogoFitMedical";
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { FISCAL_CODE_OR_VAT_REGEX, FISCAL_CODE_REGEX, EMAIL_REGEX, PASSWORD_REGEX } from "../../../config/regex";
import { getProvinces, getRegions, getCities, getCaps, getPrefixes } from "../../../api/istat";
import { Link, useNavigate } from "react-router-dom";
import MaterialIcon from "../../../components/MaterialIcon";
import { checkExistingFiscalCode } from "../../../api/users";
import { register } from "../../../api/auth";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { uploadContract } from "../../../api/contracts";

const Register: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const location = window.location.pathname;
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();

    const userType =
        location.includes("pharmacy") ? 6 :
            location.includes("patient") ? 5 :
                location.includes("nurse") ? 2 :
                    location.includes("sports-doctor") ? 3 :
                        location.includes("gym") ? 4 : 1;

    const step1Ref = React.useRef<{ validate: () => Promise<Step1FormValues | null>, getValues: () => Step1FormValues }>();
    const step2Ref = React.useRef<{ validate: () => Promise<Step2FormValues | null>, getValues: () => Step2FormValues }>();
    const step3Ref = React.useRef<{ validate: () => Promise<Step3FormValues | null>, getValues: () => Step3FormValues }>();

    const handleBack = () => {
        if (activeStep === 0) {
            navigate("/register");
            return;
        }
        setActiveStep(activeStep - 1);
    }

    const handleNext = async () => {
        let validData = null;

        if (activeStep === 0 && step1Ref.current) {
            validData = await step1Ref.current.validate();

            console.log(validData);

            if (validData) {
                setActiveStep(activeStep + 1);
            }
        } else if (activeStep === 1 && step2Ref.current) {
            validData = await step2Ref.current.validate();
            if (validData) {
                setActiveStep(activeStep + 1);
            }
        } else if (activeStep === 2 && step3Ref.current) {
            validData = await step3Ref.current.validate();

            if (validData) {
                const finalData = {
                    tipi_utenti_id_tipi_utenti: userType,
                    ...step1Ref.current?.getValues(),
                    ...step2Ref.current?.getValues(),
                    ...step3Ref.current?.getValues()
                }

                const contract = finalData.contratto;

                delete (finalData as any).contratto;

                try {
                    openLoadingDialog();
                    const response = await register(finalData);
                    const userId = response.user.data.id_utenti;
                    const token = response.token;

                    sessionStorage.setItem("token", token);

                    if ([2, 3, 4].includes(userType)) {
                        await uploadContract({ id_utente: userId, file: contract![0] });
                        sessionStorage.removeItem("token");
                    }

                    enqueueSnackbar("Registrazione completata", { variant: "success" });

                    navigate("/login");
                } catch (error: any) {
                    enqueueSnackbar("Errore durante l'inserimento del token", { variant: "error" });
                } finally {
                    closeLoadingDialog();
                }
            }
        }
    }

    return (
        <Box
            className="LoginPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            <Card
                sx={{
                    maxWidth: "42rem",
                    margin: "1rem",
                    boxSizing: "border-box",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                    }}>
                    <Step>
                        <StepLabel>Informazioni generali</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Informazioni di contatto</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Conferma i tuoi dati</StepLabel>
                    </Step>
                </Stepper>

                <Step1Form ref={step1Ref} display={activeStep === 0} userType={userType} />
                <Step2Form ref={step2Ref} display={activeStep === 1} userType={userType} />
                <Step3Form ref={step3Ref} display={activeStep === 2} userType={userType} />

                <Box marginTop="2rem" display="flex" flexDirection="row" justifyContent="space-between">
                    <Button variant="text" color="primary" onClick={handleBack}>
                        Indietro
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === 2 ? "Completa" : "Avanti"}
                    </Button>
                </Box>
            </Card>
        </Box>
    );
}

type Step1FormValues = {
    nome: string;
    cognome: string;
    codice_fiscale: string;
    data_nascita: string;
    piva: string;
    email: string;
    password: string;
    conferma_password: string;
    ragione_sociale?: string;
}

type Step2FormValues = {
    telefono: string;
    indirizzo_residenza: string;
    codice_regione: string;
    sigla_provincia: string;
    comune_residenza: string;
    cap_residenza: string;
    codice_univoco: string;
    pec: string;
}

type Step3FormValues = {
    contratto: FileList | null;
    fl_accetto_privacy: boolean;
    fl_accetto_termini: boolean;
}

const Step1Form = forwardRef(({ userType, display }: { userType: number, display: boolean }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { register, trigger, formState, getValues } = useForm<Step1FormValues>();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        validate: async () => {
            try {
                const values = getValues();

                const errors = await trigger();

                if (!errors) {
                    enqueueSnackbar("Compila tutti i campi obbligatori", { variant: "error" });
                    return null;
                }

                // Verifica se il codice fiscale esiste già
                const exists = await checkExistingFiscalCode(values.codice_fiscale);
                if (exists.data) {
                    enqueueSnackbar("Codice fiscale già registrato", { variant: "error" });
                    return null;
                }

                return values; // Se tutto è valido, ritorna i valori
            } catch (error) {
                enqueueSnackbar("Errore durante la validazione", { variant: "error" });
                return null;
            }
        },
        getValues: () => getValues(),
    }));

    const handleTogglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    return (
        <Box display={display ? "block" : "none"}>
            <Grid container spacing="1rem">
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.nome}>
                        <InputLabel htmlFor="nome">{[4, 6].includes(userType) ? "Nome rappresentante legale" : "Nome"}</InputLabel>
                        <OutlinedInput
                            id="nome"
                            label={[4, 6].includes(userType) ? "Nome rappresentante legale" : "Nome"}
                            {...register("nome", {
                                required: "Campo obbligatorio",
                            })}
                        />
                        {formState.errors.nome && (
                            <FormHelperText>{formState.errors.nome.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.cognome}>
                        <InputLabel htmlFor="cognome">{[4, 6].includes(userType) ? "Cognome rappresentante legale" : "Cognome"}</InputLabel>
                        <OutlinedInput
                            id="cognome"
                            label={[4, 6].includes(userType) ? "Cognome rappresentante legale" : "Cognome"}
                            {...register("cognome", {
                                required: "Campo obbligatorio",
                            })}
                        />
                        {formState.errors.cognome && (
                            <FormHelperText>{formState.errors.cognome.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.codice_fiscale}>
                        <InputLabel htmlFor="codice_fiscale">{[4, 6].includes(userType) ? "Codice Fiscale rappresentante legale" : "Codice Fiscale"}</InputLabel>
                        <OutlinedInput
                            id="codice_fiscale"
                            label={[4, 6].includes(userType) ? "Codice Fiscale rappresentante legale" : "Codice Fiscale"}
                            {...register("codice_fiscale", {
                                required: "Campo obbligatorio",
                                pattern: {
                                    value: FISCAL_CODE_REGEX,
                                    message: "Codice fiscale non valido",
                                },
                            })}
                        />
                        {formState.errors.codice_fiscale && (
                            <FormHelperText>{formState.errors.codice_fiscale.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {[4, 6].includes(userType) && (
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error={!!formState.errors.ragione_sociale}>
                            <InputLabel htmlFor="ragione_sociale">Ragione Sociale</InputLabel>
                            <OutlinedInput
                                id="ragione_sociale"
                                label="Ragione Sociale"
                                {...register("ragione_sociale", {
                                    required: "Campo obbligatorio",
                                })}
                            />
                            {formState.errors.ragione_sociale && (
                                <FormHelperText>{formState.errors.ragione_sociale.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                )}
                {[2, 3, 5].includes(userType) && (
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error={!!formState.errors.data_nascita}>
                            <TextField
                                id="data_nascita"
                                label="Data di Nascita"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("data_nascita", {
                                    required: "Campo obbligatorio",
                                    validate: (value) => {
                                        const fiscalCodeMonths = "ABCDEHLMPRST".split("");
                                        const fiscalCode = getValues("codice_fiscale").toUpperCase();
                                        const birthYear = parseInt(value.substring(2, 4));
                                        const birthMonth = parseInt(value.substring(5, 7));
                                        const birthDay = parseInt(value.substring(8, 10));
                                        const fiscalCodeYear = parseInt(fiscalCode.substring(6, 8));
                                        const fiscalCodeMonth = fiscalCodeMonths.indexOf(fiscalCode.charAt(8)) + 1;
                                        const fiscalCodeDay = parseInt(fiscalCode.substring(9, 11));

                                        if (birthYear !== fiscalCodeYear || birthMonth !== fiscalCodeMonth || (birthDay !== fiscalCodeDay && birthDay !== fiscalCodeDay - 40)) {
                                            return "Data di nascita non valida";
                                        }

                                        return true;
                                    }
                                })}
                            />
                            {formState.errors.data_nascita && (
                                <FormHelperText>{formState.errors.data_nascita.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                )}
                {[2, 3, 4, 6].includes(userType) && (
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error={!!formState.errors.piva}>
                            <InputLabel htmlFor="piva">Partita IVA</InputLabel>
                            <OutlinedInput
                                id="piva"
                                label="Partita IVA"
                                {...register("piva", {
                                    required: "Campo obbligatorio",
                                    pattern: {
                                        value: FISCAL_CODE_OR_VAT_REGEX,
                                        message: "Partita IVA non valida",
                                    },
                                })}
                            />
                            {formState.errors.piva && (
                                <FormHelperText>{formState.errors.piva.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sm={[2, 3, 4, 6].includes(userType) ? 6 : 12}>
                    <FormControl fullWidth error={!!formState.errors.email}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <OutlinedInput
                            id="email"
                            label="Email"
                            {...register("email", {
                                required: "Campo obbligatorio",
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: "Email non valida",
                                },
                            })}
                        />
                        {formState.errors.email && (
                            <FormHelperText>{formState.errors.email.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.password}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            label="Password"
                            type={isPasswordVisible ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleTogglePasswordVisibility}>
                                        {!isPasswordVisible ? <MaterialIcon icon="visibility" /> : <MaterialIcon icon="visibility_off" />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            {...register("password", {
                                required: "Campo obbligatorio",
                                pattern: PASSWORD_REGEX
                            })}
                        />
                        {formState.errors.password && (
                            <FormHelperText>{formState.errors.password.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.conferma_password}>
                        <InputLabel htmlFor="conferma_password">Conferma Password</InputLabel>
                        <OutlinedInput
                            id="conferma_password"
                            label="Conferma Password"
                            type="password"
                            {...register("conferma_password", {
                                required: "Campo obbligatorio",
                                validate: (value) => {
                                    return value === getValues("password") || "Le password non corrispondono";
                                },
                            })}
                        />
                        {formState.errors.conferma_password && (
                            <FormHelperText>{formState.errors.conferma_password.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
});

const Step2Form = forwardRef(({ userType, display }: { userType: number, display: boolean }, ref) => {
    const { register, trigger, formState, setValue, getValues } = useForm<Step2FormValues>();
    const [regions, setRegions] = useState<any[]>([]);
    const [provinces, setProvinces] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [caps, setCaps] = useState<any[]>([]);
    const [phonePrefixes, setPhonePrefixes] = useState<any[]>([]);
    const [phonePrefix, setPhonePrefix] = useState<string>('+39');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const values = getValues();

            const errors = await trigger();

            if (!errors) {
                enqueueSnackbar("Compila tutti i campi obbligatori", { variant: "error" });
                return null;
            }

            return values;
        },
        getValues: () => getValues(),
    }));

    useEffect(() => {
        const loadRegions = async () => {
            const response = await getRegions();
            setRegions(response.regioni);
        };
        const loadPrefixes = async () => {
            const response = await getPrefixes();
            setPhonePrefixes(response.prefissi);
        };
        loadRegions();
        loadPrefixes();

        if (getValues('codice_regione')) {
            loadProvinces(getValues('codice_regione'));
        }
        if (getValues('sigla_provincia')) {
            loadCities(getValues('sigla_provincia'));
        }
        if (getValues('comune_residenza')) {
            loadCaps(getValues('comune_residenza'));
        }

        // Initialize phone prefix and number
        if (getValues('telefono')) {
            const [prefix, number] = getValues('telefono').split(' ');
            setPhonePrefix(prefix);
            setPhoneNumber(number);
        }
    }, [getValues]);

    useEffect(() => {
        setValue('telefono', `${phonePrefix} ${phoneNumber}`);
    }, [phonePrefix, phoneNumber, setValue]);

    const loadProvinces = async (regionCode: string) => {
        const response = await getProvinces({ codice_regione: regionCode });
        setProvinces(response.province);
    };

    const loadCities = async (provinceCode: string) => {
        const response = await getCities({ sigla_provincia: provinceCode });
        setCities(response.comuni);
    };

    const loadCaps = async (cityCode: string) => {
        const response = await getCaps({ codice_istat: cityCode });
        setCaps(response.cap);
    };

    const handleRegionChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
        const value = e.target.value as string;
        setValue('codice_regione', value);
        setProvinces([]);
        setCities([]);
        setCaps([]);
        setValue('sigla_provincia', '');
        setValue('comune_residenza', '');
        setValue('cap_residenza', '');
        await loadProvinces(value);
    };

    const handleProvinceChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
        const value = e.target.value as string;
        setValue('sigla_provincia', value);
        setCities([]);
        setCaps([]);
        setValue('comune_residenza', '');
        setValue('cap_residenza', '');
        await loadCities(value);
    };

    const handleCityChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
        const value = e.target.value as string;
        setValue('comune_residenza', value);
        setCaps([]);
        setValue('cap_residenza', '');
        await loadCaps(value);
    };

    return (
        <Box display={display ? "block" : "none"}>
            <Grid container spacing="1rem">
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.telefono}>
                        <InputLabel htmlFor="telefono">Telefono</InputLabel>
                        <OutlinedInput
                            id="telefono"
                            label="Telefono"
                            type="tel"
                            value={phoneNumber}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Select
                                        id="prefisso"
                                        label="Prefisso"
                                        value={phonePrefix}
                                        onChange={(e) => setPhonePrefix(e.target.value)}
                                        variant="standard"
                                        displayEmpty>
                                        {phonePrefixes.map((item) => (
                                            <MenuItem key={item.id} value={`+${item.phonecode}`}>+{item.phonecode} ({item.iso})</MenuItem>
                                        ))}
                                    </Select>
                                </InputAdornment>
                            }
                            {...register("telefono", {
                                required: "Campo obbligatorio",
                                validate: () => {
                                    if (!phoneNumber) {
                                        return "Campo obbligatorio";
                                    }

                                    return phoneNumber.length >= 6 && phoneNumber.length <= 14 || "Numero di telefono non valido";
                                },
                                onChange: (e) => setPhoneNumber(e.target.value),
                            })}
                        />
                        {formState.errors.telefono && (
                            <FormHelperText>{formState.errors.telefono.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.indirizzo_residenza}>
                        <InputLabel htmlFor="indirizzo_residenza">{[4, 6].includes(userType) ? "Sede Legale" : "Indirizzo di Residenza"}</InputLabel>
                        <OutlinedInput
                            id="indirizzo_residenza"
                            label={[4, 6].includes(userType) ? "Sede Legale" : "Indirizzo di Residenza"}
                            {...register("indirizzo_residenza", {
                                required: "Campo obbligatorio",
                            })}
                        />
                        {formState.errors.indirizzo_residenza && (
                            <FormHelperText>{formState.errors.indirizzo_residenza.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.codice_regione}>
                        <InputLabel htmlFor="codice_regione">Regione</InputLabel>
                        <Select
                            id="codice_regione"
                            label="Regione"
                            value={getValues('codice_regione')}
                            {...register("codice_regione", {
                                required: "Campo obbligatorio",
                                onChange: handleRegionChange,
                            })}>
                            {regions.map((item) => (
                                <MenuItem key={item.codice_regione} value={item.codice_regione}>{item.denominazione_regione}</MenuItem>
                            ))}
                        </Select>
                        {formState.errors.codice_regione && (
                            <FormHelperText>{formState.errors.codice_regione.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.sigla_provincia}>
                        <InputLabel htmlFor="sigla_provincia">Provincia</InputLabel>
                        <Select
                            id="sigla_provincia"
                            label="Provincia"
                            value={getValues('sigla_provincia')}
                            disabled={provinces.length === 0}
                            {...register("sigla_provincia", {
                                required: "Campo obbligatorio",
                                onChange: handleProvinceChange,
                            })}>
                            {provinces.map((item) => (
                                <MenuItem key={item.sigla_provincia} value={item.sigla_provincia}>{item.denominazione_provincia}</MenuItem>
                            ))}
                        </Select>
                        {formState.errors.sigla_provincia && (
                            <FormHelperText>{formState.errors.sigla_provincia.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.comune_residenza}>
                        <InputLabel htmlFor="comune_residenza">Comune</InputLabel>
                        <Select
                            id="comune_residenza"
                            label="Comune"
                            value={getValues('comune_residenza')}
                            disabled={cities.length === 0}
                            {...register("comune_residenza", {
                                required: "Campo obbligatorio",
                                onChange: handleCityChange,
                            })}>
                            {cities.map((item) => (
                                <MenuItem key={item.codice_istat} value={item.codice_istat}>{item.denominazione_ita}</MenuItem>
                            ))}
                        </Select>
                        {formState.errors.comune_residenza && (
                            <FormHelperText>{formState.errors.comune_residenza.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={!!formState.errors.cap_residenza}>
                        <InputLabel htmlFor="cap_residenza">CAP</InputLabel>
                        <Select
                            id="cap_residenza"
                            label="CAP"
                            disabled={caps.length === 0}
                            {...register("cap_residenza", {
                                required: "Campo obbligatorio",
                            })}>
                            {caps.map((item) => (
                                <MenuItem key={item.cap} value={item.cap}>{item.cap}</MenuItem>
                            ))}
                        </Select>
                        {formState.errors.cap_residenza && (
                            <FormHelperText>{formState.errors.cap_residenza.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {[2, 3, 4, 6].includes(userType) && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!formState.errors.codice_univoco}>
                                <InputLabel htmlFor="codice_univoco">Codice Univoco</InputLabel>
                                <OutlinedInput
                                    id="codice_univoco"
                                    label="Codice Univoco"
                                    {...register("codice_univoco")}
                                />
                                {formState.errors.codice_univoco && (
                                    <FormHelperText>{formState.errors.codice_univoco.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!formState.errors.pec}>
                                <InputLabel htmlFor="pec">PEC</InputLabel>
                                <OutlinedInput
                                    id="pec"
                                    label="PEC"
                                    {...register("pec", {
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: "PEC non valida",
                                        },
                                    })}
                                />
                                {formState.errors.pec && (
                                    <FormHelperText>{formState.errors.pec.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
});

const Step3Form = forwardRef(({ userType, display }: { userType: number, display: boolean }, ref) => {
    const theme = useTheme();
    const { register, trigger, formState, getValues, setValue } = useForm<Step3FormValues>();
    const [contractFile, setContractFile] = useState<FileList | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const values = getValues();

            const errors = await trigger();

            if (!errors) {
                enqueueSnackbar("Compila tutti i campi obbligatori", { variant: "error" });
                return null;
            }

            if (!values.fl_accetto_privacy) {
                enqueueSnackbar("Devi accettare la privacy policy", { variant: "error" });
                return null;
            }

            if (!values.fl_accetto_termini) {
                enqueueSnackbar("Devi accettare i termini e le condizioni", { variant: "error" });
                return null;
            }

            if ([2, 3, 4].includes(userType) && !contractFile) {
                enqueueSnackbar("Devi caricare il contratto", { variant: "error" });
                return null;
            }

            return {
                ...values,
                contratto: contractFile,
            };
        },
        getValues: () => {
            return {
                ...getValues(),
                contratto: contractFile,
            }
        },
    }));

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setContractFile(files);
        }
    };

    return (
        <Box display={display ? "block" : "none"}>
            {[2, 3, 4, 6].includes(userType) ? (
                <>
                    <Typography variant="body1" gutterBottom color={theme.palette.text.secondary}>
                        Gentile utente, per completare la registrazione, carica il contratto firmato, quindi accetta la privacy policy e i termini e le condizioni.
                    </Typography>
                    <Box marginTop="2rem" marginBottom="2rem" textAlign="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const link = document.createElement("a");
                                link.href = userType == 2 ?
                                    "https://app.telemedicina.care/contratto_infermieri.pdf"
                                    : userType == 3 ?
                                        "https://app.telemedicina.care/contratto_medici.pdf"
                                        : userType == 4 ?
                                            "https://app.telemedicina.care/contratto_palestre.pdf"
                                            : "https://app.telemedicina.care/contratto_farmacie.pdf";
                                link.download = "contratto.pdf";
                                link.click();
                            }}>
                            Scarica contratto
                        </Button>
                    </Box>
                    <Box marginBottom="1rem">
                        <FormControl fullWidth error={!!formState.errors.contratto}>
                            <TextField
                                id="contratto"
                                label="Carica contratto"
                                type="file"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    onChange: handleFileChange,
                                }}
                            />
                            {formState.errors.contratto && (
                                <FormHelperText>{formState.errors.contratto.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                </>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom color={theme.palette.text.secondary}>
                        Gentile utente, per completare la registrazione, devi accettare la privacy policy e i termini e le condizioni.
                    </Typography>
                </>
            )}
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            {...register("fl_accetto_privacy", {
                                required: "Devi accettare la privacy policy",
                            })}
                        />
                    }
                    label={
                        <Typography>
                            Dichiaro di aver letto e di accettare la <a href="https://telemedicina.care/privacy-policy-e-informative/" target="_blank">privacy policy</a>
                        </Typography>
                    }
                />
                {formState.errors.fl_accetto_privacy && (
                    <Typography color="error">{formState.errors.fl_accetto_privacy.message}</Typography>
                )}
            </Box>
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox {...register("fl_accetto_termini", {
                            required: "Devi accettare i termini e le condizioni"
                        })} />
                    }
                    label={
                        <Typography>
                            Dichiaro di aver letto e di accettare i <a href="https://telemedicina.care/privacy-policy-e-informative/" target="_blank">termini e le condizioni</a>
                        </Typography>
                    }
                />
                {formState.errors.fl_accetto_termini && (
                    <Typography color="error">{formState.errors.fl_accetto_termini.message}</Typography>
                )}
            </Box>
        </Box>
    );
});

export default Register;

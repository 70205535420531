import apiClient from "./base";

export const getSpecializations = async (params?: { id_specializzazione?: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/specializzazioni/ottieni_specializzazioni.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const getUsersSpecializations = async (params?: { id_utente?: number, id_specializzazione?: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/specializzazioni/ottieni_specializzazioni_utenti.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const assignSpecializationToUser = async (params: { id_utente: number, id_specializzazione: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/specializzazioni/assegna_specializzazione_utente.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const removeSpecializationFromUser = async (params: { id_utente: number, id_specializzazione: number }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/specializzazioni/elimina_specializzazione_utente.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}

export const updateUserSpecializations = async (params: { id_utente: number, specializzazioni: number[] }) => {
    const token = sessionStorage.getItem("token");
    const response = await apiClient.post("/specializzazioni/aggiorna_specializzazioni_utente.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
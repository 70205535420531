import { Box, Card, Chip, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../../components/MaterialIcon";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { approveContract, getContracts, rejectContract } from "../../../../api/contracts";
import { downloadDocument } from "../../../../api/documents";
import { useNavigate } from "react-router-dom";
import DialogUploadContract from "../../../../components/dialogs/DialogUploadContract";

const Contracts: React.FC = () => {
    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');
    const [userTypeFilter, setUserTypeFilter] = useState<number>(-1);
    const [contractStatusFilter, setContractStatusFilter] = useState<number>(-1);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [contracts, setContracts] = useState<any[]>([]);

    const navigate = useNavigate();

    const dialogUploadRef = useRef<{ open: () => void, close: () => void, setUserId: (id: number | null) => void }>();

    useEffect(() => {
        loadContracts();
    }, []);

    useEffect(() => {
        if (!anchorEl) {
            // Evita di ricaricare i dati se l'ancora del menu cambia
            return;
        }

        // Qualsiasi altra logica che dipende dall'apertura del menu
    }, [anchorEl]);


    const loadContracts = async () => {
        openLoadingDialog();
        try {
            const response = await getContracts();
            setContracts(response.contratti);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei contratti", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleApprove = async () => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            await approveContract({ id_contratto: selectedItem.id_contratto });
            enqueueSnackbar('Contratto approvato', { variant: 'success' });
            loadContracts();
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'approvazione del contratto", { variant: 'error' });
        } finally {
            closeLoadingDialog();
            handleMenuClose();
        }
    }

    const handleReject = async () => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            await rejectContract({ id_contratto: selectedItem.id_contratto });
            enqueueSnackbar('Contratto rifiutato', { variant: 'success' });
            loadContracts();
        } catch (error: any) {
            enqueueSnackbar("Errore durante il rifiuto del contratto", { variant: 'error' });
        } finally {
            closeLoadingDialog();
            handleMenuClose();
        }
    }

    const handleDownloadContract = async () => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            const response = await downloadDocument({ id_documento: selectedItem.id_documento });

            // Create a URL for the file blob
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            // Open the file in a new tab
            window.open(url, '_blank');

            // Optionally, if you want to revoke the object URL after some time
            setTimeout(() => window.URL.revokeObjectURL(url), 1000 * 60 * 5); // Revoke URL after 5 minutes
        } catch (error: any) {
            enqueueSnackbar("Errore durante il download del documento", { variant: 'error' });
        } finally {
            closeLoadingDialog();
            handleMenuClose();
        }
    }

    const handleUploadContract = () => {
        if (!selectedItem) return;

        dialogUploadRef.current?.setUserId(selectedItem.id_utenti);
        dialogUploadRef.current?.open();

        handleMenuClose();
    }

    const handleNavigateDetails = () => {
        if (!selectedItem) return;

        // Passa sia l'id_contratto che l'id_utente nello state
        navigate(`/settings/contracts/details`, {
            state: {
                userId: selectedItem.id_utenti
            }
        });
    }

    const columns: HeadCell<any>[] = [
        { id: 'id_utenti', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'tipi_utenti_id_tipi_utenti', numeric: false, disablePadding: false, label: 'Tipo Utente' },
        { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'cognome', numeric: false, disablePadding: false, label: 'Cognome' },
        { id: 'codice_fiscale', numeric: false, disablePadding: false, label: 'Codice fiscale' },
        { id: 'stato', numeric: false, disablePadding: false, label: 'Stato' },
        { id: 'timestamp_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const filteredList = contracts.filter((item) => {
        if (userTypeFilter !== -1 && item.tipi_utenti_id_tipi_utenti !== userTypeFilter) return false;

        if (contractStatusFilter !== -1 && item.stato !== contractStatusFilter) return false;

        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.id_utenti?.toString().toLowerCase().includes(term) ||
            item.tipi_utenti_id_tipi_utenti?.toString().toLowerCase().includes(term) ||
            item.nome?.toLowerCase().includes(term) ||
            item.cognome?.toLowerCase().includes(term) ||
            item.codice_fiscale?.toLowerCase().includes(term) ||
            item.stato?.toString().toLowerCase().includes(term) ||
            item.timestamp_creazione?.toLowerCase().includes(term)
        );
        return matchesSearch;
    });

    const renderRow = (item: any) => (
        <TableRow key={item.id_utenti}>
            <TableCell align='right'>{item.id_utenti}</TableCell>
            <TableCell>
                <Chip
                    label={
                        item.tipi_utenti_id_tipi_utenti === 1 ?
                            "Amministratore"
                            : item.tipi_utenti_id_tipi_utenti === 2 ?
                                "Infermiere"
                                : item.tipi_utenti_id_tipi_utenti === 3 ?
                                    "Medico"
                                    : item.tipi_utenti_id_tipi_utenti === 4 ?
                                        "Palestra"
                                        : "Paziente"
                    }
                    color="default" />
            </TableCell>
            <TableCell>{item.nome}</TableCell>
            <TableCell>{item.cognome}</TableCell>
            <TableCell>{item.codice_fiscale}</TableCell>
            <TableCell>
                <Chip
                    label={item.stato === 0 ? "In attesa" : item.stato === 1 ? "Rifiutato" : item.stato === 2 ? "Accettato" : 'Non caricato'}
                    color={item.stato === 0 ? "info" : item.stato === 1 ? "error" : item.stato === 2 ? "success" : 'default'} />
            </TableCell>
            <TableCell>{item.timestamp_creazione ?? 'N/D'}</TableCell>
            <TableCell>
                <IconButton
                    onClick={(event) => handleMenuOpen(event, item)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Contratti</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci i contratti dei Professionisti
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <TextField
                    label="Cerca contratti"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                <FormControl>
                    <InputLabel id="user-type-filter">Tipo Utente</InputLabel>
                    <Select
                        id="user-type-filter"
                        value={userTypeFilter}
                        onChange={(e) => setUserTypeFilter(e.target.value as number)}
                        displayEmpty
                        variant="outlined"
                        sx={{ width: "12rem" }}
                        label="Tipo Utente"
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value={-1}>Tutti</MenuItem>
                        <MenuItem value={2}>Infermiere</MenuItem>
                        <MenuItem value={3}>Medico</MenuItem>
                        <MenuItem value={4}>Palestra</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="contract-status-filter">Stato Contratto</InputLabel>
                    <Select
                        id="contract-status-filter"
                        label="Stato Contratto"
                        value={contractStatusFilter}
                        onChange={(e) => setContractStatusFilter(e.target.value as number)}
                        displayEmpty
                        variant="outlined"
                        sx={{ width: "12rem" }}
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value={-1}>Tutti</MenuItem>
                        <MenuItem value={0}>In attesa</MenuItem>
                        <MenuItem value={2}>Accettati</MenuItem>
                        <MenuItem value={1}>Rifiutati</MenuItem>
                        <MenuItem value={3}>Non caricati</MenuItem>
                    </Select>
                </FormControl>
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {selectedItem?.stato === 3 && (
                    <MenuItem onClick={handleUploadContract}>
                        <ListItemIcon >
                            <MaterialIcon icon="upload" />
                        </ListItemIcon>
                        <ListItemText>Carica contratto</ListItemText>
                    </MenuItem>
                )}
                {selectedItem?.stato !== 3 && (
                    <MenuItem onClick={handleNavigateDetails}>
                        <ListItemIcon >
                            <MaterialIcon icon="troubleshoot" />
                        </ListItemIcon>
                        <ListItemText>Visualizza</ListItemText>
                    </MenuItem>
                )}
                {selectedItem?.stato !== 3 && (
                    <MenuItem onClick={handleDownloadContract}>
                        <ListItemIcon >
                            <MaterialIcon icon="download" />
                        </ListItemIcon>
                        <ListItemText>Scarica contratto</ListItemText>
                    </MenuItem>
                )}
                {(selectedItem?.stato === 0 || selectedItem?.stato === 1) && (
                    <MenuItem onClick={handleApprove}>
                        <ListItemIcon>
                            <MaterialIcon icon="thumb_up" />
                        </ListItemIcon>
                        <ListItemText>Approva</ListItemText>
                    </MenuItem>
                )}
                {(selectedItem?.stato === 0 || selectedItem?.stato === 2) && (
                    <MenuItem onClick={handleReject}>
                        <ListItemIcon>
                            <MaterialIcon icon="thumb_down" />
                        </ListItemIcon>
                        <ListItemText>Rifiuta</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <DialogUploadContract
                ref={dialogUploadRef}
                onUpdate={loadContracts} />
        </Box>
    );
};

export default Contracts;
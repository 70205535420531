import React, { HTMLAttributes } from "react";

const LogoFitMedical = (props: HTMLAttributes<HTMLOrSVGImageElement> & { logoColor?: 'default' | 'white' }) => {
    if (!props.logoColor) {
        props.logoColor = 'default';
    }

    if (props.logoColor === 'white') {
        return (
            <img {...props} src="/images/logo_white.svg" alt="Logo" />
        );
    } else {
        return (
            <img {...props} src={`/images/logo.svg`} alt="Logo" />
        );
    }
}

LogoFitMedical.defaultProps = {
    logoColor: 'default'
};

export default LogoFitMedical;

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSwallowLoading } from "../../providers/useSwallowLoading";
import { getServices } from "../../api/services";
import { getUsers } from "../../api/users";
import { updateWallet } from "../../api/wallets";

type FormValues = {
    id_utente?: number,
    id_servizio?: number,
    saldo: number,
};

type DialogCreateWalletProps = {
    onUpdate: () => void;
};

const DialogCreateWallet = forwardRef((props: DialogCreateWalletProps, ref) => {
    const { register, handleSubmit, getValues, setValue, formState, reset, watch } = useForm<FormValues>({
        defaultValues: {
            saldo: 0,
        }
    });
    const { errors } = formState;
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [services, setServices] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'create' | 'edit'>('create');

    // Traccia il valore selezionato per il campo servizio
    const selectedService = watch('id_servizio', getValues().id_servizio);

    useEffect(() => {
        openLoadingDialog();

        Promise.all([loadServices(), loadUsers()])
            .then(([services, users]) => {
                setServices(services);
                setUsers(users);
            })
            .catch(error => {
                enqueueSnackbar("Errore durante l'ottenimento dei servizi", { variant: 'error' });
            })
            .finally(() => {
                closeLoadingDialog();
            });
    }, []);

    const loadServices = async () => {
        const response = await getServices();
        return response.servizi;
    }

    const loadUsers = async () => {
        const response = await getUsers();
        return response.data;
    }

    useImperativeHandle(ref, () => ({
        open: (mode: 'create' | 'edit', initialData?: FormValues) => {
            setMode(mode);
            if (initialData) {
                reset(initialData);
            }
            setOpen(true);
        },
        close: () => {
            setOpen(false);
            resetForm();
        },
    }));

    const onSubmit = async () => {
        const data = getValues();

        if (!data.id_utente || !data.id_servizio) {
            enqueueSnackbar("Utente o servizio non validi", { variant: 'error' });
            return;
        }

        openLoadingDialog();
        try {
            await updateWallet({
                id_utente: data.id_utente!,
                id_servizio: data.id_servizio!,
                saldo: data.saldo,
            });

            props.onUpdate();
            handleClose();

            enqueueSnackbar('Wallet aggiornato con successo', { variant: 'success' });
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'aggiornamento del wallet", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleClose = () => {
        setOpen(false);
        resetForm();
    }

    const resetForm = () => {
        reset({});
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "36rem",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>{mode === 'create' ? 'Nuovo Wallet' : 'Modifica Wallet'}</DialogTitle>
            <DialogContent>
                <DialogContentText marginBottom="2rem">Compila il form per {mode === 'create' ? 'creare' : 'modificare'} il wallet.</DialogContentText>
                <Grid container rowSpacing="1rem" columnSpacing="1rem">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.id_utente}>
                            <Autocomplete
                                options={users}
                                getOptionLabel={(user) => `${user.nome} ${user.cognome} - ${user.codice_fiscale}`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Utente"
                                        error={!!errors.id_utente}
                                    />
                                )}
                                value={users.find(user => user.id_utenti === getValues().id_utente)}
                                onChange={(_, value) => setValue('id_utente', value?.id_utenti || 0)}
                                disabled={mode === 'edit'}
                            />
                            {errors.id_utente && (
                                <FormHelperText>{errors.id_utente.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.id_servizio}>
                            <InputLabel htmlFor="servizio">Servizio</InputLabel>
                            <Select
                                label="Servizio"
                                disabled={mode === 'edit'}
                                value={getValues().id_servizio ?? null}
                                {...register('id_servizio', {
                                    required: true,
                                })}>
                                {services && services.map(service => (
                                    <MenuItem key={service.id_servizio} value={service.id_servizio}>{service.nome_servizio}</MenuItem>
                                ))}
                            </Select>
                            {errors.id_servizio && (
                                <FormHelperText>{errors.id_servizio.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors.saldo}>
                            <InputLabel htmlFor="saldo">Saldo</InputLabel>
                            <OutlinedInput
                                id="saldo"
                                type="number"
                                label="Saldo"
                                {...register('saldo', {
                                    required: "Il saldo è obbligatorio",
                                    validate: value => Number.isInteger(Number(value)) && value >= 0,
                                })}
                            />
                            {errors.saldo && (
                                <FormHelperText>{errors.saldo.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant="contained" type="submit" disabled={formState.isSubmitting}>Salva</Button>
            </DialogActions>
        </Dialog>
    );
});

export default DialogCreateWallet;

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface TableProps<T extends Record<string, any>> { // Changed from string | number to any
    rows: T[];
    headCells: HeadCell<T>[];
    renderRow: (row: T) => JSX.Element;
    rowsPerPageOptions?: number[];
    initialRowsPerPage?: number;
}

export interface HeadCell<T> {
    id: keyof T;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    disableSort?: boolean;
}

export type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T | undefined) {
    if (!orderBy) return 0;
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key | undefined,
): (
    a: Record<Key, any>,
    b: Record<Key, any>,
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const SortableTable = forwardRef(<T extends Record<string, any>>(
    { rows, headCells, renderRow, rowsPerPageOptions = [10, 25, 50], initialRowsPerPage = 25 }: TableProps<T>,
    ref: React.Ref<any>
) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof T | undefined>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [filterFunction, setFilterFunction] = useState<(item: any) => boolean>(() => () => true);

    const handleRequestSort = (property: keyof T) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredRows = rows.filter(filterFunction);

    const sortedRows = orderBy ? stableSort(filteredRows, getComparator(order, orderBy)) : filteredRows;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // Esponi API imperativa tramite ref
    useImperativeHandle(ref, () => ({
        setFilter: (filter: (item: any) => boolean) => {
            setFilterFunction(() => filter);
            setPage(0);
        },
        resetPagination: () => {
            setPage(0);
        }
    }));

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id as string}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {headCell.disableSort ? headCell.label : (
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(renderRow)}
                    {sortedRows.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={headCells.length + 1} align="center">
                                Nessun elemento da mostrare
                            </TableCell>
                        </TableRow>
                    )}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={headCells.length + 1} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
});

export default SortableTable;

import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    Typography,
    useTheme,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Chip,
    CardActionArea
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { getAvailabilities } from '../../../api/availabilities';
import { useUserProvider } from '../../../providers/useUserProvider';
import { useNavigate } from 'react-router-dom';
import DialogAvailabilityCreate from '../../../components/dialogs/DialogAvailabilityCreate';

const DAYS = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

const Availabilities = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [availabilities, setAvailabilities] = useState<any[]>([]);
    const currentDate = new Date();
    const navigate = useNavigate();

    const dialogRef = useRef<any>(null); // Ref per controllare DialogAvailabilityCreate

    useEffect(() => {
        if (!user.userId || !user.userType) {
            navigate('/error', { state: { errorStatus: 401 } });
            return;
        }

        if (user.userType !== 3) {
            navigate('/error', { state: { errorStatus: 403 } });
            return;
        }

        loadData();
    }, []);

    const loadData = async () => {
        openLoadingDialog();
        try {
            const response = await getAvailabilities({ id_utente: user.userId! });
            setAvailabilities(response.data);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei dati", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleMonthChange = (event: SelectChangeEvent) => {
        setSelectedMonth(parseInt(event.target.value));
    };

    const handleDateClick = (date: Date, availability: any) => {
        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        const isPastDate = localDate.getTime() < currentDate.getTime();
        const hasBookings = availability?.count_prenotazioni > 0;

        if (availability) {
            if (isPastDate || hasBookings) {
            } else {
                dialogRef.current?.open('edit', { ...availability, id_medico_sport: user.userId, data_disponibilita: localDate });
            }
        } else {
            dialogRef.current?.open('create', { id_medico_sport: user.userId, data_disponibilita: localDate });
        }
    };

    const renderDates = () => {
        const dates = [];
        const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1);
        const lastDayOfMonth = new Date(selectedYear, selectedMonth + 1, 0);
        const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

        const previousMonth = new Date(selectedYear, selectedMonth, 0);
        const daysInPreviousMonth = previousMonth.getDate();

        const startDay = firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1;

        // Add dates from the previous month to fill the first week
        for (let i = startDay; i > 0; i--) {
            dates.push(new Date(selectedYear, selectedMonth - 1, daysInPreviousMonth - i + 1));
        }

        // Add dates for the current month
        for (let i = 1; i <= daysInMonth; i++) {
            dates.push(new Date(selectedYear, selectedMonth, i));
        }

        // Add dates from the next month to fill the last week
        const endDay = lastDayOfMonth.getDay() === 0 ? 6 : lastDayOfMonth.getDay() - 1;
        for (let i = 1; i < 7 - endDay; i++) {
            dates.push(new Date(selectedYear, selectedMonth + 1, i));
        }

        // Split into groups of 7
        const weeks = [];
        for (let i = 0; i < dates.length; i += 7) {
            weeks.push(dates.slice(i, i + 7));
        }

        return weeks.map((week, weekIndex) => (
            <TableRow key={weekIndex} >
                {week.map((date, index) => {
                    const availability = availabilities.find(av => new Date(av.data_disponibilita).toDateString() === date.toDateString());
                    let chipLabel = "Nessuna";
                    let chipColor = "default";
                    let canEdit = true;

                    if (availability) {
                        if (availability.orario_mattina_inizio || availability.orario_mattina_fine) {
                            chipLabel = "Mattina";
                            chipColor = "primary";
                        }
                        if (availability.orario_pomeriggio_inizio || availability.orario_pomeriggio_fine) {
                            chipLabel = chipLabel === "Mattina" ? "Mattina & Pomeriggio" : "Pomeriggio";
                            chipColor = "primary";
                        }
                        if (availability.count_prenotazioni > 0 || date.getTime() < currentDate.getTime()) {
                            canEdit = false;
                        }
                    }

                    canEdit = canEdit && date.getMonth() === selectedMonth && date.toISOString().split('T')[0] >= currentDate.toISOString().split('T')[0] && date.getDay() !== 0 && date.getDay() !== 6;

                    return (
                        <TableCell key={index} sx={{
                            height: 'auto',
                            padding: "0rem",
                            border: "1px solid #e0e0e0",
                            backgroundColor: !canEdit ? theme.palette.grey[200] : 'transparent',
                            width: 'calc(100% / 7)',
                        }}>
                            <CardActionArea sx={{
                                padding: "1rem 2rem",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}
                                disabled={!canEdit}
                                onClick={() => handleDateClick(date, availability)}>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    color={theme.palette.text.secondary}
                                    textAlign="center"
                                    textTransform="uppercase">
                                    {weekIndex === 0 && DAYS[index]}.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color={date.getMonth() !== selectedMonth ? theme.palette.text.secondary : theme.palette.text.primary}
                                    textAlign="center"
                                    marginBottom="2rem"
                                    borderRadius="50%">
                                    {date.getDate()}
                                </Typography>
                                <Box
                                    textAlign="center">
                                    <Chip
                                        label={chipLabel}
                                        color={chipColor as any}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            marginRight: "0.5rem"
                                        }} />
                                </Box>
                            </CardActionArea>
                        </TableCell>
                    );
                })}
            </TableRow>
        ));
    };

    return (
        <Box padding="2rem" boxSizing="border-box">
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Disponibilità</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Gestisci le tue disponibilità
            </Typography>
            <Card sx={{
                padding: "0rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                alignItems: "center"
            }}>
                <Box
                    padding="1rem">
                    <FormControl
                        sx={{
                            width: "24rem"
                        }}>
                        <InputLabel id="month-select-label">Mese</InputLabel>
                        <Select
                            labelId="month-select-label"
                            id="month-select"
                            value={selectedMonth.toString()}
                            label="Mese"
                            onChange={handleMonthChange}
                        >
                            <MenuItem value={currentDate.getMonth().toString()}>{new Date(currentDate.getFullYear(), currentDate.getMonth()).toLocaleString('default', { month: 'long', year: 'numeric' })}</MenuItem>
                            <MenuItem value={(currentDate.getMonth() + 1).toString()}>{new Date(currentDate.getFullYear(), currentDate.getMonth() + 1).toLocaleString('default', { month: 'long', year: 'numeric' })}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {renderDates()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <DialogAvailabilityCreate
                ref={dialogRef}
                onUpdate={loadData}
            />
        </Box>
    );
}

export default Availabilities;

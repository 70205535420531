import React, { useEffect } from 'react';
import { Box, Button, Card, FormControl, Typography, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useUserProvider } from '../../../providers/useUserProvider';
import LogoFitMedical from '../../../components/LogoFitMedical';

// Pagina da mostrare quando il contratto non è stato ancora approvato
const Pending: React.FC = () => {
    const { user } = useUserProvider();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if (!user.userId || !user.userType) {
            navigate("/logout");
        }
    }, []);

    const handleLogin = () => {
        navigate("/login");
    }

    return (
        <Box
            className="PendingPage"
            alignItems="center"
            width="100svw"
            height="100svh"
            display="flex"
            justifyContent="center"
            bgcolor={theme.palette.background.default}>
            <Card sx={{
                maxWidth: "28rem",
                margin: "1rem",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <LogoFitMedical style={{ height: "3rem", padding: "1rem 0" }} />
                <Typography variant="h6" align="center" marginTop="2rem" marginBottom="0.5rem">In attesa di approvazione</Typography>
                <Typography variant="body1" align="center" marginBottom="2rem">Il tuo account è in attesa di approvazione da parte di un amministratore della piattaforma. Riprova più tardi.</Typography>
                <FormControl fullWidth>
                    <Button variant="text" onClick={handleLogin}>Torna al login</Button>
                </FormControl>
            </Card>
        </Box >
    );
}

export default Pending;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import router from './config/router';
import { ThemeProvider } from '@mui/material';
import { theme } from './config/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import { ToastError, ToastInfo, ToastSuccess, ToastWarning } from './components/Toast';
import { SwallowLoadingProvider } from './providers/useSwallowLoading';
import { ModalDialogProvider } from './providers/useModalDialog';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        Components={{
          default: ToastInfo,
          success: ToastSuccess,
          error: ToastError,
          warning: ToastWarning,
          info: ToastInfo
        }}>
        <ModalDialogProvider>
          <SwallowLoadingProvider>
            <RouterProvider router={router} />
          </SwallowLoadingProvider>
        </ModalDialogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </LocalizationProvider>
);
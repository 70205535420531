import { Box, Card, Chip, Fab, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select, Switch, TableCell, TableRow, TextField, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../../../components/MaterialIcon";
import { useEffect, useRef, useState } from "react";
import SortableTable, { HeadCell } from "../../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../../providers/useSwallowLoading";
import { deleteCatalog, getCatalogs, updateCatalog } from "../../../../api/catalogs";
import DialogCreateCatalog from "../../../../components/dialogs/DialogCreateCatalog";
import { useNavigate } from "react-router-dom";

const Catalogs: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [searchText, setSearchText] = useState<string>('');
    const [activeFilter, setActiveFilter] = useState<number | null>(-1);
    const [defaultFilter, setDefaultFilter] = useState<number | null>(-1);
    const [profileTipeFilter, setProfileTipeFilter] = useState<number | null>(-1);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [catalogs, setCatalogs] = useState<any[]>([]);

    const dialogRef = useRef<any>(null);

    useEffect(() => {
        loadCatalogs();
    }, []);

    const loadCatalogs = async () => {
        openLoadingDialog();
        try {
            const response = await getCatalogs();
            setCatalogs(response.listini);
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'ottenimento dei cataloghi", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleCreateCatalog = () => {
        if (!dialogRef.current) return;

        dialogRef.current.setInitialData({});
        dialogRef.current.open('create');
    }

    const handleDialogCloseUpdate = () => {
        loadCatalogs();
    }

    const handleOnSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleUpdateDefault = async (id_listino: number, fl_default: 0 | 1) => {
        openLoadingDialog();
        try {
            await updateCatalog({ id_listino, fl_default });
            enqueueSnackbar('Listino aggiornato con successo', { variant: 'success' });

            await loadCatalogs();
        } catch (error: any) {
            enqueueSnackbar("Errore durante l'aggiornamento del listino", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleUpdateActive = async (id_listino: number, fl_attivo: 0 | 1) => {
        openLoadingDialog();
        try {
            await updateCatalog({ id_listino, fl_attivo });
            enqueueSnackbar('Listino aggiornato con successo', { variant: 'success' });

            await loadCatalogs();
        } catch (error: any) {
            enqueueSnackbar("Errore durte l'aggionamento del listino", { variant: 'error' });
        } finally {
            closeLoadingDialog();
        }
    }

    const handleDeleteCatalog = async () => {
        if (!selectedItem) return;

        openLoadingDialog();
        try {
            await deleteCatalog({ id_listino: selectedItem.id_listino });
            enqueueSnackbar('Listino eliminato con successo', { variant: 'success' });

            await loadCatalogs();
        } catch (error: any) {
            enqueueSnackbar("Errore durante la rimozione del listino", { variant: 'error' });
        } finally {
            closeLoadingDialog();
            handleMenuClose();
        }
    }

    const handlePricesDetails = () => {
        if (!selectedItem) return;

        handleMenuClose();

        navigate(`prices`, { state: { id_listino: selectedItem.id_listino } });
    }

    const handleEditCatalog = () => {
        if (!selectedItem) return;

        if (!dialogRef.current) return;

        dialogRef.current.setInitialData(selectedItem);
        dialogRef.current.open('edit');

        handleMenuClose();
    }

    const columns: HeadCell<any>[] = [
        { id: 'id_listino', numeric: true, disablePadding: false, label: 'ID' },
        { id: 'tipo_profilo', numeric: false, disablePadding: false, label: 'Tipo Profilo' },
        { id: 'nome_listino', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'fl_attivo', numeric: false, disablePadding: false, label: 'Attivo' },
        { id: 'fl_default', numeric: false, disablePadding: false, label: 'Default' },
        { id: 'data_creazione', numeric: false, disablePadding: false, label: 'Data Creazione' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Azioni', disableSort: true },
    ];

    const filteredList = catalogs.filter((item) => {
        if (activeFilter !== -1 && item.fl_attivo !== activeFilter) return false;
        if (defaultFilter !== -1 && item.fl_default !== defaultFilter) return false;
        if (profileTipeFilter !== -1 && item.tipo_profilo !== profileTipeFilter) return false;

        const searchTerms = searchText.toLowerCase().split(' ');
        const matchesSearch = searchTerms.every(term =>
            item.nome_listino.toLowerCase().includes(term) ||
            item.id_listino.toString().toLowerCase().includes(term)
        );

        return matchesSearch;
    });

    const renderRow = (item: any) => (
        <TableRow key={item.id_listino}>
            <TableCell align='right'>{item.id_listino}</TableCell>
            <TableCell>
                <Chip
                    label={
                        item.tipo_profilo === 1 ?
                            "Amministratore"
                            : item.tipo_profilo === 2 ?
                                "Infermiere"
                                : item.tipo_profilo === 3 ?
                                    "Medico"
                                    : item.tipo_profilo === 4 ?
                                        "Palestra"
                                        : "Paziente"
                    }
                    color="default" />
            </TableCell>
            <TableCell>{item.nome_listino}</TableCell>
            <TableCell>
                <Switch
                    checked={item.fl_attivo === 1}
                    onChange={(event) => handleUpdateActive(item.id_listino, event.target.checked ? 1 : 0)}
                />
            </TableCell>
            <TableCell>
                <Switch
                    checked={item.fl_default === 1}
                    onChange={(event) => handleUpdateDefault(item.id_listino, event.target.checked ? 1 : 0)}
                />
            </TableCell>
            <TableCell>{item.data_creazione}</TableCell>
            <TableCell>
                <IconButton onClick={(event) => handleMenuOpen(event, item)}>
                    <MaterialIcon icon="more_vert" />
                </IconButton>
            </TableCell>
        </TableRow>
    );

    return (
        <Box padding="2rem" boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.textDark.primary} fontWeight="bold">Listini</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.textDark.primary}>
                Visualizza e gestisci i listini
            </Typography>
            <Card sx={{
                padding: "1rem",
                borderRadius: "1rem",
                marginBottom: "1rem",
                display: "flex",
                gap: "1rem",
                alignItems: "center"
            }}>
                <Fab
                    color="primary"
                    size="medium"
                    aria-label="add"
                    onClick={handleCreateCatalog}>
                    <MaterialIcon icon="add" />
                </Fab>
                <TextField
                    label="Cerca listini"
                    variant="outlined"
                    sx={{ width: "20rem" }}
                    onChange={handleOnSearchTextChange} />
                <FormControl>
                    <InputLabel id="attivo">Attivo</InputLabel>
                    <Select
                        id="attivo"
                        variant="outlined"
                        value={activeFilter}
                        label="Attivo"
                        onChange={(event) => setActiveFilter(event.target.value as number)}
                        sx={{ width: "10rem" }}>
                        <MenuItem value={-1}>Tutti</MenuItem>
                        <MenuItem value={1}>Attivi</MenuItem>
                        <MenuItem value={0}>Non Attivi</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="default">Default</InputLabel>
                    <Select
                        id="default"
                        variant="outlined"
                        value={defaultFilter}
                        label="Default"
                        onChange={(event) => setDefaultFilter(event.target.value as number)}
                        sx={{ width: "10rem" }}>
                        <MenuItem value={-1}>Tutti</MenuItem>
                        <MenuItem value={1}>Default</MenuItem>
                        <MenuItem value={0}>Non Default</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="tipo_profilo">Tipo Profilo</InputLabel>
                    <Select
                        id="tipo_profilo"
                        variant="outlined"
                        value={profileTipeFilter}
                        label="Tipo Profilo"
                        onChange={(event) => setProfileTipeFilter(event.target.value as number)}
                        sx={{ width: "10rem" }}>
                        <MenuItem value={-1}>Tutti</MenuItem>
                        <MenuItem value={1}>Amministratore</MenuItem>
                        <MenuItem value={2}>Infermiere</MenuItem>
                        <MenuItem value={3}>Medico</MenuItem>
                        <MenuItem value={4}>Palestra</MenuItem>
                        <MenuItem value={5}>Paziente</MenuItem>
                    </Select>
                </FormControl>
            </Card>
            <Card sx={{ padding: "0", borderRadius: "1rem" }}>
                <SortableTable
                    rows={filteredList}
                    headCells={columns}
                    renderRow={renderRow} />
            </Card>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handlePricesDetails}>
                    <ListItemIcon>
                        <MaterialIcon icon="troubleshoot" />
                    </ListItemIcon>
                    <ListItemText>Visualizza costi</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleEditCatalog}>
                    <ListItemIcon>
                        <MaterialIcon icon="edit" />
                    </ListItemIcon>
                    <ListItemText>Modifica</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteCatalog}>
                    <ListItemIcon>
                        <MaterialIcon icon="delete" />
                    </ListItemIcon>
                    <ListItemText>Elimina</ListItemText>
                </MenuItem>
            </Menu>
            <DialogCreateCatalog
                ref={dialogRef}
                onUpdate={handleDialogCloseUpdate} />
        </Box>
    );
};

export default Catalogs;